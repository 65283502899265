import { ICategorie } from './../../models/entities/icategorie';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoginService } from './../../services/login.service';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/entities/iuser';
import { NgControl } from '@angular/forms'
import { DashboardPage } from 'src/app/pages/dashboard/dashboard.page';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.scss'],
})
export class ParametresComponent implements OnInit , OnDestroy {
private profileParam : boolean = true;
private passwordParam : boolean = false;
private gestionParam : boolean = false;
private categorieParam : boolean = false;

  subupdateAdminEmiter: any;
  subgetAllBackOfficeUser: any;
  subaddBackOff1ice: any;
  subdeleteOrUpdateBackOfficeUser: any;
  subshowAddCategorie: any;
  subshowAddNewUser: any;
  sublogin: any;
  subupdateCurentAdmin: any;
  subgetCategorieList: any;
  subactivateOrDeactivateCategorie: any;
  subshowConf1irmAlert: any;
  subactivateOrDeactivateBackOfficeUser: any;
  subshowConfirmAlert: any;
  subshowUpdateUser: any;
  subshowUpdateCategorie: any;
  subgetAdmins: any;
  sousCat: any;
  currentCat: any;

  ngOnDestroy(): void {
    // this.subupdateAdminEmiter.unsubscribe();
    // this.subgetAllBackOfficeUser.unsubscribe();
    // this.subaddBackOff1ice.unsubscribe();
    // this.subdeleteOrUpdateBackOfficeUser.unsubscribe();
    // this.subshowAddCategorie.unsubscribe();
    // this.subshowAddNewUser.unsubscribe();
    // this.sublogin.unsubscribe();
    // this.subupdateCurentAdmin.unsubscribe();
    // this.subgetCategorieList.unsubscribe();
    // this.subactivateOrDeactivateCategorie.unsubscribe();
    // this.subshowConf1irmAlert.unsubscribe();
    // this.subactivateOrDeactivateBackOfficeUser.unsubscribe();
    // this.subshowConfirmAlert.unsubscribe();
    // this.subshowUpdateUser.unsubscribe();
    // this.subshowUpdateCategorie.unsubscribe();
    // this.subgetAdmins.unsubscribe();
  }
@ViewChild('slides', { static: false }) slider: IonSlides;

segment = 0;
private users : IUser[] = [];  

email: string = "" ;
nom: string = "" ;
pin: string = "" ;
nouveauPin: string = "" ;
ancienPin: string = "" ;
prenom: string = "" ;
referenceFichier: string = "" ;
telephone: string = "" ;
role: string = "" ;           
userForm: FormGroup;

  user =  {"prenom":"","nom":"","role":"","email":"","telephone":"","ancienPin":"","nouveauPin":""};
    tel = "";
  AllAdmin: any[] = [];
  AllCategorie: any[] = [];
  fichier: any;
  fichierBase: any;
  constructor(public jeveBaraService : JeveBaraService,
              private loginService : LoginService,
              private formBuilder : FormBuilder,
              private localStorageProvider: LocalStorageService
              ) {
                this.getUser();
                console.log("constructeur : "+ JSON.stringify(this.user) );
                this.subgetAdmins = this.jeveBaraService.getAdmins({page : "0", size : "10000"}).subscribe( (res) => {
                  this.users = res;
                });
                
                this.userForm = this.formBuilder.group({
                  email: "new FormControl(this.user.email, Validators.minLength(2))",
                  nom: "new FormControl(this.user.nom, Validators.minLength(2))",
                  pin: "ew FormControl(this.user.email)",
                  nouveauPin: "new FormControl()",
                  prenom: "new FormControl(this.user.prenom, Validators.minLength(2))",
                  referenceFichier: "new FormControl(this.user.referenceFichier, Validators.maxLength(22))",
                  telephone: "new FormControl(this.user.telephone, Validators.required)",
                  role: "new FormControl(this.user.role, Validators.required)",
                });
    }
  
    modifierCategorie(id : number){
      this.subshowUpdateCategorie = this.loginService.showUpdateCategorie(id).afterClosed().subscribe( (res) => {
        if(res){
          // this.jeveBaraService.updateBackOfficeUser(id,).subscribe((res) => {
          //   this.loginService.showAlert("supprimer administrateur","Administrateur supprimer avec succès !");
          // });
        }else{

        }
      });
    }
    
    addSousCat(idCat : number, libelle : string){
      this.loginService.showAddSousCategorie(idCat, libelle);
    }

    modifierUser(id : number){
      this.subshowUpdateUser = this.loginService.showUpdateUser(id).afterClosed().subscribe( (res) => {
        if(res){
          // this.jeveBaraService.updateBackOfficeUser(id,).subscribe((res) => {
          //   this.loginService.showAlert("supprimer administrateur","Administrateur supprimer avec succès !");
          // });
        }else{

        }
      });
    }
    selectedIndex: number = 0;

   async setIndex(index: number) {
       this.selectedIndex = index;
       this.currentCat = this.AllCategorie[index];
       
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.getSousCatByCatId(+this.currentCat.id).subscribe(async (res) => {
      (await loading).dismiss();  
        this.sousCat = res;
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    }

    modifierSousCategorie(id : number){
      this.loginService.showUpdateSousCategorie(id);
    }
    
    async supprimerSousCategorie(id : number){  
      this.loginService.showConfirmAlert("supprimer","Voulez-vous vraiment supprimer cette sous categorie ?").afterClosed().subscribe(async (res) => {
        if(res){       
          const loading = this.loginService.loading();
          (await loading).present();
          this.jeveBaraService.activateOrDeactivateSousCategorie(id).subscribe(async (res) => {
          (await loading).dismiss();  
            this.loginService.showAlert("supprimer sous categorie","sous categorie supprimer avec succès !");
            this.jeveBaraService.addBackOffice.emit();
          },async e => {
            (await loading).dismiss();
            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
          });
        }else{

        }
      });
    }

    selectCat(id : any){
      this.jeveBaraService.getSousCatByCatId(id).subscribe((res) => {
        console.log("Sous categories : "+res);
      });
    }
    
    rendreCategorie(categorie){      
      this.jeveBaraService.rendredDisponileCategorie(categorie.id, {"libelle" : categorie.libelle, 
                                                               "referenceFichier" : categorie.path, 
                                                               "disponible" : !categorie?.disponible}).
      subscribe((res) => {
        if(categorie?.disponible){
          this.loginService.showAlert("Rendre indisponible","Categorie rendu indisponible !");          
        }else{
          this.loginService.showAlert("Rendre disponible","Categorie rendu disponible !"); 
        }
        this.jeveBaraService.addBackOffice.emit();
      });
    }

    supprimer(id : number){
      this.subshowConfirmAlert = this.loginService.showConfirmAlert("supprimer","Voulez-vous vraiment supprimer cette utilisateur ?").afterClosed().subscribe(async (res) => {
        if(res){
          const loading = this.loginService.loading();
          (await loading).present();
          this.subactivateOrDeactivateBackOfficeUser = this.jeveBaraService.activateOrDeactivateBackOfficeUser(id)
          .subscribe(async (res) => {
          (await loading).dismiss();                 
            this.loginService.showAlert("supprimer administrateur","Administrateur supprimer avec succès !");
            this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();
          },async e => {
            (await loading).dismiss();
            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
          });
        }else{

        }
      });
    }

    supprimerCategorie(id : number){
      this.subshowConf1irmAlert = this.loginService.showConfirmAlert("supprimer","Voulez-vous vraiment supprimer cette categorie ?").afterClosed().subscribe(async (res) => {
        if(res){
          this.sousCat.forEach(element => {
            this.jeveBaraService.activateOrDeactivateSousCategorie(element?.id).subscribe(res => { },
            e => {
              this.loginService.showAlert("Désololé","une erreur c'est produite lors de la suppression des sous catégories !");
              return;
            });
          });
          const loading = this.loginService.loading();
          (await loading).present();
          this.subactivateOrDeactivateCategorie = this.jeveBaraService.activateOrDeactivateCategorie(id)
          .subscribe(async (res) => {
          (await loading).dismiss();                 
            this.loginService.showAlert("Supprimer categories","Categorie supprimer avec succès !");
            this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();
          },async e => {
            (await loading).dismiss();
            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
          });
        }else{

        }
      });
    }

    private apiUrl = environment.apiUrl;
    getUser(){
      this.selectedIndex = 0;
      this.subgetAllBackOfficeUser = this.jeveBaraService.getAllBackOfficeUser({page:"0",size:"10000"}).subscribe( (res) => {
          this.AllAdmin = [];
          res.data.forEach(element => {
            if((element.actif) && (element.role != "ADMIN")){
              console.log("User : "+JSON.stringify(element));
              this.AllAdmin = this.AllAdmin.concat([element]);
            }
          });
      });
      
        this.subgetCategorieList = this.jeveBaraService.getAllSuperCategorie().subscribe( (res) => {
        this.AllCategorie = [];
        let egorie :ICategorie;
        console.log("categorie : "+JSON.stringify(this.AllCategorie));
        res.forEach(element => {
              element.referenceFichier = this.apiUrl +"api/file?fileReference="+ element.referenceFichier;
              this.AllCategorie = this.AllCategorie.concat([element]);
              element.sousCategories.forEach(element => {
                if(element.actif){
                    element.referenceFichier = this.apiUrl +"api/file?fileReference="+ element.referenceFichier;
                }
              });
        });
          this.currentCat = res[0];
          this.sousCat = res[0].sousCategories;
      });
      this.localStorageProvider.getItem("user").then((data:any)=>{
        console.log("parametre user get before : "+JSON.stringify(data));
        data = JSON.parse(data);
        this.ancienPin = this.user.ancienPin = data?.ancienPin;
        this.email = this.user.email = data?.email;
        this.role = this.user.role = data?.role;
        this.tel = this.telephone = this.user.telephone = data?.telephone;
        this.nom = this.user.nom = data?.nom;
        this.prenom = this.user.prenom = data?.prenom;
        console.log("parametre user get after : "+JSON.stringify(data));
      });
    }

    restForm(){
      this.email = "";
      this.nom = "";
      this.pin = "";
      this.nouveauPin = "";
      this.prenom = "";
      this.referenceFichier = "";
      this.telephone = "";
      this.role = "";          
    }

  async resetProfil(){
    let data = {
      email	: this.email,
      nom	: this.nom,
      prenom	: this.prenom,
      telephone	: this.telephone
    };
    console.log("reset Profile : "+ JSON.stringify(data));
    console.log("email : "+ this.email+" nom : "+ this.nom+" prenom : "+ this.prenom+" telephone : "+ this.telephone);
    console.log(JSON.stringify(data));

    const loading = this.loginService.loading();
    (await loading).present();
    this.subupdateCurentAdmin = this.jeveBaraService.updateCurentAdmin(data).subscribe( async (res) => {
    (await loading).dismiss();                 
        this.user.email = DashboardPage.user.email = data?.email;
        this.user.nom = DashboardPage.user.nom = data?.nom;
        this.user.prenom = DashboardPage.user.prenom = data?.prenom;   
        this.user.telephone = DashboardPage.user.telephone = data?.telephone;   
        this.jeveBaraService.updateAdminEmiter.emit(DashboardPage.user);
        this.loginService.showAlert("Modification","Votre compte à été modifié avec succes !");
      
      await this.localStorageProvider.setItem("user", JSON.stringify(this.user) );
      this.sublogin = (await this.loginService.login({ telephone: this.user.email, pin: this.user.ancienPin })).subscribe();

    },async e => {
        (await loading).dismiss();
        console.log("Erreur de modification "+(e.error.message));
        console.log("Erreur : "+ JSON.stringify(e.error));
        if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
          this.loginService.showAlert("Erreur de modification", "Mot de passe pas correct !");          
        }else{ if(e.error.message == "PHONE_NUMBER_ALREADY_EXISTS"){
          this.loginService.showAlert("Erreur de modification", "Ce numéro existe déjà !");   
         }else{
            this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
         }
        }
    });
  }

  async resetPassword(){
    console.log("ancien Password : "+ this.pin+" nouveau Password : "+ this.nouveauPin+" confirm nouveau Password : "+ this.telephone);

    let password = this.pin;
    let newPassword = this.nouveauPin;
    let newPassword2 = this.telephone;
    if(newPassword === newPassword2){
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.resetPasswordOfCurrentAdmin({"ancienPin"	: password, "nouveauPin"	: this.nouveauPin }).
      subscribe( async (res) =>  {
          (await loading).dismiss();                 
          this.loginService.showAlert("Modifier mot  de passe","mot de passe modifié avec success !");
          this.user.ancienPin = this.nouveauPin;
          await this.localStorageProvider.setItem("user", JSON.stringify(this.user) ) ;
      },async e => {
        (await loading).dismiss();
        console.log("Erreur de modification "+JSON.stringify(e.error.message));
        if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
          this.loginService.showAlert("Erreur de modification", "Mot de passe incorrect !");          
        }else{ if(e.error.message == "PHONE_NUMBER_ALREADY_EXISTS"){
          this.loginService.showAlert("Erreur de modification", "Ce numéro existe déjà !");   
         }else{
            this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
         }
        }
      });
    }else{
      this.loginService.showAlert("Incompatibilité","Les mots de passe saisis ne sont pas identiques !");
    }
  }

  new_user(){
    this.subshowAddNewUser = this.loginService.showAddNewUser(null).afterClosed().subscribe( (res) => {
      if(res){
      }else{
      }
    });
  }

  new_categorie(){
    this.subshowAddCategorie = this.loginService.showAddCategorie(null).afterClosed().subscribe( (res) => {
      if(res){
      }else{
      }
    });
  }

  async segmentChanged() {
    await this.slider.slideTo(this.segment);
    if(this.segment==0){
    }
    else if(this.segment==1){
    }    
  }

  async slideChanged() {
    this.segment = await this.slider.getActiveIndex();
  }

  profile(){
    this.telephone = this.tel
    this.profileParam = true;
    this.passwordParam = false; 
    this.categorieParam= false; 
    this.gestionParam = false;
  }
  
  password(){
    this.telephone = "";
    this.passwordParam = true;
    this.profileParam = false;
    this.categorieParam = false;
    this.gestionParam = false;
  }
  
  gestion(){
    this.gestionParam = true;
    this.profileParam = false;
    this.categorieParam = false;
    this.passwordParam = false;
  }
  
  categori(){
    this.categorieParam = true;
    this.gestionParam = false;
    this.profileParam = false;
    this.passwordParam = false;
  }

  async ngOnInit() {
     const loading = this.loginService.loading();
     this.subdeleteOrUpdateBackOfficeUser = this.jeveBaraService.deleteOrUpdateBackOfficeUser.subscribe(async (res) => {
     (await loading).dismiss();                 
      this.getUser();
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });

    this.subaddBackOff1ice = this.jeveBaraService.addBackOffice.subscribe( (res) => {
      this.subgetAllBackOfficeUser = this.jeveBaraService.getAllBackOfficeUser({page:"0",size:"1000"}).subscribe( (res) => {
        this.getUser();
      });
    });
    
    this.subupdateAdminEmiter = this.jeveBaraService.updateAdminEmiter.subscribe( async (data) => {
    (await loading).dismiss();                 
    await this.localStorageProvider.getItem("user").then( async res => {
      data = JSON.parse(data);
      this.ancienPin = this.user.ancienPin = data?.ancienPin;
      this.email = this.user.email = data?.email;
      this.role = this.user.role = data?.role;
      this.tel = this.telephone = this.user.telephone = data?.telephone;
      this.nom = this.user.nom = data?.nom;
      this.prenom = this.user.prenom = data?.prenom;

      this.user = res;
      this.user.email = data?.email;
      this.user.nom = data?.nom;
      this.user.prenom = data?.prenom;
      this.user.telephone = data?.telephone;
      console.log("parametre user  : "+JSON.stringify(this.user));
      await this.localStorageProvider.setItem("user", this.user );
    });
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }
}
