import { LoginService } from 'src/app/services/login.service';
import { DebiterWalletComponent } from './../debiter-wallet/debiter-wallet.component';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IWallet } from 'src/app/models/entities/iwallet';
// import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-wallet',
  templateUrl: './add-wallet.component.html',
  styleUrls: ['./add-wallet.component.scss'],
})
export class AddWalletComponent implements OnInit {
  numero = "";
  typeWallet = "";
  
  periodess  = [
    {value: 'ORANGE', viewValue: 'Orange Money'},
    {value: 'MTN', viewValue: 'Mtn Money'},
    {value: 'MOOV', viewValue: 'Moov Money'},
    {value: 'WAVE', viewValue: 'Wave'},
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<AddWalletComponent>,
  public jeveBaraService: JeveBaraService,
  public loginService: LoginService ) { }

ngOnInit() {}

  async addWallet(){
    this.closeDialog(true);
    console.log("numero : " + this.numero,"typeWallet : " + this.typeWallet)    
    const loading = this.loginService.loading();
    (await loading).present();
    this.jeveBaraService.saveWalet(this.data.id, {"numero" : this.numero,"typeWallet" : this.typeWallet}).subscribe(async (res) => {    
      (await loading).dismiss();                 
      this.loginService.showAlert("Ajout du wallet","Wallets ajouté avec success !");
      this.jeveBaraService.userEmitter.emit();
    },async error => {
      (await loading).dismiss();
      this.loginService.showAlert("Ajout du wallet","Désolé une erreur inattendue est survenue !");   
      console.log(error);   
    });
  }

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}