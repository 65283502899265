import { MatPaginatorIntl } from "@angular/material/paginator";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Elements par page';
  customPaginatorIntl.lastPageLabel = 'Dernier';
  customPaginatorIntl.firstPageLabel = 'Premier';
//   customPaginatorIntl.getRangeLabel = 'Custom_Label';
  customPaginatorIntl.nextPageLabel = 'Suiv';
  customPaginatorIntl.previousPageLabel = 'Prev';
//   customPaginatorIntl.changes = 'Custom_Label';

  return customPaginatorIntl;
}