import { IPositionnement } from './../../models/entities/ipositionnement';
import { IGrouilleur } from './../../models/entities/igrouilleur';
// import { offrom } from 'rxjs';
import { JeveBaraService } from './../../services/jeve-bara.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import {  AfterViewInit, ComponentFactoryResolver, OnDestroy, ViewChild } from '@angular/core';
import { IBoss } from 'src/app/models/entities/iboss';
// import { ifError } from 'assert';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Component, OnInit, Input } from '@angular/core';
import { AlertController, IonSlides } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabHeader } from '@angular/material/tabs';
import { IOffre } from 'src/app/models/entities/ioffre';

@Component({
  selector: 'app-offres',
  templateUrl: './offres.component.html',
  styleUrls: ['./offres.component.scss'],
})
export class OffresComponent implements OnInit, OnDestroy {
  getOffresubscription: any;
  subsaffectterBara: any;
  subsgetGrouilleurDetails: any;
  subsvaliderOffre: any;
  offreO: any;
  idOffreUp: number;


  ngOnDestroy(): void {
    // this.getOffresubscription.unsubscribe();      
    // this.subsaffectterBara.unsubscribe();
    // this.subsgetGrouilleurDetails.unsubscribe();
    // this.subsvaliderOffre.unsubscribe();
  }
  ELEMENT_DATA: IOffre[] = [{}];
  @ViewChild('slides', { static: true }) slider: IonSlides;
  offre : boolean = true;
  detailO : boolean = false;
  detailG : boolean = false;
  id: number;
  private isActivate = false;
  grouilleur: IGrouilleur;
  @Input() idOffre = null;

      constructor(private dialog: MatDialog,
          private loginService: LoginService,
          private alertController : AlertController,
          public jeveBaraService: JeveBaraService) {
            console.log(this.jeveBaraService.offreId+" est cette id de l'offre .....................")
                  this.offre = true;
                  this.detailO = false;
                  this.detailG = false;

                  if(this.jeveBaraService.offreId > 0){
                    this.idOffre = this.jeveBaraService.offreId;
                    let id = this.jeveBaraService.offreId;
                    this.getDetailOffre(this.jeveBaraService.offreId);
                    this.jeveBaraService.offreId = 0; 
                  }else{                  
                    this.offre = true;
                    this.detailG = false;
                    this.detailO = false;
                    this.getOffresubscription = jeveBaraService.getOffres({page : "0" , size : '1000'}).subscribe( (res) => {
                      this.ELEMENT_DATA = res.data;           
                    });
                }      
                this.jeveBaraService.offreId = null;
      }

        retirer(event : IPositionnement){
          console.log("id de user : "+JSON.stringify(event)+" id de l'offre : "+this.id);
          if(event !== null){
            this.loginService.showRemoveUserFromPositionnement("RETRAIT SUR UN POSITIONNEMENT","voulez-vous ce positionnement ?").
              afterClosed().subscribe(async res => {
                if(res){
                  const loading = this.loginService.loading();
                  (await loading).present();
                  this.jeveBaraService.refuserPositionnement(event.id).subscribe( async (res) => {
                  (await loading).dismiss();                 
                    this.loginService.showAlert("retrait", "Utilisateur retiré avec success !");
                    this.jeveBaraService.positionementEmit.emit();
                  },async e => {
                    (await loading).dismiss();
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  });
                }else{
                }
              });
          }
          this.offre = true;
          this.detailO = false;
          this.detailG = false;
        }

        affecter(id : number){
          this.subsaffectterBara = this.loginService.showAffecterBara(this.jeveBaraService.offreId+"","").afterClosed().
          subscribe(res => {
          });
        }

        offreattente = false
        offreo(){
          this.offre = true;
          this.offreattente = false;
          this.detailO = false;
        }
        
        attente(){
          this.offre = false;
          this.offreattente = true;
          this.detailO = false;
        }
        
        detail(){
          this.offre = false;
          this.offreattente = false;
          this.detailO = true;
        }

        getDetailOffre(id: number){
          this.jeveBaraService.offreId = id;
          this.id = id;
          this.idOffre = id;
          this.idOffreUp = 0;
          
          this.jeveBaraService.getOffreById(''+id).subscribe( async (res) =>{
              console.log(JSON.stringify("error : statutSpecifique" + this.jeveBaraService.offreId));
              console.log(JSON.stringify(res.statutSpecifique));
              this.offreO = res;   
              },async e => {
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              });

          this.offre = false;
          this.detailG = false;
          this.detailO = true;
        }

        goBack2(id: number){
          this.jeveBaraService.id = id;
          this.offre = false;
          this.detailG = false;
          this.detailO = true;
          this.id = id;
        }
    
        goBack(id: number){
          this.jeveBaraService.id = id;
          this.id = id;
          this.offre = true;
          this.detailO = false;
          this.detailG = false;

          
          this.offre = false;
          this.offreattente = false;
          this.detailO = true;
        }

        async getDetailGrouilleur(id: number){
          this.offre = false;
          this.offreattente = false;
          this.detailO = true;
          this.jeveBaraService.id = id;
          this.id = id;
          const loading = this.loginService.loading();
          (await loading).present();
          this.subsgetGrouilleurDetails = this.jeveBaraService.getGrouilleurDetails(this.jeveBaraService.id)
          .subscribe( async (res) => {
              (await loading).dismiss();                 
              console.log("Grouilleur de l'id "+this.jeveBaraService.id+" : :-) " + JSON.stringify(res));
              this.grouilleur = res;  
              },async e => {
                (await loading).dismiss();
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              });
        }

        getDetailOffres(id: number){
          this.idOffre = 0;
          this.jeveBaraService.id = id;
          this.id = id;
          this.offre = false;
          this.detailG = false;
          this.detailO = true;
        }

    

    con(id: number){
      this.loginService.showOffreDetail("", id);
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    print(row){
      this.showAlert(row.name);
    }
  
    async valider(id){
      const loading = this.loginService.loading();
      (await loading).present();
      this.subsvaliderOffre = this.jeveBaraService.validerOffre(id).subscribe(async (res) => {
        (await loading).dismiss();                 
        this.loginService.showAlert("Valider Barra", "Bara valider avec success !");
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    }
  
    async annuler(idOffre){
      if(this.offreO.statutSpecifique === 'ANNULER')
        return;
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.annulerOffre(idOffre).subscribe(async (res) => {
        (await loading).dismiss();    
        // this.je.detailBara.emit();    
        this.loginService.showAlert("Annuler Barra", "Bara annulé avec success !");
        this.jeveBaraService.traiterOffre.emit();         
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé", "Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    }
  
    async terminer(idOffre){
      if(this.offreO.statutSpecifique === 'ANNULER')
        return;
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.terminerOffre(idOffre).subscribe(async (res) => {
        (await loading).dismiss();                 
        this.loginService.showAlert("Terminer Barra", "Bara terminé avec success !");
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé", "Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    }

    showAlert(msg) {
      let alert = this.alertController.create({
        message: msg,
        header: 'Login',
        buttons: ['OK']
      });
      alert.then(alert => alert.present());
    }
  
    ngOnInit() {
      this.dataSource.paginator = this.paginator
    }

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
}

const ELEMENT_DATA: PeriodicElement[] = [
];

export interface PeriodicElement {
}