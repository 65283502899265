import { DashboardPage } from 'src/app/pages/dashboard/dashboard.page';
import { IUser } from 'src/app/models/entities/iuser';
import { LoginService } from 'src/app/services/login.service';
import { ITache } from './../../models/entities/itache';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBoss } from 'src/app/models/entities/iboss';
import { MatTableDataSource } from '@angular/material/table';
import { IBadge } from 'src/app/models/entities/ibadge';
import { IOffre } from 'src/app/models/entities/ioffre';
import { MatPaginator } from '@angular/material/paginator';
import { IGrouilleur } from 'src/app/models/entities/igrouilleur';
// import { MAT_DIALOG_DATA } from '@angular/material';

const ELEMENT_DATA: IGrouilleur[] = [];
const badge_DATA: IBadge[] = [];
const offre_DATA: IOffre[] = [];

@Component({
  selector: 'app-take-tache',
  templateUrl: './take-tache.component.html',
  styleUrls: ['./take-tache.component.scss'],
})
export class TakeTacheComponent implements OnInit {
  id : number = null;
  message: string = null;
  tache: ITache[] = null;
  boss: any[] = null;

  
  @ViewChild(MatPaginator) paginator : MatPaginator;

  dataSource = new MatTableDataSource<IBoss>(ELEMENT_DATA) ;
  badgeSource = new MatTableDataSource<IBadge>(badge_DATA) ;
  userSource = new MatTableDataSource<IOffre>(offre_DATA) ;
  tacheSource = new MatTableDataSource<ITache>(offre_DATA) ;
  // Information
  displayedColumns1: string[] = ['Nom', 'Prénom', 'Email'];
  Information: string[] = ['Téléphone', 'Adresse'];
  Information2: string[] = ['Date de Creation','libelle','Statut'];
  user: IUser;
  quartier: any;
//  {
//   "id": 3830,
//   "dateCreation": "2022-09-05T19:44:30.227926",
//   "dateModification": "2022-09-07T05:25:26.911271",
//   "libelle": "Paiement en attente",
//   "typeProfil": null,
//   "statutTache": "NON_TRAITER",
//   "infosBadge": null,
//   "infosOffre": null,
//   "infosRealisation": null,
//   "infosTransaction": {
//     "id": 3828,
//     "dateCreation": "2022-09-05T19:44:30.204472",
//     "dateModification": "2022-09-05T19:44:30.204485",
//     "typeOperation": "CREDIT",
//     "description": "Paiement du bara 3758",
//     "valeur": 26600,
//     "moyenOperation": "INTERNE",
//     "statutTransaction": "EN_ATTENTE",
//     "typeSolde": "CAGNOTTE",
//     "emetteur": "Omgba Louis",
//     "destinataire": "Kouotou Zounedou",
//     "numeroTransaction": "e0daefa1-5c86-4e8f-b1a1-522d67786a82",
//     "idOffre": null
//   },
//   "nomAdminUtilisateur": "Admin",
//   "prenomAdminUtilisateur": "admin",
//   "idAdminUtilisateur": 149
// },
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<TakeTacheComponent>,
  public jeveBaraService: JeveBaraService,
  private loginService: LoginService ) { 
    this.id = data.title;
    this.message = data.message;
    
     const loading = this.loginService.loading();
     this.jeveBaraService.getTacheByid(this.id).subscribe( async (res) => {
     (await loading).dismiss();                 
      this.tache = [res];      
      console.log("tache details : ");
      console.log(res);
      this.dataSource = new MatTableDataSource<IBoss>(this.boss) ;
      this.tacheSource = new MatTableDataSource<ITache>(this.tache) ;
      if(res.typeProfil=="BOSS"){        
        const loading = this.loginService.loading();
        (await loading).present();
            this.jeveBaraService.getBossById(res.infosOffre.boss.id).subscribe(async (grou) => {
              (await loading).dismiss();                 
              this.user = grou;
              this.userSource = new MatTableDataSource<IUser>([this.user]) ;
              console.log("grouilleur details B : ");
              console.log(JSON.stringify(grou));
            },async e => {
              (await loading).dismiss();
              this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
            });
      }else{
        if(res.typeProfil=="GROUILLEUR"){
          if(res.infosBadge!=null){            
            const loading = this.loginService.loading();
            (await loading).present();
                  this.jeveBaraService.getGrouilleurById(res.infosBadge.grouilleur.id).subscribe(async (grou) => {
                    (await loading).dismiss();                 
                    this.user = grou;
                    this.userSource = new MatTableDataSource<IUser>([this.user]) ;
                    console.log("grouilleur details G1 : ");
                    console.log(JSON.stringify(grou));
                  },async e => {
                    (await loading).dismiss();
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  });
                }else{                  
                  const loading = this.loginService.loading();
                  (await loading).present();

                  this.jeveBaraService.getGrouilleurById(res.infosRealisation.grouilleur.id).subscribe(async (grou) => {
                    (await loading).dismiss();                 
                    this.user = grou;
                    this.userSource = new MatTableDataSource<IUser>([this.user]) ;
                    console.log("grouilleur details G1 : ");
                    console.log(JSON.stringify(grou));
                  },async e => {
                  (await loading).dismiss();
                  this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                });
            }
        }       
      }
    },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    
  }
  
  parentComponent(id : number){
    this.jeveBaraService.offreDetailProfil.emit(id) 
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.badgeSource.paginator = this.paginator;
    this.userSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.badgeSource.filter = filterValue.trim().toLowerCase();
    this.userSource.filter = filterValue.trim().toLowerCase();
  }
  async prendreTAche(){
    
     const loading = this.loginService.loading();
    (await loading).present();
    this.jeveBaraService.selectionnerTache(this.id).subscribe(async (res) => {
      (await loading).dismiss();                 
      this.loginService.showAlert("Prendre tâche","opération éffectué avec succès !");
      this.jeveBaraService.tacheEmit.emit();
      this.jeveBaraService.notif.emit();
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    })
    this.closeDialog(true);
  }
  attribuerTAche(){
    this.loginService.showAffecterTache(this.id+"","").afterClosed().
    subscribe(res => { });
    this.closeDialog(true);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.badgeSource.paginator = this.paginator;
    this.userSource.paginator = this.paginator;
  }

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}

