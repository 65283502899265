import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { IBoss } from './../../models/entities/iboss';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';

@Component({
  selector: 'app-boss-profil',
  templateUrl: './boss-profil.component.html',
  styleUrls: ['./boss-profil.component.scss'],
})
export class BossProfilComponent implements OnInit {
  boss : any;
  pour : boolean = true;
  boutonText: string ;
  private jveBaraService: JeveBaraService
  
  constructor(private loginService: LoginService,
              private dialog: MatDialog,
              public jeveBaraService: JeveBaraService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<DialogUpdateBossComponent>) {
                jeveBaraService.getAdminById(data?.id).subscribe( (res)=>{
                  this.boss = res;
                  if(res.actif){
                    this.boutonText  = 'Desactiver';
                  }else{
                    this.boutonText = "Activer";
                  }
                });
                if(data?.id != -1){
                  this.pour = false;
                }
     }

     
    delete(){
      this.onClose();
      this.loginService.showConfirmAlert("supprimer",'voulez-vous vraiment supprimer '+ this.boss.nom  +'?').afterClosed().
      subscribe(async res => {
        if(res){
          if(this.boss.actif){            
            const loading = this.loginService.loading();
            (await loading).present();
            this.jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
    //  this.jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
              // this.jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
                    (await loading).dismiss();                 
              this.loginService.showAlert( "Desactivation","compte desactivé avec success !");
            },async e => {
              (await loading).dismiss();
              console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
              if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
              }else{
                if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                  this.loginService.showAlert("Activation","Compte déjà actif !"); 
                }else{
                  this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                }
              }});      
          }else{            
            const loading = this.loginService.loading();
            (await loading).present();
           
     this.jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
              // this.jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
            (await loading).dismiss();                 
             this.loginService.showAlert( "activation","compte activé avec success !");
           },async e => {
            (await loading).dismiss();
            console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
            if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
              this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
            }else{
              if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                this.loginService.showAlert("Activation","Compte déjà actif !"); 
              }else{
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              }
            }});      
          }
          console.log(this.boss.actif);
        }
          console.log("resultat : "+res);   
      });       
    }
    
    desactiver(){
      this.onClose();
      this.loginService.showConfirmAlert(this.boutonText, 'voulez-vous vraiment '+ this.boutonText +'?').
      afterClosed().subscribe(async res => {
        if(res){
          console.log(this.boss.actif);
          if(this.boss.actif){
              const loading = this.loginService.loading();
              (await loading).present();
              
     this.jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
              // this.jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
                (await loading).dismiss();                 
                this.loginService.showAlert( "Desactivation","compte desactivé avec success !");
              },async e => {
                (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
                }});      
          }else{
              const loading = this.loginService.loading();
              (await loading).present();
              
     this.jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
              // this.jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
                (await loading).dismiss();                 
                this.loginService.showAlert( "activation","compte activé avec success !");
              },async e => {
                (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
            }});         
          }   
        }
          console.log("resultat : "+res);   
      });       
      console.log(this.boss.actif);
    }
   
    async activer(){
      const loading = this.loginService.loading();
      (await loading).present();
      
     this.jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
              // this.jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
        (await loading).dismiss();                 
        this.loginService.showAlert("compte activé avec success !");
      },async e => {
        (await loading).dismiss();
        console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
        if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
          this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
        }else{
          if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
            this.loginService.showAlert("Activation","Compte déjà actif !"); 
          }else{
            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
          }
        }});      
      this.onClose();
    }
  ngOnInit() {}

  onClose(){
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close(false);
  }
}
