import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { IBoss } from 'src/app/models/entities/iboss';
import { LoginService } from 'src/app/services/login.service';
// import { ConfirmDialogComponent } from '../confim-dialog/confim-dialog.component';
// import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';

@Component({
  selector: 'app-dialog-update-boss',
  templateUrl: './dialog-update-boss.component.html',
  styleUrls: ['./dialog-update-boss.component.scss'],
})
export class DialogUpdateBossComponent implements OnInit {
  boss : IBoss;
  pour : boolean = true;

  loginService: LoginService;
  constructor(private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data,
      public dialogRef: MatDialogRef<DialogUpdateBossComponent>) {
      if(data?.id != -1){
        this.pour = false;
      }
  }

  ngOnInit() {}

  onClose(){
    this.dialogRef.close();
  }

  
  addBoss(msg){
    this.closeDialog();
    console.log(this.boss);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg
    }
    this.dialog.open(DialogUpdateBossComponent, dialogConfig);
    this.closeDialog();
  }

  delete(){
    this.closeDialog();
    this.addBoss('');
    this.closeDialog();
  }
  
  closeDialog(){
    this.dialogRef.close(false);
  }

}
