import { Injectable } from '@angular/core';
import { StorageConfig, Storage } from '@ionic/storage';
import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';


const SECRET_KEY = 'secret_key';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private storage: Storage;

  secureStorage;
  
  constructor(){
    let config : StorageConfig = {
      name:"jevebara_db",
      storeName:"jevebara_db",
      dbKey :"jevebara_db@123",
    
      //driverOrder: ['indexeddb', 'localstorage']
    }
    
    console.log("CreateDB",config.name);
    this.storage = new Storage(config);
  }

  initializeStorage(){    

    this.secureStorage = new SecureStorage(localStorage, 

      {
       hash: function hash(key): any {
          key = CryptoJS.SHA256(key, SECRET_KEY);
          return key.toString();
      },
      
      // Encrypt the localstorage data
      
      encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);    
        data = data.toString();    
        return data;
      },
      // Decrypt the encrypted data
      decrypt: function decrypt(data) {    
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);    
        data = data.toString(CryptoJS.enc.Utf8);    return data;
      }
    });

   return  this.storage.create();
 
   }

   async set(key:string,value :any){
    await this.storage.set(this.hash(key),this.encrypt(value)); 
   }

   async get(key:string){
    return this.decrypt(await this.storage.get(this.hash(key)));
   }

  private encrypt(data) {
     
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);    
    data = data.toString();    
    return data;
  }
  // Decrypt the encrypted data
  private decrypt(data) {   
    //return data;
    try{
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);    
      data = data.toString(CryptoJS.enc.Utf8);    
      return data;
    }catch(e){
      return null;
    }

  }

  private  hash(key): any {
    //return key;
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  }

  clear(){
    return this.storage.clear();
  }

  remove(key){
    return this.storage.remove(key);
  }

}
