// import { MaterialModule } from '../../material-module'
import { IGrouilleur } from './../../models/entities/igrouilleur';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grouilleur-profil',
  templateUrl: './grouilleur-profil.component.html',
  styleUrls: ['./grouilleur-profil.component.scss'],
})
export class GrouilleurProfilComponent implements OnInit {
  grouilleur : IGrouilleur;
  jveBaraService: JeveBaraService;
  boutonText: string;
  panelOpenState = false;

  constructor(private loginService: LoginService,
              private dialog: MatDialog,
              public jeveBaraService: JeveBaraService,
              @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogUpdateBossComponent>) {
      console.log(data?.id);
      this.getGrouilleurs(data.id);
     }

  getGrouilleurs(id  : number) {
      this.jeveBaraService.getGrouilleurDetails(id).subscribe( (res)=>{
      console.log(res);
      this.grouilleur = res;
      if(res.actif){
        this.boutonText  = 'Desactiver';
      }else{
        this.boutonText = "Activer";
      }
    });
  }

     valider(id, item){
       item.statut = 'VALIDE';
      this.jeveBaraService.validerBages(id).subscribe( res => {
        console.log("valider : "+ res);
        this.jeveBaraService.updataBage.emit();
      });
     }

     invalider(id, item){
       item.statut = 'NON_VALIDE';
      this.jeveBaraService.invaliderBages(id).subscribe( res => {
        console.log("invalider : "+ res);
        this.jeveBaraService.updataBage.emit();
      });
     }
     
    private apiUrl = environment.apiUrl;

     ouvrir(path){  
        console.log(path);
        const url = this.apiUrl +"api/file?fileReference="+ path;
        
        window.open( url, "_blank");
     }
     
     download(path){
        path.forEach(element => {
          console.log(element.reference);
          this.ouvrir(element.reference);
        });
     }
     
     delete(){
      this.onClose();
      this.loginService.showConfirmAlert("supprimer",'voulez-vous vraiment supprimer '+ this.grouilleur.nom  +'?').afterClosed().
      subscribe(res => {
        if(res){
          if(this.grouilleur.actif){
            // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( (res)=> {
              this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( (res)=> {
               this.loginService.showAlert( "Desactivation","compte desactivé avec success !");
             },async e => {
                // (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
                }});      
          }else{
          //  this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( (res)=> {
            this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( (res)=> {
             this.loginService.showAlert( "activation","compte activé avec success !");
           },async e => {
                // (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
                }});          
          }
        }
      });       
    }

    
    desactiver(){
      this.onClose();
      this.loginService.showConfirmAlert(this.boutonText, 'voulez-vous vraiment '+ this.boutonText +'?').
      afterClosed().subscribe(res => {    
        if(res){
          console.log(this.grouilleur.actif);
           if(this.grouilleur.actif){
              // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( (res)=> {
                this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( (res)=> {
                this.loginService.showAlert( "Desactivation","compte desactivé avec success !");
              },async e => {
                // (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
                }});      
           }else{
            // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( (res)=> {
              this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( (res)=> {
              this.loginService.showAlert( "activation","compte activé avec success !");
            },async e => {
              // (await loading).dismiss();
              console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
              if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
              }else{
                if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                  this.loginService.showAlert("Activation","Compte déjà actif !"); 
                }else{
                  this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                }
              }});            
           }   
        }
      });       
    }
    
  ngOnInit() {
  }

  onClose(){
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close(false);
  }

}
