import { AddNewUserComponent } from 'src/app/AlertComponents/add-new-user/add-new-user.component';
import { AppService } from './app.service';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { GoogleChartsModule } from 'angular-google-charts';

import { HeaderInterceptor } from './services/interceptors/HeaderInterceptor';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import {MaterialModule} from './material-module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser';
import { TokenInterceptor } from './services/interceptors/TokenInterceptor';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { ListeAttenteGrouilleurPipe } from './pipes/liste-attente-grouilleur.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './CustomPaginatorConfiguration';
// import { CustomPaginator } from './CustomPaginatorConfiguration';

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    AddNewUserComponent,
    // ListeAttenteGrouilleurPipe
  ],
  entryComponents: [],
  imports: [
    // Ng2GoogleChartsModule ,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    MatSliderModule, 
    MaterialModule, 
    BrowserAnimationsModule,  
    HttpClientModule,
    CommonModule,
    GoogleChartsModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    AppService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { useClass : TokenInterceptor, provide : HTTP_INTERCEPTORS, multi : true },
    { useClass : HeaderInterceptor, provide : HTTP_INTERCEPTORS, multi : true },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
