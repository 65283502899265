import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  constructor(private locaStorageService : LocalStorageService,
		private router: Router){
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
        console.log("can load dashboard");
        
      //  return new Promise((resolve,reject)=>{
      //   this.locaStorageService.getItem('isLoggedIn').then(val => {
      //     console.log(" access guard ");
      //       if (localStorage.length > 0) {
      //         console.log(" to dasboard ");
      //       resolve(true);
      //       }
      //       else {
      //         console.log(" to login 1");
      //         this.router.navigateByUrl('/login');
      //         resolve(false);
      //       }
      //     }).catch(e=>{
      //       console.log(" to login 2");
      //       this.router.navigateByUrl('/login');
      //       resolve(false);
      //     })
      //  });

       return new Promise((resolve,reject)=>{
         console.log("can load dashboard");

         this.locaStorageService.getItem('isLoggedIn').then(val => {
        //  this.locaStorageService.getItem('isLoggedIn').then(val => {
        console.log(" value "+ val);

        console.log("get Item isloggedIn :"+localStorage.getItem("isLoggedIn"));

            // if (true) {
              if (localStorage.getItem("isLoggedIn")!=null) {
              console.log(" to dashboard  ");
            resolve(true);
            }else {
              console.log(" to login 1");
              this.router.navigateByUrl('/login');
              resolve(false);
            }
          }).catch(e=>{
            console.log(" to login 2");
            this.router.navigateByUrl('/login');
            resolve(false);
          })
       });
  }
  
}
