import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { IBoss } from 'src/app/models/entities/iboss';
import { ifError } from 'assert';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AlertController, IonSlides } from '@ionic/angular';

const ELEMENT_DATA: IBoss[] = [];
@Component({
  selector: 'app-boss',
  templateUrl: './boss.component.html',
  styleUrls: ['./boss.component.scss'],
})
export class BossComponent implements OnInit, OnDestroy{
  subsactiveteOrDesactivateUser: any;
  subsshowBloquerUser: any;
  subsgetBossById: any;
  subsgetGrouilleurById: any;
  subsuserEmitter: any;
  subsimageProfil: any;
  subsgetAllBoss: any;
  subuserEmitter: any;

  ngOnDestroy(): void {
    // this.subsactiveteOrDesactivateUser.unsubscribe();      
    // this.subsshowBloquerUser.unsubscribe();
    // this.subsgetBossById.unsubscribe();
    // this.subsgetGrouilleurById.unsubscribe();
    // this.subsuserEmitter.unsubscribe();      
    // this.subsimageProfil.unsubscribe();
    // this.subsgetAllBoss.unsubscribe();
    // this.subuserEmitter.unsubscribe();
  }
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol','valider'];
  @ViewChild(MatPaginator) paginator : MatPaginator;
  dataSource = new MatTableDataSource<IBoss>(ELEMENT_DATA) ;
  dataSource1 :any ;

  @ViewChild('slides', { static: true }) slider: IonSlides;
  segment = 0;

  userStat1 : string = "userStat1";
  bossTable : boolean = true;
  boss : any;
  
  nom: string = "Liste Des Boss En Attente";
  pin:          string;
  prenom:       string;
  quartier:     string;
  telephone:    string;
  
  offre : boolean = true;
  detailO : boolean = false;
  detailB : boolean = false;
  
  id: number;

  @Input() idBoss: number = null;

  constructor(private dialog: MatDialog,
              private loginService: LoginService,
              private alertController : AlertController,
              public jeveBaraService: JeveBaraService) {
                console.log("data in component : "+this.idBoss);
                if(this.jeveBaraService.bossId){
                  this.getDetailBoss(this.jeveBaraService.bossId);
                }else{
                  this.addBoss();
                  this.offre = true;
                  this.detailB = false;
                  this.detailO = false;
                }
                this.jeveBaraService.bossId = null;
  }
      
      private path = "";
      getImagePath(path : string){
        this.path = path;
      }

      ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
      }

      send_message(id : number){
        this.loginService.showSendMessageToUser(id, "boss");
      }

      addBoss(){
        let res : IBoss[] = [];
        this.subsgetAllBoss = this.jeveBaraService.getAllBoss({page : '0' , size : '100'}).subscribe( (res) => {
          res = res.data;  
          console.log('tableau de grouileur add'+ JSON.stringify(res));      
          this.dataSource = new MatTableDataSource<IBoss>(res) ;
          this.dataSource.paginator = this.paginator;
        });
      }

  maFonction(){
    this.subuserEmitter = this.jeveBaraService.userEmitter.subscribe( (res) => {
      this.subsgetAllBoss = this.jeveBaraService.getAllBoss({page : '0' , size : '100'}).subscribe( (res) => {
        res = res.data;      
        this.dataSource = new MatTableDataSource<IBoss>(res) ;
        this.dataSource.paginator = this.paginator;
      });
    });
  }
  
  ngOnInit() {
    
    this.jeveBaraService.userEmitter.subscribe((res) => {
      this.getDetailBoss(this.boss.id);
    });

    this.subsimageProfil = this.jeveBaraService.imageProfil.subscribe( (res) => {
      this.path = res;
    });    
    this.dataSource.paginator = this.paginator    
    this.subsuserEmitter = this.jeveBaraService.userEmitter.subscribe( () => {
      this.addBoss();
    });
  }

  goToaddBoss(){
    this.loginService.showUpdateBoss("msg : string", -1);

  }
  
    delete(){
    }

    
  async slideChanged() {
    this.segment = await this.slider.getActiveIndex();
  }
  
  async segmentChanged() {
    this.nom = "Liste Des Boss En Attente";

    await this.slider.slideTo(this.segment);
    if(this.segment==0){      
    }
    else if(this.segment==1){ 
    this.nom = "Liste Des Boss Actif";
      this.displayedColumns = ['position', 'name', 'weight', 'symbol', 'etienne'];
      this.dataSource1 = new MatTableDataSource<IBoss>(ELEMENT_DATA) ;
    }else{
    }    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getDetailOffre(id: number){
    this.jeveBaraService.id = id;
    this.id = id;
    this.offre = false;
    this.detailB = false;
    this.detailO = true;
    // this.loginService.showAlert("getDetailOffre : "+id,"offre : "+this.offre+" detailO : "+this.detailO+" detailB : "+this.detailB);
  }

  //  private
  goBack2(id: number){
    this.subsgetBossById = this.jeveBaraService.getBossById(+id).subscribe( (res) => {
      this.boss = res;
      this.jeveBaraService.id = id;
      this.offre = false;
      this.detailB = false;
      this.detailO = true;
      this.id = id;
    })
    // this.goBack(id);
  }
    
  goBack(id: number){
    this.jeveBaraService.id = id;
    this.id = id;
    this.offre = true;
    this.detailO = false;
    this.detailB = false;
  }
        
  async activer(){
    const loading = this.loginService.loading();
    (await loading).present();
    this.subsgetGrouilleurById = this.jeveBaraService.getBossById(this.id).subscribe(async (res) => {
      (await loading).dismiss();                 
      // this.loginService.showAlert('id User boss : '+this.id, JSON.stringify(res));
      if(this.boss.actif)
      this.subsactiveteOrDesactivateUser = this.jeveBaraService.activeteOrDesactivateUser(res.idUser).subscribe((res) => {
        this.loginService.showAlert('Activer Boss', 'Boss activé avec success !');
      });
      
      if(this.boss.actif)
      this.subsactiveteOrDesactivateUser = this.jeveBaraService.activeteOrDesactivateUser(res.idUser).subscribe((res) => {
        this.loginService.showAlert('Activer Boss', 'Boss activé avec success !');
      });


    },async e => {
      (await loading).dismiss();
      if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
        console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
        this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
      }else{
        if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
          this.loginService.showAlert("Activation","Compte déjà actif !"); 
        }else{
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        }
      }
    });
  }

        statut = false;
  async getDetailBoss(id: number){          
    this.jeveBaraService.id = id;
    this.id = id;
    this.offre = false;
    this.detailO = false;
    this.detailB = true;
    this.idBoss = null;
    const loading = this.loginService.loading();
    (await loading).present();
    this.subsgetBossById = this.jeveBaraService.getBossById(this.jeveBaraService.id).subscribe( async (res) => {
      (await loading).dismiss();                 
      console.log("boss de l'id "+this.jeveBaraService.id+" : :-) " + JSON.stringify(res));
      this.boss = res;  
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
  }
        
  exporter(){
    // this.jeveBaraService.exporterGrouilleur();
    this.jeveBaraService.exporterBoss();
    // this.jeveBaraService.exporterBoss().subscribe((res) =>  {
    //   console.log('exporterBoss');
    //   console.log(res);
    // });
  }

  BloquerUser(){
    
    this.loginService.showBloquerUser(this.boss.idUser+"" ,"Voulez-vous vraiment supprimer "+ this.boss.nom+" ?").afterClosed()
    .subscribe( (res) => {
    if(res){
      this.jeveBaraService.userEmitter.emit();
      }else{
      }
    });
  }

  DebloquerUser(){
    this.loginService.showBloquerUser(this.boss.idUser+"" ,"Voulez-vous vraiment supprimer "+ this.boss.nom+" ?").afterClosed()
    .subscribe( (res) => {
    if(res){
      this.jeveBaraService.userEmitter.emit();
      }else{
      }
    });
  }

  getDetailOffres(id: number){
    this.jeveBaraService.id = id;
    this.id = id;
    this.offre = false;
    this.detailB = false;
    this.detailO = true;
  }

  con(id: number){
    this.loginService.showOffreDetail("", id);
  }

  print(row){
    this.showAlert(row.name);
  }

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Login',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }
}
