import { IPromotion } from './../../models/entities/ipromotion';
import { IPositionnement } from './../../models/entities/ipositionnement';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { randomInt } from 'crypto';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit, OnDestroy {

  subcreatePromotion: any;
  titre: string;
  bouton: string = "Ajouter";
  data: any = null;
  addPromotionPropertie : boolean = false;
  regle  = "" ;
  cible  = "" ; 
  dateFin  = "";
  dateDebut  = "";
  genererCode = "";
  reduction = "";
  reductionValue = "";
  libelle = "" ;

  ngOnDestroy(): void {
    // this.subcreatePromotion.unsubscribe();
  }

  async modifier(data : any){
  this.data = data;
 if(data.value == 'update'){
    this.titre = "Modifier promotion de l'id : "+data.id;
    this.bouton = "Modifier";
    const loading = this.loginService.loading();
    (await loading).present();
    this.jeveBaraService.getPromotionById(data.id).subscribe(async (res) => { 
      (await loading).dismiss();                    
      this.regle   = res.regle  ; 
      this.cible  = res.cible  ; 
      this.dateFin  = res.dateFin ;
      this.dateDebut  = res.dateDebut ;
      this.genererCode = res.code ;
      this.reduction = res.taux ? "Taux" : "valeur";
      this.reductionValue = res.valeur ;
      this.libelle  = res.libelle ;
      this.addPromotionPropertie = !this.addPromotionPropertie;
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
 }else{
  if(data.value == 'delete'){
    this.addPromotionPropertie = !this.addPromotionPropertie;
  }
 }
}

  async addPromotion(){
    if(this.data != null ){
        let prommotion = {
          "cible": this.cible,
          "code": this.genererCode,
          "dateDebut": this.dateDebut,
          "dateFin": this.dateFin,
          "libelle": this.libelle,
          "regle": this.regle,
          "taux": this.reduction =='Taux' ? true : false,
          "valeur": +this.reductionValue,
        };    
        const loading = this.loginService.loading();
        (await loading).present();
        this.jeveBaraService.updatePromotionById(this.data.id, prommotion).subscribe(async (res) => {
          (await loading).dismiss();                 
          this.data = null
          this.addPromotionPropertie = !this.addPromotionPropertie;
          this.loginService.showAlert("Modification d'une promotion", "Promotion modifié avec success !");
          this.jeveBaraService.notif.emit();
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        });
        return ;
    }else{
      let prommotion = {
        "cible": this.cible,
        "code": this.genererCode,
        "dateDebut": this.dateDebut,
        "dateFin": this.dateFin,
        "libelle": this.libelle,
        "regle": this.regle,
        "taux": this.reduction =='Taux' ? true : false,
        "valeur": +this.reductionValue,
      };  
      const loading = this.loginService.loading();
      (await loading).present();
      this.subcreatePromotion = this.jeveBaraService.createPromotion(prommotion).subscribe(async (res) => {
          (await loading).dismiss();                 
          this.addPromotionPropertie = !this.addPromotionPropertie;
          this.loginService.showAlert("Création d'une promotion", "Promotion créer avec success !");
          this.jeveBaraService.notif.emit();
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
            if(e.error.message="CODE_DEJA_UTILISER")
              return;
        });

            console.log("Bloquer User", "cibles : "+ this.cible
            +", libelle : "
            + this.libelle
            +", reduction : "
            + this.reduction
            +", reductionValue : "
            + this.reductionValue
                  +", code : "+ this.genererCode
                  +", date de debut : "+this.dateDebut
                  +" , date de fin : "+this.dateFin);
    }
  }

cibles = [
  {value: 'BOSS', viewValue: 'Boss'},
  {value: 'GROUILLEUR', viewValue: 'Groulleur'},
  {value: 'TOUS', viewValue: 'Tous'},
];

options = [
  {value: 'Taux', viewValue: 'Taux'},
  {value: 'valeur', viewValue: 'Valeur'},
];
regles = [
  {value: 'CAGNOTTE', viewValue: 'Cagnotte'},
  {value: 'CAUTION', viewValue: 'Caution'},
];
  constructor(private loginService : LoginService,
              public jeveBaraService : JeveBaraService) {
                this.titre = "Ajouter une nouvelle promotion";
              }

  ngOnInit() {
    
  }

  generer(){
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    this.genererCode = ""+Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    this.genererCode = this.genererCode+characters.charAt(Math.floor(Math.random() * charactersLength));
    this.genererCode = this.genererCode+""+Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    this.genererCode = this.genererCode+characters.charAt(Math.floor(Math.random() * charactersLength));
    this.genererCode = this.genererCode+""+Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    this.genererCode = this.genererCode+characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  annuler(){
    this.regle  = "" ;
    this.cible  = "" ; 
    this.dateFin  = "";
    this.dateDebut  = "";
    this.genererCode = "";
    this.reduction = "";
    this.reductionValue = "";
    this.libelle = "" ;

  this.titre = "Ajouter une nouvelle promotion";
  this.bouton = "Ajouter";
  this.data = null;
    console.log("Bloquer User", "cibles : "+ this.cible
    +", libelle : "
    + this.libelle
    +", reduction : "
    + this.reduction
    +", reductionValue : "
    + this.reductionValue
          +", code : "+ this.genererCode
          +", date de debut : "+this.dateDebut
          +" , date de fin : "+this.dateFin);
    this.addPromotionPropertie = !this.addPromotionPropertie;
  }
}
