import { LoginService } from 'src/app/services/login.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSlides } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabHeader } from '@angular/material/tabs';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import {MaterialModule} from '../../material-module'
import { IGrouilleur } from 'src/app/models/entities/igrouilleur';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

const ELEMENT_DATA: IGrouilleur[] = [];

@Component({
  selector: 'app-grouilleurs',
  templateUrl: './grouilleurs.component.html',
  styleUrls: ['./grouilleurs.component.scss'],
})
export class GrouilleursComponent implements OnInit, OnDestroy {
  subshowBloquerUser: any;
  subgetDesactiveGrouilleur: any;
  subgetGroulleurs: any;
  subgetGrouilleurDetails: any;
  subactiveteOrDesactivateUser: any;
  subactivateOrDeactivategrouilleur: any;
  subgetGrouilleurById: any;

  statutP  = [
    {value: 'Croissant', viewValue: 'Croissant'},
    {value: 'Decroissant', viewValue: 'Decroissant'},
  ];
  statut: string;
  filter: boolean = false;
  timerSubscription: any;

  onChangeStatut(){
    this.jeveBaraService.bossByBara.emit(this.statut);
  }

  ngOnDestroy(): void {
    // this.subshowBloquerUser.unsubscribe();
    // this.subgetDesactiveGrouilleur.unsubscribe();
    // this.subgetGroulleurs.unsubscribe();
    // this.subgetGrouilleurDetails.unsubscribe();
    // this.subactiveteOrDesactivateUser.unsubscribe();
    // this.subactivateOrDeactivategrouilleur.unsubscribe();
    // this.subgetGrouilleurById.unsubscribe();
  }

  displayedColumns: string[] = ['id', 'name', 'prenom','telephone', 'actif'];

  @ViewChild('slides', { static: true }) slider: IonSlides;
  dataSource = new MatTableDataSource<IGrouilleur>(ELEMENT_DATA) ;  
  // this.dataSource.paginator = this.paginator;

  offre : boolean = true;
  detailO : boolean = false;
  detailG : boolean = false;
  pipe = "";
  id: number;
  grouilleur: IGrouilleur;

  @Input() idGrouilleur: number = null;
  state: boolean = false;
  constructor(private alertController : AlertController,
              public jeveBaraService: JeveBaraService,
              public loginService: LoginService
              ) {
                console.log("id du grouilleur : "+ this.jeveBaraService.grouilleurId);
                this.addGrouilleur();
                if(this.jeveBaraService.grouilleurId){
                  this.getDetailGrouilleur(this.jeveBaraService.grouilleurId);
                }
                this.jeveBaraService.grouilleurId = null;
              }
  

  deleteUser(id : any){
    this.goBack(+id);
  }
  BloquerUser(){
    this.jeveBaraService.id = this.grouilleur.id;
    this.subshowBloquerUser = this.loginService.showBloquerUser(this.grouilleur.idUser+"" ,"Voulez-vous vraiment supprimer "+ this.grouilleur.nom+" ?").afterClosed()
    .subscribe( (res) => {
    if(res){      
      this.jeveBaraService.updataBage.emit();
      }else{
      }
    });
  }

  exporter(){
    this.jeveBaraService.exporterGrouilleur();
    //   this.jeveBaraService.exporterGrouilleur().subscribe((res) =>  {
    //   console.log('exporterGrouilleur');
    //   console.log(res);
    // });
  }

  send_message(id : number){
    this.loginService.showSendMessageToUser(id,"grouilleur");
  }

  async filtre(){
    this.pipe = "true";
    let g : IGrouilleur[] = [];
    
    const loading = this.loginService.loading();
    (await loading).present();
    this.subgetDesactiveGrouilleur = this.jeveBaraService.getDesactiveGrouilleur({page : '0' , size : '100'})
    .subscribe( async (res) => {
      (await loading).dismiss();                 
      res = res.data;  
      console.log('tableau de grouileur desactivé '+ JSON.stringify(res)); 
      this.dataSource = new MatTableDataSource<IGrouilleur>(res) ;
      this.dataSource.paginator = this.paginator;
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }

  async addGrouilleur(){
    let res : IGrouilleur[] = [];      
    const loading = this.loginService.loading();
      this.subgetGroulleurs = this.jeveBaraService.getGroulleurs({page : '0' , size : '100'}).subscribe( async (res) => {
          (await loading).dismiss();                 
          res.data.forEach(element => {
            this.subgetGroulleurs = this.jeveBaraService.getGrouilleurPositionnement(element.id, { page: "0", size: "1" }).subscribe((res) => {
              element.positionnement = res.nombreTotalElements;
            });
          });
          res = res.data;
          console.log('tableau de grouileur add'+ JSON.stringify(res));      
          this.dataSource = new MatTableDataSource<IGrouilleur>(res) ;
          this.dataSource.paginator = this.paginator;
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        });
  }

        private path = "";
  getImagePath(path : string){
    this.path = path;
  }

        

      async ActiverUser(){ 

            // timer(0, 10000) call the function immediately and every 10 seconds 
          //   this.timerSubscription = timer(0, 3000).pipe( 
          //   map(() => {       
          //         this.jeveBaraService.getSanctionBydUser(this.grouilleur.idUser).subscribe((sanction) => {
          //           this.jeveBaraService.activerDesactiverSanction(sanction[sanction.length - 1].id).subscribe((reactivation) => {
          //             this.loginService.showAlert('Réactivation du Grouilleur', 'Grouilleur réactivé avec success !');
          //             return 0;
          //           });
          //         });
          //   }) 
          // ).subscribe(); 
          
          const loading = this.loginService.loading();
          (await loading).present();

          // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur.id).subscribe(async (res) => {
          if(!this.grouilleur.actif)
          this.jeveBaraService.activeteaGrouilleur(this.grouilleur.id).subscribe(async (res) => {
              (await loading).dismiss();                 
              this.jeveBaraService.actif.emit();
              this.jeveBaraService.updataBage.emit();
              this.loginService.showAlert('Activation du Grouilleur', 'Grouilleur activé avec success !');
            },async e => {
              (await loading).dismiss();
              if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
              }else{
                if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                  this.loginService.showAlert("Activation","Compte déjà actif !"); 
                }
              }              
            });

            if(this.grouilleur.actif)
            this.jeveBaraService.desactivateGrouilleur(this.grouilleur.id).subscribe(async (res) => {
            (await loading).dismiss();                 
            this.jeveBaraService.actif.emit();
            this.jeveBaraService.updataBage.emit();
            this.loginService.showAlert('Desactivation du Grouilleur', 'Grouilleur desactivé avec success !');
          },async e => {
            (await loading).dismiss();
            if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
              console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
              this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
            }else{
              if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                this.loginService.showAlert("Activation","Compte déjà actif !"); 
              }
            }
          });
      }
      
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async valider(id){    
     const loading = this.loginService.loading();
    (await loading).present();
    this.subactiveteOrDesactivateUser = this.jeveBaraService.activeteOrDesactivateUser(+id).subscribe( async (res) => {
      (await loading).dismiss();                 
      this.loginService.showAlert("Activation de "+ this.grouilleur.nom,"Activation effectué avec success !");
      this.goBack(id);
    },async e => {
      (await loading).dismiss();
      if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
        console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
        this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
      }else{
        if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
          this.loginService.showAlert("Activation","Compte déjà actif !"); 
        }else{
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        }
      }});
  }

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Login',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  ngOnInit() {
    
    this.jeveBaraService.userEmitter.subscribe((res) => {
      this.getDetailGrouilleur(this.grouilleur.id);
    });
    
    this.subgetGroulleurs = this.jeveBaraService.imageProfil.subscribe( (res) => {
      this.path = res;
    });
    this.subgetGroulleurs = this.jeveBaraService.userEmitter.subscribe( () => {
      this.addGrouilleur();
      // console.log('mon Emitter');
    });
    this.dataSource.paginator = this.paginator;
    this.addGrouilleur();
  }
  ///////////////////////
  ///////////////////////
  ///////////////////////

getState(state : boolean){
  this.state = state;
}
//  private
getDetailOffre(id: number){
  this.jeveBaraService.id = id;
  this.id = id;
  this.offre = false;
  this.detailG = false;
  this.detailO = true;
}

//  private
goBack2(id: number){
  this.jeveBaraService.id = id;
  this.offre = false;
  this.detailG = false;
  this.detailO = true;
  this.id = id;
}

goBack(id: number){
  this.jeveBaraService.id = id;
  this.id = id;
  this.offre = true;
  this.detailO = false;
  this.detailG = false;
}

  async getDetailGrouilleur(id: number){
    const loading = this.loginService.loading();
    (await loading).present();
    this.subgetGrouilleurDetails = this.jeveBaraService.getGrouilleurDetails(id).subscribe( async (res) => {
    (await loading).dismiss();                 
      console.log("Grouilleur de l'id "+this.jeveBaraService.id+" : :-) " + JSON.stringify(res));
      this.grouilleur = res;    
        this.jeveBaraService.id = id;
        this.id = id;
        this.offre = false;
        this.detailO = false;
        this.detailG = true;
        this.idGrouilleur = null;
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
  }


  async modifier(id: number){      
    this.goBack2(id);
    const loading = this.loginService.loading();
    (await loading).present();
    this.subgetGrouilleurDetails = this.jeveBaraService.getGrouilleurDetails(id).subscribe( async (res) => {
      (await loading).dismiss();                 
      console.log("Grouilleur de l'id "+this.jeveBaraService.id+" : :-) " + JSON.stringify(res));
      this.grouilleur = res;  
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }

  getDetailOffres(id: number){
    this.jeveBaraService.id = id;
    this.id = id;
    this.offre = false;
    this.detailG = false;
    this.detailO = true;
  }

  con(id: number){
    this.loginService.showOffreDetail("", id);
  }
        
  @ViewChild(MatPaginator) paginator : MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}

