import { LoginService } from 'src/app/services/login.service';
import { IGrouilleur } from './../../models/entities/igrouilleur';
import { JeveBaraService } from './../../services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
// import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-choose-cible-message',
  templateUrl: './choose-cible-message.component.html',
  styleUrls: ['./choose-cible-message.component.scss'],
})
export class ChooseCibleMessageComponent implements OnInit {
  id : string = "";

  title = 'autocomplete';
  options = [""];
  filteredOptions;
  formGroup : any = "";
  fb: any = false;

  initForm(response){
    if(response.length == 0){
      this.fb = false;
    }else{
      this.fb = true;
      this.formGroup = response;
    }
      console.log(response);
      this.filterData(response);
  }

  filterData(enteredData){
    //     if(enteredData!=""){git 
    if(this.type=='GROUILLEUR'){ 
          if(enteredData!=""){
            this.jeveBaraService.rechercheGrouilleur({page : "0",size : "20",query : enteredData}).subscribe((res) => {
                    
              console.log("recherche grouilleurs : "+JSON.stringify(res));
              
              this.filteredOptions = res.data;
            });
        }else{
          this.jeveBaraService.getGroulleurs({page : "0",size : "20"}).subscribe((res) => {
                
          this.filteredOptions = res.data;
          }); 
        }
    }
    if(this.type=='BOSS'){ 
      this.filteredOptions = this.options.filter(item => {
        return item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1
      });
        if(enteredData!=""){
          this.jeveBaraService.rechercheBoss({page : "0",size : "20",query : enteredData}).subscribe((res) => {
                  
              console.log("recherche boss : "+JSON.stringify(res));
            
            this.filteredOptions = res.data;
          });
      }else{
        this.jeveBaraService.getGroulleurs({page : "0",size : "20"}).subscribe((res) => {
              
        this.filteredOptions = res.data;
        });  
      }
  }
  }
  async sendMessage(data){
      // this.loginService.showAlert(JSON.stringify(data.idUser)+" id : "+JSON.stringify(this.id),""+this.id); return;
        if(this.type=='BOSS'){     
          this.closeDialog(false);     
          // this.loginService.showAlert(" id : "+JSON.stringify(data?.idUser),"type "+this.type); return; data?.idUser
          this.loginService.showSendMessageToUser(+data.id, this.type).afterClosed().subscribe(async (res) => {
        }); 
     }

     if(this.type=='GROUILLEUR'){
      this.closeDialog(false);     
      // this.loginService.showAlert(" id : "+JSON.stringify(data?.idUser),"type "+this.type); return;
      this.loginService.showSendMessageToUser(+data.id, this.type).afterClosed().subscribe(async (res) => {
    }); 
     }
  }
  periodess  = [
    {'nom': 'UN BOSS', 'id': 'BOSS'},
    {'nom': 'UN GROUILLEUR', 'id': 'GROUILLEUR'}
  ];
  user  = [];
  type: string = "";
  range: string = "";
  showUser: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<ChooseCibleMessageComponent>, 
              public jeveBaraService : JeveBaraService, 
              private loginService : LoginService ) {
                this.initForm("");
  }

   onChange(data){
    this.affecter();
   }

   async affecter(data ? : string){
    let pass = 0;
     if(this.id=='ALL'){
      pass = 1;
      this.loginService.showSendMessageToUser(1,"All").afterClosed().subscribe(); 
      this.closeDialog(false);
      return;
     }
     if(this.id=='ALLGROUILLEUR'){
      pass = 1;
      this.loginService.showSendMessageToUser(1,"ALLGROUILLEUR").afterClosed().subscribe(); 
      this.closeDialog(false);
      return;
     }
     if(this.id=='ALLBOSS'){
      pass = 1;
      this.loginService.showSendMessageToUser(1,"ALLBOSS").afterClosed().subscribe(); 
      this.closeDialog(false);
      return;
     }
     if(this.id=='BOSS'){
      this.showUser = true;
      pass = 1;
      this.type = this.id;
      this.options = []
     }
     if(this.id=='GROUILLEUR'){
      this.showUser = true;
      pass = 1;
      this.type = this.id;
      this.options = []
     }

     if(pass==0){
       this.closeDialog(false);
      this.loginService.showSendMessageToUser(+this.id, this.type);
     }
    }

ngOnInit() {}

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}