import { IBoss } from './../../models/entities/iboss';
import { IPositionnement } from 'src/app/models/entities/ipositionnement';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IOffre } from 'src/app/models/entities/ioffre';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-look-message',
  templateUrl: './look-message.component.html',
  styleUrls: ['./look-message.component.scss'],
})
export class LookMessageComponent implements OnInit {

  @Input() headerTitle : String = "";
  recherche : string = "";
  title : boolean = false;
  user : any = {} ;
  messages : any[] = [] ;
  offreBoolean : Boolean = true;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  path: string;
  textMessage: any;

  constructor(private loginService: LoginService,
              public jeveBaraService : JeveBaraService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<LookMessageComponent> ) {

                let dataToSend = {"page":"0", "size":"100", "idPositionnement":data.id}
                this.jeveBaraService.getDiscutionByIdPositionnement(dataToSend).subscribe((res) => {
                  this.messages = res.data;
                    console.log("messages d'un positionnement : "+ JSON.stringify(res));
                  //   this.messages = [{
                  //   "idMessage":4635, 
                  //   "dateEnvoi":"13/09/2022 17:39",
                  //   "contenu":"ça fait 4 fois que je réparé ce tuyau je veux un pro cette fois ci svp?",
                  //   "nomGrouilleur":"Kohou","prenomGrouilleur":"Deborah","nomBoss":"Aubin","prenomBoss":"Kouassi",
                  //   "nomDestinataire":"Kohou","prenomDestinataire":"Deborah","nomExpediteur":"Aubin","prenomExpediteur":"Kouassi"},

                  //   {"idMessage":4637 ,
                  //   "dateEnvoi":"13/09/2022 17:40",
                  //   "contenu":"vous me rassurez ou pas?","nomGrouilleur":"Kohou",
                  //   "prenomGrouilleur":"Deborah","nomBoss":"Aubin","prenomBoss":"Kouassi","nomDestinataire":"Kohou","prenomDestinataire":"Deborah",
                  //   "nomExpediteur":"Aubin","prenomExpediteur":"Kouassi"},

                  //   {"idMessage":4639 ,
                  //   "dateEnvoi":"13/09/2022 17:41",
                  //   "contenu":"Deborah?","nomGrouilleur":"Kohou","prenomGrouilleur":"Deborah",
                  //   "nomBoss":"Aubin","prenomBoss":"Kouassi","nomDestinataire":"Kohou",
                  //   "prenomDestinataire":"Deborah","nomExpediteur":"Aubin","prenomExpediteur":"Kouassi"},

                  //   {"idMessage":4641 ,
                  //   "dateEnvoi":"13/09/2022 17:42",
                  //   "contenu":"Deborah ? OK,  je pense que vous ne pouvez pas faire ce travail puis que vous me rassurer pas.. quittez l'application si vous êtes venu pour dormi. je vous désélectionne",
                  //   "nomGrouilleur":"Kohou","prenomGrouilleur":"Deborah","nomBoss":"Aubin","prenomBoss":"Kouassi","nomDestinataire":"Kohou",
                  //   "prenomDestinataire":"Deborah","nomExpediteur":"Aubin","prenomExpediteur":"Kouassi"},

                  //   {"idMessage":4642 ,
                  //   "dateEnvoi":"13/09/2022 17:48",
                  //   "contenu":"non monsieur je suis qualifié et j'ai 5d'expérience donc je peux le faire","nomGrouilleur":"Kohou",
                  //   "prenomGrouilleur":"Deborah","nomBoss":"Aubin","prenomBoss":"Kouassi","nomDestinataire":"Aubin",
                  //   "prenomDestinataire":"Kouassi","nomExpediteur":"Kohou","prenomExpediteur":"Deborah"},

                  //   {"idMessage":4640 ,
                  //   "dateEnvoi":"13/09/2022 17:41",
                  //   "contenu":"Oui-Oui c'est ça","nomGrouilleur":"Kohou","prenomGrouilleur":"Deborah","nomBoss":"Aubin",
                  //   "prenomBoss":"Kouassi","nomDestinataire":"Aubin","prenomDestinataire":"Kouassi","nomExpediteur":"Kohou",
                  //   "prenomExpediteur":"Deborah"},

                  //   {"idMessage":4638 ,
                  //   "dateEnvoi":"13/09/2022 17:40",
                  //   "contenu":"ok moi je suis qualifié chez Jevebara donc vous avez frappé à la bonne porte","nomGrouilleur":"Kohou",
                  //   "prenomGrouilleur":"Deborah","nomBoss":"Aubin","prenomBoss":"Kouassi","nomDestinataire":"Aubin","prenomDestinataire":"Kouassi",
                  //   "nomExpediteur":"Kohou","prenomExpediteur":"Deborah"},

                  //   {"idMessage":4636 ,
                  //   "dateEnvoi":"13/09/2022 17:40",
                  //   "contenu":"bonjour monsieur vous allez bien","nomGrouilleur":"Kohou","prenomGrouilleur":"Deborah","nomBoss":"Aubin",
                  //   "prenomBoss":"Kouassi","nomDestinataire":"Aubin","prenomDestinataire":"Kouassi","nomExpediteur":"Kohou","prenomExpediteur":"Deborah"
                  // }];
                  
                });
  }

  
  private apiUrl = environment.apiUrl;
  
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

  async sendMessage(){                   
    this.closeDialog(true);
     const loading = this.loginService.loading();
    (await loading).present();
    (await this.jeveBaraService.sendMessageToUser({"contenu": this.textMessage, 
                                              "idUsers": [this.user.idUser], 
                                              "typeProfil": this.data.type})).
    subscribe(async (res) => {
      (await loading).dismiss();  
      this.loginService.showAlert("Envoi de message", "Message envoyé avec success !");
      this.jeveBaraService.messageEmit.emit();    
      this.jeveBaraService.notif.emit();

    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }

  // changeOffreBox(idOffre : number){
  //   this.jeveBaraService.getOffreById(idOffre+"").subscribe( (res) => {
  //     this.offre = res ;
  //   });
  // }

  ngOnInit() {}

}
