import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
// import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-crediter-wallet',
  templateUrl: './crediter-wallet.component.html',
  styleUrls: ['./crediter-wallet.component.scss'],
})
export class CrediterWalletComponent implements OnInit {
  datePikerFin = "";
  datePikerDebut = "";
  montant: number = 0;
  motif: string = "";
  moyenOperation: string = "";
  numeroTransaction: string = "";
  typeOperation: string = "";

  periodess  = [
    {value: 'ORANGE_MONEY', viewValue: 'Orange Money'},
    {value: 'MTN_MONEY', viewValue: 'Mtn Money'},
    {value: 'MOOV_MONEY', viewValue: 'Moov Money'},
    {value: 'WAVE', viewValue: 'Wave'},
    {value: 'MOOV_MONEY', viewValue: 'Moov Money'},
    {value: 'INTERNE', viewValue: 'INTERNE'},
    {value: 'CASH', viewValue: 'CASH'},
  ];
  show: boolean;
  // CASH, INTERNE, MASTERCARD, MTN_MONEY, ORANGE_MONEY, PAYPAL, VISA
  onChange(){
    if(this.moyenOperation=="CASH"){
      this.show = false;
    }else{
      this.show = true;
    }
   }

  constructor(private loginService: LoginService,
    public jeveBaraService: JeveBaraService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CrediterWalletComponent> 
    ) {    
    this.typeOperation = "CREDIT";
    this.show = true;
  }
  
  async crediter(){
    this.closeDialog(false);    
    if(this.moyenOperation=="CASH"){
      this.numeroTransaction = null;
    }
    if(this.data.message == "cagnote"){      
            const loading = this.loginService.loading();
            (await loading).present();
            this.jeveBaraService.creditOrDebitCagnote(+this.data.title, { montant: this.montant,motif: this.motif,
                                                                          moyenOperation: this.moyenOperation,numeroTransaction: this.numeroTransaction,
                                                                          typeOperation: this.typeOperation
                                                                        }).subscribe(
        async (res) => {
          (await loading).dismiss();                 
          this.loginService.showAlert("Crediter "+this.data.message,"cette "+this.data.message+" à été Crediter avec success !");
          this.jeveBaraService.userEmitter.emit(this.data);
        },async e => {
          (await loading).dismiss();
            if(e.error.message=="CAGNOTE_INSUFFISANTE"){
              this.loginService.showAlert("Désolé","Désolé cagnote insuffisante !");
            }else{
              if(e.error.message=="GROUILLEUR_NON_EXISTANT"){
                this.jeveBaraService.creditOrDebitCagnoteBoss(+this.data.title, {   montant: this.montant,    
                  motif: this.motif,
                  moyenOperation: this.moyenOperation,
                  numeroTransaction: this.numeroTransaction,
                  typeOperation: this.typeOperation}).
              subscribe(
                async (res) => {
                (await loading).dismiss();                 
                  this.loginService.showAlert("Debiter "+this.data.message,"cette "+this.data.message+" à été Debiter avec success !");
                  this.jeveBaraService.userEmitter.emit(this.data);
                });
              }else{
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              }
            }
          console.log("error : "+JSON.stringify(e));
        });
    }else{      
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.creditOrDebitCaution(+this.data.title, { montant: this.montant,motif: this.motif,
                                                                    moyenOperation: this.moyenOperation,
                                                                    numeroTransaction: this.numeroTransaction,
                                                                    typeOperation: this.typeOperation
                                                                  }).subscribe(
            async (res) => {
              (await loading).dismiss();                 
              this.loginService.showAlert("Crediter "+this.data.message,"cette "+this.data.message+" à été Crediter avec success !");
              this.jeveBaraService.userEmitter.emit(this.data);
            },async e => {
              (await loading).dismiss();
                if(e.error.message=="CAGNOTE_INSUFFISANTE"){
                  this.loginService.showAlert("Désolé","Désolé cagnote insuffisante !");
                }else{
                  if(e.error.message=="GROUILLEUR_NON_EXISTANT"){
                    this.jeveBaraService.creditOrDebitCagnoteBoss(+this.data.title, {   montant: this.montant,    
                      motif: this.motif,
                      moyenOperation: this.moyenOperation,
                      numeroTransaction: this.numeroTransaction,
                      typeOperation: this.typeOperation}).
                  subscribe(
                    async (res) => {
                    (await loading).dismiss();                 
                      this.loginService.showAlert("Debiter "+this.data.message,"cette "+this.data.message+" à été Debiter avec success !");
                      this.jeveBaraService.userEmitter.emit(this.data);
                    });
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
                }
                console.log("error : "+JSON.stringify(e));
            });      
    }
  }
ngOnInit() {}

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}