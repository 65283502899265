import { JeveBaraService } from './../../services/jeve-bara.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-entretien-alert',
  templateUrl: './show-entretien-alert.component.html',
  styleUrls: ['./show-entretien-alert.component.scss'],
})
export class ShowEntretienAlertComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public jeveBaraService : JeveBaraService,
  public dialogRef: MatDialogRef<ShowEntretienAlertComponent> ) { }

  ngOnInit() {}

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}
