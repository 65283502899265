import { log } from 'src/app/core/core';
import { IUser } from './../../models/entities/iuser';
import { IPositionnement } from 'src/app/models/entities/ipositionnement';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IOffre } from 'src/app/models/entities/ioffre';
import { IonInfiniteScroll } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit , OnDestroy{
  subgetOffreById: any;
  subgetOffrePositionnement: any;
  picture: string;
  userToSendName: string;
  user: any;
  textMessage: any;
  curentuser: any;
  id: any;
  ELEMENT_DATA: any[];

  private apiUrl = environment.apiUrl;

  @Output() notifOut: any = new EventEmitter();

  @Input() notif : number;

  @Input() headerTitle : String = "";
  recherche : string = "";
  title : boolean = false;
  userToSend : number = 0;
  option : boolean = false;
  users : IUser[] = [] ;
  offre : any[] = [] ;
  length :number;
  discutionBoolean : Boolean = false;

  formGroup : any = "";
  fb: any = false;
  f: any = false;
  options = [];
  noms = [];
  filteredOptions : any[] = [];
  discution: boolean = true;
  first: boolean = true;
  datePickerSection: boolean = false;
  dateDebut : string = '';
  pageNumber = 0;
  pageSize : string = '10';
  totalPages: any;
  ngOnDestroy(): void {
    // this.subgetOffreById.unsubscribe();
    // this.subgetOffrePositionnement.unsubscribe();
  }


  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(  //public datePipe: DatePipe,
                private loginService: LoginService,
                public jeveBaraService : JeveBaraService) {
                console.log("discution : "+ jeveBaraService.mobile)
                this.getdata();
                this.getDiscutionNonLu();
              }

  ouvrirDuiscution(){
    this.discution = true;
  }

  fermerDuiscution(){
    this.discution = false;
  }
   newMessage(){
    this.option=!this.option;
   }

   async getPaginateNextdata() {
    if(this.pageNumber==(this.totalPages)){
      this.loginService.showAlert("Désolé","Pas d'éléments suivant !");
        return;
      }
      this.first = true;
      this.pageNumber = this.pageNumber + 1;
      this.getdata()
  }

  async getPaginatePrevdata() {
    if(this.pageNumber==1) {
      this.loginService.showAlert("Désolé","Pas d'éléments précédant !");
          return;
    }
    this.first = true;
    this.pageNumber = this.pageNumber - 1;
    this.getdata()
 }

  async getdata() {
    const loading = this.loginService.loading();
    (await loading).present();
    console.log("start request : "+this.pageNumber);
    this.jeveBaraService.getPaginateDiscution({ 'pageNumber': this.pageNumber+'', 'pageSize': this.pageSize }).subscribe( async (res) => {

      console.log("end of request : "+JSON.stringify(res));

// {"content":[{"userDiscussion":{"id":6918,"idGrouilleur":6919,"idBoss":null,
  // "nom":"Omgba","prenom":"Louis","ppGrouilleur":"6918/166965805291920221105_164606.jpg",
  // "ppBoss":"6918/166966095344420221116_113356.jpg"},"messageDiscussions":[],"nombreMessagesNonLu":0},
  // {"userDiscussion":{"id":6934,"idGrouilleur":6935,"idBoss":null,"nom":"Edmond","prenom":"DRAMA",
  // "ppGrouilleur":"6934/1669904476417IMG_20221111_152159_442.jpg","ppBoss":null},"messageDiscussions":[],
  // "nombreMessagesNonLu":0},{"userDiscussion":{"id":6949,"idGrouilleur":6950,"idBoss":null,"nom":"Aubin",
  // "prenom":"Boon","ppGrouilleur":"6949/1669659211630IMG-20221128-WA0002.jpg","ppBoss":null},
  // "messageDiscussions":[],"nombreMessagesNonLu":0},{"userDiscussion":{"id":7024,"idGrouilleur":7025,
  // "idBoss":null,"nom":"Kohou","prenom":"Prisca Déborah","ppGrouilleur":"7024/1669828674543crainte boss 001.jpg",
  // "ppBoss":null},"messageDiscussions":[],"nombreMessagesNonLu":0},{"userDiscussion":{"id":7033,"idGrouilleur":7034,
  // "idBoss":null,"nom":"Kouotou","prenom":"Zounedou","ppGrouilleur":"7033/16696617509652Q==.jpg","ppBoss":null},
  // "messageDiscussions":[],"nombreMessagesNonLu":0},{"userDiscussion":{"id":7220,"idGrouilleur":7221,"idBoss":null,
  // "nom":"Mbouende","prenom":"Norman","ppGrouilleur":"7220/166966832026920220824_162114.jpg","ppBoss":null},
  // "messageDiscussions":[],"nombreMessagesNonLu":0},{"userDiscussion":{"id":7235,"idGrouilleur":7236,"idBoss":null,
  // "nom":"AKAWA","prenom":"Richie","ppGrouilleur":"7235/1669880951155-d2ro6g.jpg","ppBoss":null},
  // "messageDiscussions":[],"nombreMessagesNonLu":1},{"userDiscussion":{"id":7392,"idGrouilleur":7393,"idBoss":null,
  // "nom":"Ndemaze","prenom":"Etienne","ppGrouilleur":"7392/1669890681118IMG-20221122-WA0004.jpg","ppBoss":null},
  // "messageDiscussions":[],"nombreMessagesNonLu":0},{"userDiscussion":{"id":7760,"idGrouilleur":7761,"idBoss":null,
  // "nom":"Jevebara","prenom":"Moov","ppGrouilleur":"7760/166999844001420221125_121136.jpg","ppBoss":null},
  // "messageDiscussions":[],"nombreMessagesNonLu":0}],"pageable":{"sort":{"empty":true,"sorted":false,"unsorted":true},
  // "offset":0,"pageSize":10,"pageNumber":0,"paged":true,"unpaged":false},"totalElements":9,"totalPages":1,"last":true,
  // messages.component.ts:117 res.data : 0
  // messages.component.ts:118 res.data : {"content":[{"userDiscussion":{"id":6918,"idGrouilleur":6919,"idBoss":null,"nom":"Omgba","prenom":"Louis","ppGrouilleur":"6918/166965805291920221105_164606.jpg","ppBoss":"6918/166966095344420221116_113356.jpg"},"messageDiscussions":[],"nombreMessagesNonLu":0},{"userDiscussion":{"id":6934,"idGrouilleur":6935,"idBoss":null,"nom":"Edmond","prenom":"DRAMA","ppGrouilleur":"6934/1669904476417IMG_20221111_152159_442.jpg","ppBoss":null},"messageDiscussions":
      (await loading).dismiss();
      this.length = res.totalElements;
      this.totalPages = res.totalPages;
      this.ELEMENT_DATA = [];
      this.ELEMENT_DATA = res.content;
      console.log("res.data : "+this.pageNumber);
      console.log("res.data : "+JSON.stringify(res));
      console.log(res.content);
      if(this.length<1){
        this.loginService.showAlert("Désolé","Nous n'avons aucune discution !");
      }
      console.log("::::::::::::::::::::::::::::: "+JSON.stringify(res)+" :::::::::::::::::::::::::::::");
      this.filteredOptions = [];
      let i = 0;
      res.content.forEach(element => {
        this.noms = this.noms.concat(element.userDiscussion.nom+" "+element.userDiscussion.prenom);
        element.userDiscussion.ppGrouilleur = null;
        element.userDiscussion.ppBoss = null;
          element.userDiscussion.ppGrouilleur = this.getProfilPicture(element.userDiscussion);
          if(i<1){
            this.offreListiner(element);
          }
          i++;
          this.filteredOptions = this.filteredOptions.concat([element]);
          console.log("User : "+JSON.stringify(element));
        });
        console.log("User : "+JSON.stringify(this.filteredOptions));
        this.options = this.filteredOptions;
    },async e => {
      (await loading).dismiss();
      console.log("error : "+JSON.stringify(e));
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }

  initForm(respons){
    this.f = true;
    let response = this.recherche;
    if(response.length == 0){
      this.fb = false;
      this.filteredOptions = this.options;
    }else{
      this.fb = true;
      this.formGroup = response;
    }
      console.log(response);
      this.filterData(response);
  }

  filterData(enteredData){
    this.jeveBaraService.rechercheDiscutionByNomPrenom(enteredData).subscribe((res) => {
      this.filteredOptions = res;
    });
  }

  getProfilPicture(res : any){
    if(res.referenceFichier != null){
      return this.picture = this.apiUrl +"api/file?fileReference="+ res.referenceFichier ;
      // return this.picture = this.apiUrl +"api/file?fileReference="+ res.ppGrouilleur ;
    }else{
      if(res.referenceFichier != null){
        return this.picture = this.apiUrl +"api/file?fileReference="+ res.referenceFichier ;
        // return this.picture = this.apiUrl +"api/file?fileReference="+ res.ppBoss ;
      }else{
        return this.picture = "../../../assets/icon/profil.svg";
      }
    }
  }

  sendMessage(){
    let typeProfil = "";
    if(this.datePickerSection){
      // this.sendSmsToBoss(userType : "boss",data : {date : this.dateDebut, message : string});
      const datePipe = new DatePipe('en-US')
      this.dateDebut = datePipe.transform(this.dateDebut, 'yyyy/MM/dd HH:mm:ss');

      this.jeveBaraService.sendSmsToBoss("boss",{'date': this.dateDebut, 'message': this.textMessage}).subscribe((res) => {
        this.loginService.showAlert("Envoi des SMS", "SMS envoyé avec success !");
      });
      console.log("date d'inscription est : "+this.dateDebut+ " - message : "+this.textMessage);
      this.textMessage = "";
      return;
    }
    if(this.userToSend == 1){
      typeProfil = "GROUILLEUR";
    }
    if(this.userToSend == 2){
      typeProfil = "BOSS";
    }
    if(this.userToSend == 3){
      typeProfil = "TOUS";
    }
    if(this.userToSend == 0){
      this.jeveBaraService.sendMessageToUser({"contenu": this.textMessage, "idUsers": [this.user.id], "typeProfil": "GROUILLEUR"}).
      subscribe((res) => {
        this.textMessage = "";
        this.loginService.showAlert("Envoi de message", "Message envoyé avec success !");
        this.jeveBaraService.messageEmit.emit()
      });
      return;
    }

    this.jeveBaraService.sendDiffusionMessage({"contenu": this.textMessage ,
                                               "typeProfil": typeProfil}).subscribe((res) => {
                                                this.textMessage = "";
                                                this.loginService.showAlert("Envoi de message", "Message envoyé avec success !");
                                                this.jeveBaraService.messageEmit.emit()
                                              });

  }

  @ViewChild("scroll") scroll: ElementRef;

  scrollTop(){
  }

  ngAfterViewChecked(){
    this.scrollBottom();
  }

  scrollBottom(){
    this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
  }


  getDiscutionNonLu(){

    let tab = this.filteredOptions;
    this.filteredOptions = [];
    this.jeveBaraService.getDiscutionNonLuBoss({ page: "0", size: "30" }).subscribe((res) => {
      this.filteredOptions = res.data;
    });
    this.jeveBaraService.getDiscutionNonLuGrouilleur({ page: "0", size: "30" }).subscribe((res) => {
      this.filteredOptions = this.filteredOptions.concat(res.data);
    });
    this.filteredOptions = this.filteredOptions.concat(tab);
  }

  sendSmsToBoss(userType : string,data : {date : string, message : string}){
    // yyyy/MM/dd HH:mm:ss
    this.jeveBaraService.sendSmsToBoss(userType ,{date : data.date , message : data.message }).subscribe((res) => {
      this.loginService.showAlert("Envoi de sms", "sms envoyé avec success !");
    },e =>{
      this.loginService.showAlert("Envoi de sms", "désolé une erreur a survenue lors de l'envoi du SMS !");
    });
  }
  diffusionOption(num : number){
    if(num == 1){
      this.datePickerSection = false;
      this.discutionBoolean = false;
      this.userToSend = num;
      this.curentuser = "GROUILLEUR";
      this.picture = "../../../assets/icon/user.svg";
      this.userToSendName = "Tous les Grouilleurs ";
    }
    if(num == 2){
      this.datePickerSection = false;
      this.discutionBoolean = false;
      this.userToSend = num;
      this.curentuser = "BOSS";
      this.picture = "../../../assets/icon/user.svg";
      this.userToSendName = "Tous les Boss ";
    }
    if(num == 3){
      this.datePickerSection = false;
      this.discutionBoolean = false;
      this.userToSend = num;
      this.curentuser = "TOUS";
      this.picture = "../../../assets/icon/user.svg";
      this.userToSendName = "Tous les Utilisateurs ";
    }
    if(num == 4){
      this.datePickerSection = false;
      this.loginService.ChooseCibleMessageComponent(1,"BOSS").afterClosed().subscribe();
    }
    if(num == 5){
      this.discutionBoolean = false;
      // this.userToSend = num;
      // this.   = "TOUS";
      this.picture = "../../../assets/icon/user.svg";
      this.userToSendName = "SMS pour les boss";

      this.datePickerSection = true;
    }
  }


  getDataUser(user : any) {
    this.jeveBaraService.image = user.userDiscussion.ppGrouilleur;
    //  = this.getProfilPicture(user.userDiscussion);
    console.log("User class : "+JSON.stringify(user));
    if(this.user.id == user.userDiscussion.id){
      this.scrollBottom();
      return;
    }
    this.offreListiner(user);
  }

  async offreListiner(user : any){
    this.datePickerSection = false;
    this.id = user.userDiscussion.id;
    this.user = user.userDiscussion;
    this.curentuser = ""+this.user.idUser;
    console.log("user22 : ");
    console.log(JSON.stringify(this.user));

    this.userToSend = 0;
    this.option = false;
    this.userToSendName = this.user.nom+" "+this.user.prenom;
    this.picture = this.user.ppGrouilleur;
    if(user.messageDiscussions.length != 0){
      this.discutionBoolean = true;
      this.offre = user.messageDiscussions;
      console.log("Discution : "+JSON.stringify(user.messageDiscussions));
    }else{
      this.discutionBoolean = false;
    }
    const loading = this.loginService.loading();
    (await loading).present();
    // getPaginateMessageFormUser
    // this.jeveBaraService.getListMessageFromUser(this.user.id).subscribe(async (res) => {
      this.jeveBaraService.getPaginateMessageFormUser(this.user.id, {pageNumber: "0", pageSise : "100"}).subscribe(async (res) => {
      console.log("adminDiscussion : "+JSON.stringify(res));
      (await loading).dismiss();
      this.length = res.nombreTotalElements;
      this.ELEMENT_DATA = [];
      this.ELEMENT_DATA = res.data;
      if(this.length<1){
        this.loginService.showAlert("Désolé","Nous n'avons aucun message !");
      }
      if(res.length != 0){
        this.discutionBoolean = true;
        this.offre = res.data;
        console.log("adminDiscussion : "+JSON.stringify(res));
      }else{
        this.discutionBoolean = false;
      }
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
    if(user.nombreMessagesNonLu > 0 && !this.first){
        this.jeveBaraService.lireNouveauMessage(this.user.id).subscribe((res) => {
          this.jeveBaraService.messageEmit.emit();
          this.jeveBaraService.discutionUpdate.emit({"userName" : this.user.nom+" "+this.user.prenom});
          this.jeveBaraService.notif.emit();
      });
    }
    if(this.first)
      this.first = !this.first;

    this.ngAfterViewChecked();
  }

  changeOffreBox(idOffre : number){
    this.subgetOffreById = this.jeveBaraService.getOffreById(idOffre+"").subscribe( (res) => {
      this.offre = res ;
    });
  }

  ngOnInit() {
    this.jeveBaraService.searchMessage.subscribe((res) => {
      this.recherche = (res.target as HTMLInputElement).value;
      this.initForm(this.recherche);
    });

    this.jeveBaraService.messageEmit.subscribe((res) => {
      this.getdata();
    });
  }

}
