// <<<<<<< HEAD
import { transition } from '@angular/animations';
import { async } from '@angular/core/testing';
import { IUser } from 'src/app/models/entities/iuser';
import { MenuController } from '@ionic/angular';
import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoginService } from 'src/app/services/login.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { AppService } from 'src/app/app.service';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  alertController: any;
  // user: IUser = {};recherche
  recherche: string = "";
  notif: number;
  headerTitle: String = "Paramètres";
  public static user =  {"prenom":"","nom":"","role":"","email":"","telephone":"","ancienPin":""};

  showFiller = false;
  timerSubscription: any;


  applyFilter(event ? : Event) {
  // //   const filterValue = (event.target as HTMLInputElement).value;
  // //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // // }
  // rechercher(event ? : any){

    this.recherche = (event.target as HTMLInputElement).value;
    if(this.menu[0].isActivate){
      // this.jeveBaraService.rechercheBoss.emit(this.recherche);

      this.menu[0].isActivate = false;
      this.menu[1].isActivate = true;
      this.jeveBaraService.searchBoss.emit(event);
    }
    if(this.menu[1].isActivate){
      this.jeveBaraService.searchBoss.emit(event);
    }
    if(this.menu[2].isActivate){
      this.jeveBaraService.searchGrouilleur.emit(event);
    }
    if(this.menu[3].isActivate){
      this.jeveBaraService.searchbara.emit(event);
    }
    if(this.menu[4].isActivate){
      this.jeveBaraService.searchSinistre.emit(event);
    }
    if(this.menu[5].isActivate){
      this.jeveBaraService.searchTransaction.emit(event);
    }
    if(this.menu[6].isActivate){
      this.jeveBaraService.searchMessage.emit(event);
    }
    if(this.menu[7].isActivate){
      this.jeveBaraService.searchCorbeille.emit(event);
    }
    if(this.menu[8].isActivate){
      this.jeveBaraService.searchPromotion.emit(event);
    }
    if(this.menu[9].isActivate){
      this.jeveBaraService.searchParametre.emit(event);
    }
  }

  menu = [
    {isActivate : false,  icon:"../../../assets/icon/home", route:"menu", label:"Accueil", new: 0},
    {isActivate : false, icon:"../../../assets/icon/boss", route:"boss", label:"Boss", new: 0},
    {isActivate : false, icon:"../../../assets/icon/grouilleur", route:"grouilleur", label:"Grouilleurs", new: 0},
    {isActivate : false, icon:"../../../assets/icon/offre", route:"offre", label:"Bara", new: 0},
    {isActivate : false, icon:"../../../assets/icon/sinistre", route:"sinistre", label:"Sinistres", new: 0},
    {isActivate : false, icon:"../../../assets/icon/transaction", route:"transaction", label:"Transactions", new: 0},
    {isActivate : false, icon:"../../../assets/icon/message", route:"message", label:"Messages", new: 0},
    {isActivate : false, icon:"../../../assets/icon/corbeille", route:"corbeille", label:"Corbeille de tâches", new: 0},
    {isActivate : false, icon:"../../../assets/icon/promotion", route:"promotions", label:"Promotions", new: 0},
    {isActivate : false, icon:"../../../assets/icon/parametre", route:"parametres", label:"Paramètres", new: 0}
  ];

  public getData(){
    this.jeveBaraService.NouveauMessage().subscribe((res) => {
      this.menu[6].new = res.valeur;
    });
    // NON_TRAITER NON_TRAITER
    /*
    this.jeveBaraService.getTacheAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
    subscribe((resA) => {
      this.jeveBaraService.getTacheNonAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
      subscribe((resNa) => {
        if(this.loginService.user.role == "ADMIN" || this.loginService.user.role == "MANAGER"){
            this.menu[7].new = resA.nombreTotalElements + resNa.nombreTotalElements;
            });
        }else{
          this.menu[7].new = resNa.nombreTotalElements;
        }
      });
      */

      this.jeveBaraService.getTacheAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
      subscribe((resA) => {
        this.jeveBaraService.getTacheNonAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
        subscribe((resNa) => {
          if(this.loginService.user.role == "ADMIN" || this.loginService.user.role == "MANAGER"){
                this.menu[7].new = resA.nombreTotalElements + resNa.nombreTotalElements;
              }else{
                this.menu[7].new = resNa.nombreTotalElements;
            }
          });
        });

   let ELEMENT_DATA2 = 0;

          this.jeveBaraService.getAllTransaction({page : "0", size : "10000"}).subscribe((transaction) => {
            // transaction.data.forEach(element => {
              // if(element.statutTransaction == "EN_ATTENTE"){
                // ELEMENT_DATA2 = transaction.nombreTotalElements;
              // }
            // });
          this.menu[5].new = transaction.nombreTotalElements;
          });
          let ELEMENT_DATA3 = 0;

          this.jeveBaraService.getAllSinistre({page : "0", size : "10000"}).subscribe((sinistre) => {
            sinistre.data.forEach(element => {
              if(element.statut != "RESOLU"){
                ELEMENT_DATA3 = ELEMENT_DATA3 + 1;
              }
            });
          this.menu[4].new = ELEMENT_DATA3;
          });
          let ELEMENT_DATA4 = 0;

          this.jeveBaraService.getAllPromtion({page : "0", size : "10000"}).subscribe((promotion) => {
            promotion.data.forEach(element => {
              if(element.actif){
                ELEMENT_DATA4 = ELEMENT_DATA4 + 1;
              }
            });
            this.menu[8].new = ELEMENT_DATA4;
            this.menu[8].new = promotion.nombreTotalElements;
          });


  }
  constructor(
      private localStorageProvider : LocalStorageService,
      public loginProvider : LoginService,
      public jeveBaraService: JeveBaraService,
      private loginService : LoginService,
      private navigationService : NavigationService,
      private appService: AppService,
      private menu1: MenuController) {

        this.getData();

        this.jeveBaraService.tacheEmit.emit();

        // this.jeveBaraService.notif.emit();

                this.menu[0].isActivate = false;
                this.menu[2].isActivate = true;
                this.menu[2].isActivate = false;
                this.menu[0].isActivate = true;


    this.localStorageProvider.getItem("user").then((data:any)=>{

      console.log("dashboard user get before : "+JSON.stringify(data));

      data = JSON.parse(data);
      DashboardPage.user.ancienPin = data?.ancienPin;
      this.user.email = DashboardPage.user.email = data?.email;
      DashboardPage.user.role = data?.role;
      this.user.telephone = DashboardPage.user.telephone = data?.telephone;
      this.user.nom = DashboardPage.user.nom = data?.nom;
      this.user.prenom = DashboardPage.user.prenom = data?.prenom;

      this.loginProvider.user = data;
      console.log("dashboard user get after : "+JSON.stringify(data));


    });

                console.log("dashboard user : "+JSON.stringify(DashboardPage.user));
                console.log("dashboard user val : "+JSON.stringify(this.loginProvider.getUser()));
                // .then( (val) => {
                //   this.user = val;
                //   console.log("dashboard user : "+JSON.stringify(this.user));
                //   console.log("dashboard user val : "+JSON.stringify(val));
                // });
                this.notif = 0;

                this.menu.forEach(element => {
                  this.notif = this.notif + element.new;
                });
               }

        changeComponent(index:number){
         this.getData();
         console.log("offre index : "+index);
         if(index==3)
              this.jeveBaraService.offreId = 0;

         if(this.menu[index].isActivate == true){
           this.menu[1].isActivate = true;
           this.menu[2].isActivate = true;
           this.menu[index].isActivate = false;
            setTimeout(() => {
                // Do something before delay
                this.menu[1].isActivate = false;
                this.menu[2].isActivate = false;
                this.menu[index].isActivate = true;
            },
             100);
          return;
        }
          this.menu.forEach(element => {
            element.isActivate = false;
          });
          this.menu[index].isActivate = true;
        }

        fund(){
          this.menu.forEach(element => {
            if((element.icon != "home") && (element.isActivate))
              return false
          });
          return true;
        }

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Login',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }
  opened = true;
  vara = ">";

  logout(){
    this.loginProvider.logout();
  }

  inicioRegistro(){
    return 5  ;
  }

  id : number;
  user = {
    email	: "",
    nom	: "",
    prenom	: "",
    telephone	: ""
  };

  ngOnInit() {

    // timer(0, 10000) call the function immediately and every 10 seconds
    // this.timerSubscription = timer(0, 1000).pipe(
    // map(() => {
      if(window.innerWidth <= 800){
          this.jeveBaraService.mobile = true;
          this.loginService.mobile = this.jeveBaraService.mobile;
        }else{
          this.jeveBaraService.mobile = false;
          this.loginService.mobile = this.jeveBaraService.mobile;
        }

        // this.loginService.showConfirmAlertEntretien("ENTRETIEN","L'entretien a t-il déjà été effectué ?").afterClosed().subscribe((res) => {
        //   if(res){
        //     this.jeveBaraService.validerBages(1).subscribe((ress) => {
        //     this.jeveBaraService.updataBage.emit();
        //     });
        //   }else{
        //     this.jeveBaraService.invaliderBages(1).subscribe((ress) => {
        //     this.jeveBaraService.updataBage.emit();
        //     });
        //   }
        // });

      console.log("largeur max : "+ window.innerWidth+" window.outerWidth max : "+ window.outerWidth);
  //   })
  // ).subscribe();

  this.jeveBaraService.notif.subscribe( async (res) =>  {
    this.getData();
      // this.jeveBaraService.getFreeTache({page : "0", size : "10000"} ).subscribe((res) => {
      //   this.jeveBaraService.getAllMyTache({page : "0", size : "10000"} ).subscribe((res2) => {

      //     let ELEMENT_DATA = 0;

      //     res2.data.forEach(element => {
      //       if(element.statutTache == "NON_TRAITER"){
      //         ELEMENT_DATA = ELEMENT_DATA + 1;
      //       }
      //     });

      //     this.menu[7].new = res.nombreTotalElements + ELEMENT_DATA;
      //   });
      // });
    });

    this.jeveBaraService.bossDetailProfil.subscribe( async (res) =>  {
    this.id = res;
    this.getData();
      this.jeveBaraService.bossId = res;
        this.changeComponent(1);
    });

    this.jeveBaraService.grouillerDetailProfil.subscribe( async (res) =>  {
    this.id = res;
    this.getData();
      this.jeveBaraService.grouilleurId = res;
        this.changeComponent(2);
    });

    this.jeveBaraService.offreDetailProfil.subscribe( async (res) =>  {
    this.id = res;
    this.getData();
      this.jeveBaraService.offreId = res;
        this.changeComponent(3);
    });

    this.jeveBaraService.offreSinistre.subscribe( async (res) =>  {
    this.id = res;
    this.getData();
      this.jeveBaraService.offreId = res;
        this.changeComponent(4);
    });

    this.jeveBaraService.updateAdminEmiter.subscribe( (res) => {
    this.user = res;
    this.getData();
    });


    this.jeveBaraService.tacheNumberEmit.subscribe( (res) => {
      //this.loginService.showAlert("Désolé",res);

      this.menu[7].new = res;
      });

  }

}
// =======
// import { transition } from '@angular/animations';
// import { async } from '@angular/core/testing';
// import { IUser } from 'src/app/models/entities/iuser';
// import { MenuController } from '@ionic/angular';
// import { element } from 'protractor';
// import { Component, OnInit } from '@angular/core';
// import { LocalStorageService } from 'src/app/services/local-storage.service';
// import { LoginService } from 'src/app/services/login.service';
// import { NavigationService } from 'src/app/services/navigation.service';
// import { JeveBaraService } from 'src/app/services/jeve-bara.service';
// import { AppService } from 'src/app/app.service';

// @Component({
//   selector: 'app-dashboard',
//   templateUrl: './dashboard.page.html',
//   styleUrls: ['./dashboard.page.scss'],
// })
// export class DashboardPage implements OnInit {
//   alertController: any;
//   // user: IUser = {};
//   notif: number;
//   headerTitle: String = "Paramètres";
//   public static user =  {"prenom":"","nom":"","role":"","email":"","telephone":"","ancienPin":""};

//   showFiller = false;

//   public getData(){
//     this.jeveBaraService.NouveauMessage().subscribe((res) => {
//       this.menu[6].new = res.valeur;
//     });
//     // NON_TRAITER NON_TRAITER
//     /*
//     this.jeveBaraService.getTacheAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
//     subscribe((resA) => {
//       this.jeveBaraService.getTacheNonAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
//       subscribe((resNa) => {
//         if(this.loginService.user.role == "ADMIN" || this.loginService.user.role == "MANAGER"){
//             this.menu[7].new = resA.nombreTotalElements + resNa.nombreTotalElements;
//             });
//         }else{
//           this.menu[7].new = resNa.nombreTotalElements;
//         }
//       });
//       */

//       this.jeveBaraService.getTacheAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
//       subscribe((resA) => {
//         this.jeveBaraService.getTacheNonAssignerSelonStatut({page : "0", size : "10", statut : "NON_TRAITER"}).
//         subscribe((resNa) => {
//           if(this.loginService.user.role == "ADMIN" || this.loginService.user.role == "MANAGER"){
//                 this.menu[7].new = resA.nombreTotalElements + resNa.nombreTotalElements;
//               }else{
//                 this.menu[7].new = resNa.nombreTotalElements;
//             }
//           });
//         });

//    let ELEMENT_DATA2 = 0;

//           this.jeveBaraService.getAllTransaction({page : "0", size : "10000"}).subscribe((transaction) => {
//             // transaction.data.forEach(element => {
//               // if(element.statutTransaction == "EN_ATTENTE"){
//                 // ELEMENT_DATA2 = transaction.nombreTotalElements;
//               // }
//             // });
//           this.menu[5].new = transaction.nombreTotalElements;
//           });
//           let ELEMENT_DATA3 = 0;

//           this.jeveBaraService.getAllSinistre({page : "0", size : "10000"}).subscribe((sinistre) => {
//             sinistre.data.forEach(element => {
//               if(element.statut != "RESOLU"){
//                 ELEMENT_DATA3 = ELEMENT_DATA3 + 1;
//               }
//             });
//           this.menu[4].new = ELEMENT_DATA3;
//           });
//           let ELEMENT_DATA4 = 0;

//           this.jeveBaraService.getAllPromtion({page : "0", size : "10000"}).subscribe((promotion) => {
//             promotion.data.forEach(element => {
//               if(element.actif){
//                 ELEMENT_DATA4 = ELEMENT_DATA4 + 1;
//               }
//             });
//             this.menu[8].new = ELEMENT_DATA4;
//             this.menu[8].new = promotion.nombreTotalElements;
//           });


//   }
//   constructor(
//       private localStorageProvider : LocalStorageService,
//       public loginProvider : LoginService,
//       public jeveBaraService: JeveBaraService,
//       private loginService : LoginService,
//       private navigationService : NavigationService,
//       private appService: AppService,
//       private menu1: MenuController) {
//         this.getData();

//       this.jeveBaraService.tacheEmit.emit();
//       // this.jeveBaraService.notif.emit();
//         // this.loginProvider.showSendMessageToUser(1,"BOSS").afterClosed().subscribe((res) => {
//         //   if(res){

//         //   }else{}
//         // });
//       // this.jeveBaraService.notif.emit();

//                 this.menu[0].isActivate = false;
//                 this.menu[2].isActivate = true;
//                 this.menu[2].isActivate = false;
//                 this.menu[0].isActivate = true;


//     this.localStorageProvider.getItem("user").then((data:any)=>{

//       console.log("dashboard user get before : "+JSON.stringify(data));

//       data = JSON.parse(data);
//       DashboardPage.user.ancienPin = data?.ancienPin;
//       this.user.email = DashboardPage.user.email = data?.email;
//       DashboardPage.user.role = data?.role;
//       this.user.telephone = DashboardPage.user.telephone = data?.telephone;
//       this.user.nom = DashboardPage.user.nom = data?.nom;
//       this.user.prenom = DashboardPage.user.prenom = data?.prenom;

//       this.loginProvider.user = data;
//       console.log("dashboard user get after : "+JSON.stringify(data));


//     });

//                 console.log("dashboard user : "+JSON.stringify(DashboardPage.user));
//                 console.log("dashboard user val : "+JSON.stringify(this.loginProvider.getUser()));
//                 // .then( (val) => {
//                 //   this.user = val;
//                 //   console.log("dashboard user : "+JSON.stringify(this.user));
//                 //   console.log("dashboard user val : "+JSON.stringify(val));
//                 // });
//                 this.notif = 0;

//                 this.menu.forEach(element => {
//                   this.notif = this.notif + element.new;
//                 });
//                }

//         changeComponent(index:number){
//          this.getData();
//          if(this.menu[index].isActivate == true){
//            this.menu[1].isActivate = true;
//            this.menu[2].isActivate = true;
//            this.menu[index].isActivate = false;
//             setTimeout(() => {
//                 // Do something before delay
//                 this.menu[1].isActivate = false;
//                 this.menu[2].isActivate = false;
//                 this.menu[index].isActivate = true;
//             },
//              100);
//           return;
//         }
//           this.menu.forEach(element => {
//             element.isActivate = false;
//           });
//           this.menu[index].isActivate = true;
//         }

//         fund(){
//           this.menu.forEach(element => {
//             if((element.icon != "home") && (element.isActivate))
//               return false
//           });
//           return true;
//         }

//   menu = [
//     {isActivate : false,  icon:"../../../assets/icon/home", route:"menu", label:"Accueil", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/boss", route:"boss", label:"Boss", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/grouilleur", route:"grouilleur", label:"Grouilleurs", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/offre", route:"offre", label:"Bara", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/sinistre", route:"sinistre", label:"Sinistres", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/transaction", route:"transaction", label:"Transactions", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/message", route:"message", label:"Messages", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/corbeille", route:"corbeille", label:"Corbeille de tâches", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/promotion", route:"promotions", label:"Promotions", new: 0},
//     {isActivate : false, icon:"../../../assets/icon/parametre", route:"parametres", label:"Paramètres", new: 0}
//   ];
//   showAlert(msg) {
//     let alert = this.alertController.create({
//       message: msg,
//       header: 'Login',
//       buttons: ['OK']
//     });
//     alert.then(alert => alert.present());
//   }
//   opened = true;
//   vara = ">";

//   logout(){
//     this.loginProvider.logout();
//   }

//   inicioRegistro(){
//     return 5  ;
//   }

//   id : number;
//   user = {
//     email	: "",
//     nom	: "",
//     prenom	: "",
//     telephone	: ""
//   };
//   ngOnInit() {
//   this.jeveBaraService.notif.subscribe( async (res) =>  {
//     this.getData();
//       // this.jeveBaraService.getFreeTache({page : "0", size : "10000"} ).subscribe((res) => {
//       //   this.jeveBaraService.getAllMyTache({page : "0", size : "10000"} ).subscribe((res2) => {

//       //     let ELEMENT_DATA = 0;

//       //     res2.data.forEach(element => {
//       //       if(element.statutTache == "NON_TRAITER"){
//       //         ELEMENT_DATA = ELEMENT_DATA + 1;
//       //       }
//       //     });

//       //     this.menu[7].new = res.nombreTotalElements + ELEMENT_DATA;
//       //   });
//       // });
//     });

//     this.jeveBaraService.bossDetailProfil.subscribe( async (res) =>  {
//     this.id = res;
//     this.getData();
//       console.log("dasboard redirect to bossDetailProf : "+this.id);
//       this.jeveBaraService.bossId = res;
//         this.changeComponent(1);
//     });

//     this.jeveBaraService.grouillerDetailProfil.subscribe( async (res) =>  {
//     this.id = res;
//     this.getData();
//       console.log("dasboard redirect to grouillerDetai : "+this.id);
//       this.jeveBaraService.grouilleurId = res;
//         this.changeComponent(2);
//     });

//     this.jeveBaraService.offreDetailProfil.subscribe( async (res) =>  {
//     this.id = res;
//     this.getData();
//       console.log("dasboard redirect to offreDetailPro : "+this.id);
//       this.jeveBaraService.offreId = res;
//         this.changeComponent(3);
//     });

//     this.jeveBaraService.updateAdminEmiter.subscribe( (res) => {
//     this.user = res;
//     this.getData();
//     });


//     this.jeveBaraService.tacheNumberEmit.subscribe( (res) => {
//       //this.loginService.showAlert("Désolé",res);

//       this.menu[7].new = res;
//       });

//   }

// }
// >>>>>>> c2a404b0dd73380cd08def51f9696bf03378c220
