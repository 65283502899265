import { NewDasboardComponent } from './pages/new-dasboard/new-dasboard.component';
import { SinistreComponent } from './components/sinistre/sinistre.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BossComponent } from './components/boss/boss.component';
import { GrouilleursComponent } from './components/grouilleurs/grouilleurs.component';
import { MenuComponent } from './components/menu/menu.component';
import { MessagesComponent } from './components/messages/messages.component';
import { OffresComponent } from './components/offres/offres.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { CobeilleDesTachesComponent } from './components/cobeille-des-taches/cobeille-des-taches.component';
import { SmallDashboardComponent } from './components/small-dashboard/small-dashboard.component';
// import { UserStatComponent } from './components/user-stat/user-stat.component';
import { ParametresComponent } from './components/parametres/parametres.component';
import { StatistiqueComponent } from './components/statistique/statistique.component';
import { LoginGuard } from './guard/login.guard';
import { AccessGuard } from './guard/access.guard';
import { CanDeactivateGuard } from 'src/app/guard/can-desactivate.guard';
// src\app\guard\can-desactivate.guard.ts

const routes: Routes = [
  { path: 'boss',component:BossComponent,
    // canActivate: [AccessGuard ]
                                 },
  { path: 'grouilleur',component:GrouilleursComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'menu',component:MenuComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'message',component:MessagesComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'offre',component:OffresComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'toolbar',component:ToolbarComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'transaction',component:TransactionComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 's-dashboard',component:  SmallDashboardComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'parametre',component:ParametresComponent,
    // canActivate: [AccessGuard]
                                 },
  { path: 'stat',component:StatistiqueComponent,
    // canActivate: [AccessGuard]
                                 } ,
  { path: 'corbeille',component:CobeilleDesTachesComponent,
    // canActivate: [AccessGuard]
                                } ,
  { path:  'promotion',component: PromotionsComponent,
    // canActivate: [AccessGuard]
                                },
  { path:  'sinistre',component:SinistreComponent,
    // canActivate: [AccessGuard]
                                },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' ,
    // canActivate: [AccessGuard]
                                },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'etienne', component:NewDasboardComponent,
    // canActivate: [LoginGuard]
  },
  {
    path: 'dashboard',
    canActivate: [AccessGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
  },
  // {
  //   path: 'dashboard',
  //   canDeactivate: [CanDeactivateGuard]
  //   loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  // }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes),//, { preloadingStrategy: PreloadAllModules }
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
