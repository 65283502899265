import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
// import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-present-image',
  templateUrl: './present-image.component.html',
  styleUrls: ['./present-image.component.scss'],
})
export class PresentImageComponent implements OnInit {
  photo: any;

  constructor(private loginService: LoginService,
    public jeveBaraService: JeveBaraService,
    @Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<PresentImageComponent> ) {
    this.photo = this.data.images
  }

  ngOnInit() {}
  download(){
    this.jeveBaraService.downloadImage.emit();
  }
  
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}
