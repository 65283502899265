import { LoginService } from 'src/app/services/login.service';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-bloquer-user',
  templateUrl: './bloquer-user.component.html',
  styleUrls: ['./bloquer-user.component.scss'],
})
export class BloquerUserComponent implements OnInit {
  dateFin = "";
  dateDebut = "";
  motif = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<BloquerUserComponent>,
  public jeveBaraService : JeveBaraService,
  private loginService : LoginService ) { }

ngOnInit() {}

  async bloquer(){
    this.closeDialog(true);  
    const loading = this.loginService.loading();
    (await loading).present();
    this.jeveBaraService.saveUserSanction( +this.data.title ,{dateDebut:this.dateDebut, dateFin:this.dateFin ,motif:this.motif})
    .subscribe( async (res) => {    
      (await loading).dismiss();                                
      this.loginService.showAlert("Sanction","Sanction appliqué avec success !");
      this.jeveBaraService.updataBage.emit();
      this.jeveBaraService.userEmitter.emit();
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé ", e.error.message+" : Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }
  
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}