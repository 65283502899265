import { Injectable } from '@angular/core';

import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertController, LoadingController } from '@ionic/angular'; 
import { Router } from '@angular/router';
import { NavigationService } from './navigation.service';
import { Observable } from 'rxjs'; 
import { LocalStorageService } from './local-storage.service';
import { IUser } from '../models/entities/iuser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient,
    private loadingController : LoadingController,
    private alertController : AlertController,
    private localStorageProvider : LocalStorageService,
    private navigationProvider :  NavigationService,
    ) { }
  /**
   * check the  
   * @param data 
   * @returns 
   */
    // getUserInfo() : Observable<IUser> {
    //   return this.http.get<IUser>(`${this.apiUrl}user-profile`);
    // }

    showAlert(msg) {
      let alert = this.alertController.create({
        message: msg,
        header: 'Error',
        buttons: ['OK']
      });
      alert.then(alert => alert.present());
    }
}
