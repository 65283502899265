import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { IBoss } from './../../models/entities/iboss';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit {
  boss : any;
  pour : boolean = true;
  jveBaraService: JeveBaraService
  loginService: LoginService;
  constructor(private dialog: MatDialog,
    jeveBaraService: JeveBaraService,
      @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogUpdateBossComponent>) {
      jeveBaraService.getAdminById(data?.id).subscribe( (res)=>{
        this.boss = res;
      });
      if(data?.id != -1){
        this.pour = false;
      }
     }

     
     async desactiver(jeveBaraService: JeveBaraService){
        const loading = this.loginService.loading();
        (await loading).present();
          // jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
            jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
            (await loading).dismiss();                 
            this.loginService.showAlert("compte desactivé avec success !");
          },async e => {
            (await loading).dismiss();
            console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
            if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
              this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
            }else{
              if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                this.loginService.showAlert("Activation","Compte déjà actif !"); 
              }else{
                (await loading).dismiss();
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              }
            }
          });
          this.onClose();
      }
   
     async activer(jeveBaraService: JeveBaraService){
        const loading = this.loginService.loading();
        (await loading).present();
          // jeveBaraService.activeteOrDesactivateUser(this.boss?.id).subscribe( async (res)=> {
            jeveBaraService.activeteOrDesactivateUser(this.boss?.idUser).subscribe( async (res)=> {
            (await loading).dismiss();                 
            this.loginService.showAlert("compte activé avec success !");
          },async e => {
            (await loading).dismiss();
            console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
            if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
              this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
            }else{
              if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                this.loginService.showAlert("Activation","Compte déjà actif !"); 
              }else{
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              }
            }});
          this.onClose();
      }
  ngOnInit() {}

  onClose(){
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close(false);
  }

}
