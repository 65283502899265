import { IBadge } from './../../models/entities/ibadge';
// import { MaterialModule } from '../../material-module'
import { IGrouilleur } from './../../models/entities/igrouilleur';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-badge',
  templateUrl: './show-badge.component.html',
  styleUrls: ['./show-badge.component.scss'],
})
export class ShowBadgeComponent implements OnInit {
  grouilleur : IGrouilleur;
  jveBaraService: JeveBaraService;
  boutonText: string;
  panelOpenState = false;
  badge : IBadge[] = null;
  constructor(private loginService: LoginService,
              private dialog: MatDialog,
              public jeveBaraService: JeveBaraService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<DialogUpdateBossComponent>) {
      this.badge = [this.data.badge];
      console.log("badge : "+ JSON.stringify(this.badge));
  }

     async supprimer(file, item){      
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.supprimerBages(item.id).subscribe( async res => {
        (await loading).dismiss();                 
        console.log("valider : "+ res);
        this.loginService.showAlert("Supprimer badge","Badge supprimé avec succcess !")
        this.jeveBaraService.updataBage.emit();
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
     }

    async valider(id, item){
      item.statut = 'VALIDE';      
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.validerBages(id).subscribe( async res => {
        (await loading).dismiss();                 
        console.log("valider : "+ res);
        this.jeveBaraService.updataBage.emit();
      },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    }

     async invalider(id, item){
        item.statut = 'NON_VALIDE';
        const loading = this.loginService.loading();
        (await loading).present();
        this.jeveBaraService.invaliderBages(id).subscribe( async res => {
                (await loading).dismiss();                 
          console.log("invalider : "+ res);
          this.jeveBaraService.updataBage.emit();
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        });
     }
     
    private apiUrl = environment.apiUrl;

     ouvrir(path){  
        console.log(path);
        const url = this.apiUrl +"api/file?fileReference="+ path;
        window.open( url, "_blank");
     }
     
     download(path){
       path.forEach(element => {
        console.log(element.reference);
        this.ouvrir(element.reference);
      });
     }
     
     delete(){
      this.onClose();
      this.loginService.showConfirmAlert("supprimer",'voulez-vous vraiment supprimer '+ this.grouilleur.nom  +'?').afterClosed().subscribe(async res => {
        if(res){
          if(this.grouilleur.actif){
            const loading = this.loginService.loading();
            (await loading).present();
            // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( async (res)=> {
              this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( async (res)=> {
                (await loading).dismiss();                 
                this.loginService.showAlert( "Desactivation","compte desactivé avec success !");
              },async e => {
                (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
            }});      
          }else{            
            const loading = this.loginService.loading();
            (await loading).present();
            // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( async (res)=> {
              this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( async (res)=> {
              (await loading).dismiss();                 
              this.loginService.showAlert( "activation","compte activé avec success !");
            },async e => {
              (await loading).dismiss();
              console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
              if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
              }else{
                if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                  this.loginService.showAlert("Activation","Compte déjà actif !"); 
                }else{
                  this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                }
            }});              
          }
        }
      });       
    }

    
    desactiver(){
      this.onClose();
      this.loginService.showConfirmAlert(this.boutonText, 'voulez-vous vraiment '+ this.boutonText +'?').
      afterClosed().subscribe(async res => {    
        if(res){
          console.log(this.grouilleur.actif);
           if(this.grouilleur.actif){     
            const loading = this.loginService.loading();
            (await loading).present();
              // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( async (res)=> {
                this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( async (res)=> {
                (await loading).dismiss();                 
                this.loginService.showAlert( "Desactivation","compte desactivé avec success !");
              },async e => {
                (await loading).dismiss();
                console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
                if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                  this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
                }else{
                  if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                    this.loginService.showAlert("Activation","Compte déjà actif !"); 
                  }else{
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  }
            }});      
           }else{
            const loading = this.loginService.loading();
            (await loading).present();
            // this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.id).subscribe( async (res)=> {
              this.jeveBaraService.activeteOrDesactivateUser(this.grouilleur?.idUser).subscribe( async (res)=> {
              (await loading).dismiss();                 
              this.loginService.showAlert( "activation","compte activé avec success !");
            },async e => {
              (await loading).dismiss();
              console.log(JSON.stringify(e.error.message) + " : TELEPHONE_PAS_VERIFIER");
              if(e.error.message === "TELEPHONE_PAS_VERIFIER"){
                this.loginService.showAlert("Erreur d'activation","Désolé cet utilisateur doit vérifier son numéro de téléphone !");       
              }else{
                if(e.error.message === "GROUILLEUR_DEJA_ACTIF"){
                  this.loginService.showAlert("Activation","Compte déjà actif !"); 
                }else{
                  this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                }
            }});              
          }   
        }
          console.log("resultat : "+res);   
      });       
       console.log(this.grouilleur.actif);
    }
    
  ngOnInit() {
  }

  onClose(){
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close(false);
  }
}
