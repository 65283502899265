import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  prefix = "tes_";
  constructor(private storageService: StorageService) { }

    set(key: string, value: any) {
      this.storageService.set(this.prefix+key, value); 
    }
    setAsJson(key: string, value: any) {
      return this.storageService.set(this.prefix+key, JSON.stringify(value));
    }

    setItem(key: string, value: any) {
      this.storageService.set(this.prefix+key, value); 
    }

    get(key: string) : Promise<any>{
      return this.storageService.get(this.prefix+key);
    }

    getAsJSON<T>(key: string): Promise<T> {

     return  new Promise((resolve,reject)=>{
      this.storageService.get(this.prefix+key).then(val=>{
        resolve(JSON.parse(val) as T)
      }).catch(e=>{
        resolve(null);
      });

     });
      
       //JSON.stringify();
    }

    getItem(key: string) {
      return this.storageService.get(this.prefix+key);
    }

    clearSession() {    
      return this.storageService.clear();
    }
    remove(key: string) {
        this.storageService.remove(this.prefix+key);
    }
}
