import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertController, LoadingController } from '@ionic/angular';
import {Injectable} from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.scss'],
})
export class StatistiqueComponent implements OnInit {

  constructor(private alertController : AlertController,
    private loginService : LoginService) { }

  ngOnInit() {}

}
