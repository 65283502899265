import { LoginService } from 'src/app/services/login.service';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit } from '@angular/core';
import { ITransaction } from 'src/app/models/entities/itransaction';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit {
  transactionDetails: ITransaction = {};
  details: boolean = false;
  displayedColumns: string[] = ['Date','Heure', 'Valeur', 'Type', 'Statut', 'Emetteur'];
  displayedColumns2: string[] = ['destinataire', 'Mode de paiement','Numéro de la transaction', 'Descriptipn'];

  
  dataSource = new MatTableDataSource<ITransaction>([{}]);
  constructor(public jeveBaraService : JeveBaraService,
              private loginService : LoginService) { }

  ngOnInit() {}

  getDetails(id : number){  
    console.log("transaction detail : "+ JSON.stringify(id));
    this.jeveBaraService.getTransactionByid(id).subscribe((res) => {
      console.log("transaction detail object : "+ JSON.stringify(res));
      this.transactionDetails = res;
      this.details = true;
    });
  }
  effectuer(id : number){
    this.jeveBaraService.effectuerTransaction(id).subscribe((res) => {
      this.loginService.showAlert("Effectuer Transaction", "Transaction effectuer avec success !");
      this.getDetails(id);
    })
  }
  
  annuler(id : number){
    this.jeveBaraService.annulerTransactionByid(id).subscribe((res) => {
      this.loginService.showAlert("Annuler Transaction", "Transaction annuler avec success !");
      this.getDetails(id);
    })
  }
  
  retour(){
    this.details = false;    
  }
}
