import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'; 

import { defer, from,Observable, Observer, of } from 'rxjs'; 
import { LocalStorageService } from '../local-storage.service';
import { switchMap } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  router: Router;  
  constructor(public auth: LoginService, ) {}  

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`
        }
      });
      // this.auth.showAlert("Token !",JSON.stringify(this.auth.getToken()));
      
      console.log("token : " + this.auth.getToken());
      return next.handle(request);
    }

    
    handleError(request: HttpRequest<any>, err: HttpErrorResponse, next: HttpHandler): Observable<any> {
      console.log(err);
      if (err.status === 401 || err.status === 403) {
          this.router.navigateByUrl(`/login`);
          // return Observable.of(err.message);
      }
      //handle your auth error or rethrow
      return next.handle(request);
    }
  //   handleError(err: HttpErrorResponse): Observable<any> {
  //     console.log(err);
  //     if (err.status === 401 || err.status === 403) {
  //         // Cookie.deleteUser();
  //         this.router.navigateByUrl(`/login`);
  //         return Observable.throw(err.message);
  //     }
  //     // handle your auth error or rethrow
  //     return Observable.throw(err);
  // }

     
}

 
