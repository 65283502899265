import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/entities/iuser';
import { CanComponentDeactivate } from 'src/app/guard/can-desactivate.guard';
import { Observable } from 'rxjs';

import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss'],
})
export class AddNewUserComponent implements OnInit ,CanComponentDeactivate {
  selectedValueg: string = "periode";

  telephone = "787878" ;
  role = "OPERATIONNEL"; 
  email = "@gmail.com";
  nom = "";
  pin = "0000";
  prenom = "qfs";
  referenceFichier = "";
  saved = false;
  update = false;
  submitt: string;

  periodess  = [
    // ADMIN, , , , UTILISATEUR
    {value: 'MANAGER', viewValue: 'Manager'},
    {value: 'FINANCIER', viewValue: 'Financier'},
    {value: 'OPERATIONNEL', viewValue: 'Opérationnel'},
  ];
  
  roles = ["ADMIN", "BOSS", "GROUILLEUR"];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              public loginService : LoginService,
              public jeveBaraService : JeveBaraService ) {
                if(data.id){
                      this.submitt = "Modifier";
                      this.update = true;
                      console.log("id User : "+ data.id);    
                      const loading = this.loginService.loading();
                    this.jeveBaraService.getBackOfficeUserById(data.id).subscribe( async (res) => {
                      (await loading).dismiss();                 
                      console.log("User : "+ JSON.stringify(res));
                      this.telephone = res.telephone;
                      this.role = res.roleUtilisateur;
                      this.email = res.email  ;
                      this.nom = res.nom;
                      this.pin = res.pin;
                      this.prenom = res.prenom;
                      this.referenceFichier = res.referenceFichier;      
                    },async e => {
                      (await loading).dismiss();                 
                    });
                }else{
                  this.role = "MANAGER"; 
                  this.submitt = "Ajouter";
                }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.telephone.length > 0 || this.role.length > 0)
        && !this.saved) { 
      return confirm('Your changes are unsaved!! Do you like to exit');
    }
    return true;
  }
  
  ngOnInit() {
  }

  async onSubmit(form: NgForm) { 
    this.closeDialog(true);
    if(this.data.id){
    }else{
      if(form.value.telephone!=""){      
        let user = {
                    "prenom":form.value.prenom,
                    "nom":form.value.nom,
                    "role":form.value.role,
                    "email":form.value.email,
                    "telephone":form.value.telephone,
                    "pin": "0000",
                    };  
    const loading = this.loginService.loading();
    (await loading).present();
    this.jeveBaraService.registerUser(user).subscribe(async (res) => {
          (await loading).dismiss();                 
          this.loginService.showAlert("Ajout d'utilisateur","Utilisateur Enregistré avec success !");  
          this.jeveBaraService.addBackOffice.emit();
        },async e => {
          (await loading).dismiss(); 
          console.log("Erreur de modification de modification "+JSON.stringify(e.error.message));
          if(e.error.message == "PHONE_NUMBER_ALREADY_EXISTS"){
            const loading = this.loginService.loading();
            (await loading).present();
            this.jeveBaraService.getAllBackOfficeUser({page:"0",size:"1000"}).subscribe( async (res) => { 
            (await loading).dismiss();            
              res.data.forEach(element => {
                if(element.telephone == form.value.telephone){
                  let user = {
                    "prenom":form.value.prenom,
                    "nom":form.value.nom,
                    "role":form.value.role,
                    "email":form.value.email,
                    "telephone":form.value.telephone,
                    "pin": "0000",
                  };  
                  if(!element.actif){
                    console.log(element);
                    this.jeveBaraService.activateOrDeactivateBackOfficeUser(element.id).subscribe((res) => {
                      this.jeveBaraService.updateBackOfficeUser(element.id, user).subscribe((res) => {
                        this.loginService.showAlert("Ajout d'utilisateur","Utilisateur Enregistré avec success !"); 
                        return;
                      });
                    });
                  }
                  this.jeveBaraService.updateBackOfficeUser(element.id, user).subscribe((res) => {
                    this.loginService.showAlert("Ajout d'utilisateur","Utilisateur Enregistré avec success !"); 
                    return;
                  });
                }
              });
            },async e => {
              (await loading).dismiss();                 
            });
            this.loginService.showAlert("Erreur de création", "Ce numéro existe déjà !"); 
          }else{
            this.loginService.showAlert("Erreur de création", "Oups erreur inattendue veuillez réessayer plus tard !");
          }
        });
        console.log("user : "+this.telephone+" role : "+ this.role);
        console.log("user to add : "+JSON.stringify(user));
        this.saved = true;
        }else{
          this.loginService.showAlert("Numéro","Numéro de Téléphone Obligatoire");
      }
        
    }
  }
    
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}
