import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular'; 
import { ToastCategory } from '../models/enum/toast-category.enum';
 

type ToastConfig = {
  category?: ToastCategory;
  duration?: number;
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  loading: any;
  


  constructor(private toastController: ToastController , 
    private alertController :  AlertController,
    private loadingCtrl: LoadingController,) { 
    
  }
  /**
   * 
   * @param message 
   * @param duration in milli second
   */
  
  async makeToast(message?:string,config?:ToastConfig) :Promise<void>{
    let duration= config?.duration;
    let toastCategory=config?.category;;
    var addClass= {
      cssClass: 'toast-app-default '
    }
    switch (toastCategory) {
         
      case ToastCategory.SUCCESS:
          addClass.cssClass+=" toast-operation-done"
          break;
      case ToastCategory.FAILED:
            addClass.cssClass+=" toast-operation-failed"
            break;
      case ToastCategory.DANGER:
            addClass.cssClass+=" toast-operation-failed-danger"
            break;
       
    
      default:
        break;
    } 
    
    const toast = await this.toastController.create({
      message: message,
      duration: duration || 2500,
      ...addClass
    });
    toast.present();
  }

 


    /**
   * 
   * @param message text to display
   * @param toastCategory  category type (SUCESS,FAILED , DANGER ..)
   * @param duration in millis
   */
     showToastByCategory(message,toastCategory:ToastCategory,duration?) {

      var addClass= {
        cssClass: 'toast-app'
      }
      switch (toastCategory) {
         
        case ToastCategory.SUCCESS:
            addClass.cssClass+=" toast-operation-done"
            break;
        case ToastCategory.FAILED:
              addClass.cssClass+=" toast-operation-failed"
              break;
        case ToastCategory.DANGER:
              addClass.cssClass+=" toast-operation-failed-danger"
              break;
         
      
        default:
          break;
      }
      if(!duration){
        duration = 3000;
      }
  
      this.toastController.create({ message: message, duration: duration,...addClass }).then(r => r.present())
    }


    showLoading(msg?:any) {
      let message : any;
      if(msg){
        message = {
          message :  msg 
        }
      }
       
        this.loadingCtrl.create({ spinner: 'circles',mode: 'ios', duration: 1000 ,...message })
        .then(res => {
        
          this.loading = res;
          this.loading.present();
        });
    }

    async hideLoading() {
      setTimeout( async ()=>{
        
        if (this.loading) {
          this.loading?.dismiss();
          this.loading = null;
        }
        const popover = await this.loadingCtrl.getTop();
        popover?.dismiss();
        this.loadingCtrl?.dismiss();
         
      }, 100)
      
    }
    





    async deleteMedia(popupActionListener: PopupActionListener) {

      const alertController = await this.alertController.create({
        header: 'Supprimer',
        message: `Voulez vous le media ?`,
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              popupActionListener?.onNegative();
            }
          }, 
          {
            text: 'Supprimer',
            handler: () => {
              popupActionListener?.onPositive();
            }
          }
        ]
      });
    
      await alertController.present();
      
    }

 
}

export interface PopupActionListener {
   onPositive?();
   onNegative?();
   onNeutral?();
}
