// import { JeveBaraService } from './jeve-bara.service';
import { TaiterTacheComponent } from './../AlertComponents/taiter-tache/taiter-tache.component';
import { PresentImageComponent } from './../AlertComponents/present-image/present-image.component';
import { AddSousCategirieComponent } from './../AlertComponents/add-sous-categirie/add-sous-categirie.component';
import { ShowBadgeComponent } from './../AlertComponents/show-badge/show-badge.component';
import { IBadge } from './../models/entities/ibadge';
import { BadgesComponent } from './../components/badges/badges.component';
import { AttriberTacheComponent } from './../AlertComponents/attriber-tache/attriber-tache.component';
import { element } from 'protractor';
import { UpdateCategorieComponent } from './../AlertComponents/update-categorie/update-categorie.component';
import { AddCategorieComponent } from './../AlertComponents/add-categorie/add-categorie.component';
import { UpdateUserComponent } from './../AlertComponents/update-user/update-user.component';
import { AddWalletComponent } from './../AlertComponents/add-wallet/add-wallet.component';
import { AffecterBaraComponent } from './../AlertComponents/affecter-bara/affecter-bara.component';
import { DebiterWalletComponent } from './../AlertComponents/debiter-wallet/debiter-wallet.component';
import { CrediterWalletComponent } from './../AlertComponents/crediter-wallet/crediter-wallet.component';
import { RemoveUserFormPositionnementComponent } from './../AlertComponents/remove-user-form-positionnement/remove-user-form-positionnement.component';
import { IUser } from 'src/app/models/entities/iuser';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { Injectable, Component } from '@angular/core';

import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavigationService } from './navigation.service';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';
// import { ConfimDialogComponent } from '../confim-dialog/confim-dialog.component';
import { ComponentType } from '@angular/cdk/portal';
// import { ConfirmDialogComponent } from '../AlertComponents/confim-dialog/confim-dialog.component';
import { DialogUpdateBossComponent } from '../AlertComponents/dialog-update-boss/dialog-update-boss.component';
import { OffreDetailsComponent } from '../AlertComponents/offre-details/offre-details.component';
import { GrouilleurProfilComponent } from '../AlertComponents/grouilleur-profil/grouilleur-profil.component';
import { TransactionDetailsComponent } from '../AlertComponents/transaction-details/transaction-details.component';
import { BossProfilComponent } from '../AlertComponents/boss-profil/boss-profil.component';
import { ConfirmDialogComponent } from 'src/app/AlertComponents/confirm-dialog/confirm-dialog.component';
import { AlertdialogComponent } from '../AlertComponents/alertdialog/alertdialog.component';
import { AddNewUserComponent } from '../AlertComponents/add-new-user/add-new-user.component';
import { logInfo } from '../core/core';
import { BloquerUserComponent } from '../AlertComponents/bloquer-user/bloquer-user.component';
import { SendMessageToUserComponent } from '../AlertComponents/send-message-to-user/send-message-to-user.component';
import { TakeTacheComponent } from '../AlertComponents/take-tache/take-tache.component';
import { ShowEntretienAlertComponent } from '../AlertComponents/show-entretien-alert/show-entretien-alert.component';
import { ChooseCibleMessageComponent } from '../AlertComponents/choose-cible-message/choose-cible-message.component';
import { UpdateSousCategirieComponent } from '../AlertComponents/update-sous-categirie/update-sous-categirie.component';
import { LookMessageComponent } from '../AlertComponents/look-message/look-message.component';
// import { ConfirmDialogComponent } from '../AlertComponents/confirm-dialog/confirm-dialog.component';
// import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';
// import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  async loading(message : string = "Veuillez Patienter"){
    return await this.loadingController.create({
      cssClass: 'alert-style',
      message: message,
      duration: null
    });
  }
  // await loading.present();
  // loading.dismiss();

  apiUrl = environment.apiUrl;
  user = {"prenom":"null","nom":"admin","role":"ADMIN","email":"null","telephone":"237","ancienPin":"1234"};
  phoneNumber: any;
  name: any;
  lastName: any;
  email: any;
  city: any;
  categories: any;

  public async setUser(user : any) : Promise<any>{

    this.user.ancienPin = user?.ancienPin;
    this.user.email = user?.email;
    this.user.role = user?.role;
    this.user.telephone = user?.telephone;
    this.user.nom = user?.nom;
    this.user.prenom = user?.prenom;

    await this.localStorageProvider.setItem("user", user );
    await this.localStorageProvider.getItem("user").then( res => {
      this.user = res;
      console.log("service user set : "+JSON.stringify(res));
      return res;
    });
  }

  public  getUser() : any{

    this.localStorageProvider.getItem("user").then((data:any)=>{

      console.log("service user get before : "+JSON.stringify(data));

      data = JSON.parse(data);
      console.log("service user get after : "+JSON.stringify(data));

       return data;
    });

  }
  constructor(private http: HttpClient,
    private loadingController : LoadingController,
    private localStorageProvider : LocalStorageService,
    private navigationProvider :  NavigationService,
    private alertController : AlertController,
    private router: Router,
    // public jeveBaraService :  JeveBaraService,
    private dialog :  MatDialog
    ) { }


  isActivate(){
    this.localStorageProvider.get("token");
  }

  /**
   * check the
   * @param data
   * @returns
  */
  async login(data : {telephone:string,pin:string}) {
    const loading = await this.loadingController.create({
      message: 'Authentification...'
    });

    await loading.present();
    return this.http.post(`${this.apiUrl}api/login`, data).pipe(
      tap(async (res:any) => {

        console.log("login data : "+ JSON.stringify(res));

        this.user.prenom = res.prenom;
        this.user.nom = res.nom;
        this.user.role = res.role;
        this.user.email = res.email;
        this.user.telephone = data.telephone;
        this.user.ancienPin = data.pin;
        this.logInfo(data, res, loading);
      }),
      catchError(e => {
        loading.dismiss( res);
        console.log(e);
        let err = e.error?.error?.message ||
        // e.error.msg ||
          e.statusText;
          // this.logInfo(data, this.user, loading);
        // this.showAlert("Incorrect","mot de passe ou username incorrect"+ JSON.stringify(this.user));
        throw new Error(e);
      })
    );
  }

  async logInfo(data : any,res : any, loading : any){
    localStorage.setItem("jevebara_token",res.token);
    await this.localStorageProvider.set("token",res.token);

    localStorage.setItem("isLoggedIn","true");

    this.user.prenom = res.prenom;
    this.user.nom = res.nom;
    this.user.role = res.role;
    this.user.email = res.email;
    this.user.telephone = res.telephone;
    this.user.ancienPin = data.pin;

    console.log("CurrentUser ___ ",JSON.stringify(res));

    console.log("user : "+ JSON.stringify(this.user));

    await this.localStorageProvider.setItem("user", JSON.stringify(this.user) );
    // await this.localStorageProvider.get("user");
    loading.dismiss();

    let ros = this.user;

    if(res.token!=null)
      this.navigationProvider.openPage("/dashboard");
  }

  public showAlert(title ? :string, msg ? : string) {
    return this.dialog.open(AlertdialogComponent,{
      panelClass:'confirm-dialog-container',
      width:'700px',
      disableClose: true,
      position: {top : '300px'},
      data:{
        message: msg,
        title: title,
      }
    });
  }

  public showPresenter(images ? :any[]) {
    return this.dialog.open(PresentImageComponent,{
      panelClass:'confirm-dialog-container',
      width:'950px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        images: images,
      }
    });
  }

  public takeTache(id ? : number, msg ? : string) {
    return this.dialog.open(TakeTacheComponent,{
      panelClass:'confirm-dialog-container',
    width :'697px',
    height :'890px',
      disableClose: true,
      position: {top : '90px', right : "0px", bottom : "0px"},
      data:{
        message: msg,
        title: id
      }
    });
  }


  public badge(id ? : number, type ? : string) {
    return this.dialog.open(BadgesComponent,{
      panelClass:'confirm-dialog-container',
      width:'596px',
      height: '350px',
      disableClose: true,
      position: {top : '150px'},
      data:{
        message: type,
        id: id
      }
    });
  }

  public updateBadge(id ? : number, type ? : string, badge ? : IBadge) {
    return this.dialog.open(BadgesComponent,{
      panelClass:'confirm-dialog-container',
      width:'596px',
      height: '350px',
      disableClose: true,
      position: {top : '150px'},
      data:{
        message: type,
        id: id,
        badge: badge
      }
    });
  }

  public showAffecterBara(title ? : string, msg ? : string) {
    return this.dialog.open(AffecterBaraComponent,{
     panelClass:'confirm-dialog-container',
     width :'558px',
     height :'400px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        message: msg,
        title: title
      }
    });
  }

  public ChooseCibleMessageComponent(id : number, type : string) {
    return this.dialog.open(ChooseCibleMessageComponent,{
     panelClass:'confirm-dialog-container',
     width :'558px',
     height :'400px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        message: type,
        title: id
      }
    });
  }

  public showAffecterTache(title ? : string, msg ? : string) {
    return this.dialog.open(AttriberTacheComponent,{
      panelClass:'confirm-dialog-container',
      width :'558px',
      height :'500px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        message: msg,
        title: title
      }
    });
  }

  public showAddWalletToUser(id ? : string) {
  return this.dialog.open(AddWalletComponent,{
    panelClass:'confirm-dialog-container',
    width :'578px',
    height :'454px',
    disableClose: true,
    position: {top : '100px'},
    data:{
      id: id
    }
  });
  }

  public showDebiterWallet(title ? : string, msg ? : string) {
    return this.dialog.open(DebiterWalletComponent,{
      panelClass:'confirm-dialog-container',
     //  width:'390px',
     width :'558px',
     height :'500px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        message: msg,
        title: title
      }
    });
  }

  public showCrediterWallet(title ? : string, msg ? : string) {
    return this.dialog.open(CrediterWalletComponent,{
      panelClass:'confirm-dialog-container',
     //  width:'390px',
     width :'558px',
     height :'500px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        message: msg,
        title: title
      }
    });
  }

   public showRemoveUserFromPositionnement(title ? : string, msg ? : string) {
    return this.dialog.open(RemoveUserFormPositionnementComponent,{
      panelClass:'confirm-dialog-container',
      width :'578px',
      height :'320px',
      disableClose: true,
      position: {top : '300px'},
      data:{
        message: msg,
        title: title
      }
    });
  }

  public showBloquerUser(title ? : string, msg ? : string) {
    return this.dialog.open(BloquerUserComponent,{
      panelClass:'confirm-dialog-container',
     //  width:'390px',
     width :'878px',
     height :'500px',
      disableClose: true,
      position: {top : '100px'},
      data:{
        message: msg,
        title: title
      }
    });
  }

   public showAddNewUser(id : number = null) {
     return this.dialog.open(AddNewUserComponent,{
          panelClass:'confirm-dialog-container',
          width:'650px',
          height: '600px',
          disableClose: true,
          position: {top : '150px'},
          data:{
            id: id
          }
        });
  }

  public showUpdateUser(id : number = null) {
    return this.dialog.open(UpdateUserComponent,{
          panelClass:'confirm-dialog-container',
          width:'650px',
          height: '600px',
          disableClose: true,
          position: {top : '150px'},
          data:{
            id: id
          }
        });
  }

  public showAddCategorie(id : number = null) {
    return this.dialog.open(AddCategorieComponent,{
          panelClass:'confirm-dialog-container',
          width:'596px',
          height: '500px',
          disableClose: true,
          position: {top : '150px'},
          data:{
            id: id
          }
        });
  }

  public showUpdateCategorie(id : number = null) {
    return this.dialog.open(UpdateCategorieComponent,{
          panelClass:'confirm-dialog-container',
          width:'596px',
          height: '500px',
          disableClose: true,
          position: {top : '150px'},
          data:{
            id: id
          }
        });
  }

  public showAddSousCategorie(id : number = null, libelle : string) {
    return this.dialog.open(AddSousCategirieComponent,{
          panelClass:'confirm-dialog-container',
          width:'596px',
          height: '500px',
          disableClose: true,
          position: {top : '150px'},
          data:{
            id: id,
            libelle: libelle
          }
        });
  }

  public showUpdateSousCategorie(id : number = null) {
    return this.dialog.open(UpdateSousCategirieComponent,{
          panelClass:'confirm-dialog-container',
          width:'596px',
          height: '500px',
          disableClose: true,
          position: {top : '150px'},
          data:{
            id: id
          }
        });
  }

  public showConfirmAlert(title ? : string, msg ? : string) {
  return this.dialog.open(ConfirmDialogComponent,{
        panelClass:'confirm-dialog-container',
      width :'578px',
      height :'300px',
        disableClose: true,
        position: {top : '300px'},
        data:{
          message: msg,
          title: title
        }
      });
  }

  public showConfirmAlertEntretien(title ? : string, msg ? : string) {
    
  if(this.mobile){
    this.position = '100';
  }else{
    this.position = '200';
  }
  return this.dialog.open(ShowEntretienAlertComponent,{
        panelClass:'confirm-dialog-container',
      width :'578px',
      height :'300px',
        disableClose: true,
        position: {top : this.position+'px'},
        data:{
          message: msg,
          title: title
        }
      });
  }
  
  largeur : string = '937';
  hauteur : string = '568';
  position : string = '200';

  mobile = false;

  public showSendMessageToUser(id : number, type : string) {

  if(this.mobile){
    this.largeur = '327' ;
    this.hauteur = '328' ;
    this.position = '50';
  }else{
    this.largeur = '937';
    this.hauteur = '568';
    this.position = '200';
  }
  return this.dialog.open(SendMessageToUserComponent,{
        panelClass:'confirm-dialog-container',
      width: this.largeur+'px',
      height: this.hauteur+'px',
        disableClose: true,
        position: {top : this.position+'px'},
        data:{
          id: id,
          type : type
        }
      });
  }

  public LookMessageOfPositionnement(id : number) {
  return this.dialog.open(LookMessageComponent,{
        panelClass:'confirm-dialog-container',
      //  width:'390px',
      width: '937px',
      height: '568px',
        disableClose: true,
        position: {top : '200px'},
        data:{
          id: id
        }
      });
  }

  public showSendTaiterSinistre(id : number) {
  return this.dialog.open(TaiterTacheComponent,{
        panelClass:'confirm-dialog-container',
      //  width:'390px',
      width: '937px',
      height: '568px',
        disableClose: true,
        position: {top : '200px'},
        data:{
          id: id
        }
      });
  }

  public showUpdateBoss(msg ? : string, id ?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg,
      id: id
    }

    this.dialog.open(DialogUpdateBossComponent, dialogConfig);
  }

  public showProfilBoss(msg ? : string, id ?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg,
      id: id
    }

    this.dialog.open(BossProfilComponent, dialogConfig);
  }

  public showProfilGrouilleur(msg ? : string, id ?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg,
      id: id
    }

    this.dialog.open(GrouilleurProfilComponent, dialogConfig);
  }

  public showBadgeGrouilleur(badge ?: IBadge) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      badge : badge
    }

    this.dialog.open(ShowBadgeComponent, dialogConfig);
  }

  public showOffreDetail(msg ? : string, id ?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg,
      id: id
    }

    this.dialog.open(OffreDetailsComponent, dialogConfig);
  }

  public showTransactionDetail(msg ? : string, id ?: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg,
      id: id
    }

    this.dialog.open(TransactionDetailsComponent, dialogConfig);
  }

getToken(){
  return localStorage.getItem("jevebara_token");
}

    async logout(){
      // return;
      // return;
      await this.localStorageProvider.clearSession().then((res) => {
        localStorage.removeItem("isLoggedIn");
        localStorage.clear();
        // this.navigationProvider.openPage("/login");

        const url = this.router.url ;
        window.open( url, "_self");
      });
    }

    async confirmLogout(popupActionListener: PopupActionListener) {

      const alertController = await this.alertController.create({
        header: 'Deconnexion',
        message: `Continuez l'action ?`,
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              popupActionListener?.onNegative();
            }
          },
          {
            text: 'Oui',
            handler: () => {
              popupActionListener?.onPositive();
            }
          }
        ]
      });

      await alertController.present();

    }



}

export interface PopupActionListener {
  onPositive?();
  onNegative?();
  onNeutral?();
}

function res(data: { telephone: string; pin: string; }, res: any, loading: HTMLIonLoadingElement) {
  throw new Error('Function not implemented.');
}

