import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
 
@Injectable()
export class AppService {
    constructor(private httpClient: HttpClient) {
    }
 
    getChartData(): Observable<Array<Array<string | number>>> {
        return this.httpClient.get<Array<Array<string | number>>>(`http://localhost:3000/chartData`);
        
    }

    getData(){
        return {
            "chartData": [
                [
                    "London",
                    8136000
                ],
                [
                    "New York",
                    8538000
                ],
                [
                    "Paris",
                    2244000
                ],
                [
                    "Berlin",
                    3470000
                ],
                [
                    "Kairo",
                    19500000
                ]
            ]
        };
    }
}
 