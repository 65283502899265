import { LoginService } from 'src/app/services/login.service';
import { OnDestroy, OnInit } from '@angular/core';
// import { ApexDataLabels } from 'ng-apexcharts';
// import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ChartType } from "angular-google-charts";
import { AppService } from './../../app.service';
import { Component, Input, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { MatTableDataSource } from '@angular/material/table';
import { IBoss } from 'src/app/models/entities/iboss';
import { MatPaginator } from '@angular/material/paginator';

export type ChartOptions = {
  chart: ApexChart;
  series: ApexAxisChartSeries | any[];
  stroke: ApexStroke;
  markers: ApexMarkers;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  colors: any[];
  labels: any[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
};
interface periodes {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy{
  subgetMoyenneOffreparMois: any;
  subgetChiffreAffaireParMois: any;
  subgetMoyenneOffreparJour: any;
  subgetMoyenneUtilisateurparJour: any;
  grouiAct: any;


  ngOnDestroy(): void {
    // this.subgetMoyenneOffreparMois.unsubscribe();
    // this.subgetChiffreAffaireParMois.unsubscribe();
    // this.subgetMoyenneOffreparJour.unsubscribe();
    // this.subgetMoyenneUtilisateurparJour.unsubscribe();
  }

  data: any = [];
  header: any = ['name', 'weight','etienne', 'symbol', 'symbol', 'symbol'];
  selectedValue: string = "periode";
  selectedValueg: string = "periode";

  periodess  = [
    {value: 'jour', viewValue: 'Par jour'},
    {value: 'mois', viewValue: 'Par mois'},
    {value: 'ans', viewValue: 'Par année'},
  ];

  public visites: number = 0;
  public default: string = "an";
  public offres: number = 0;
  public utilisateur: number = 0;
  public static mois: number = 0;

  public options: Partial<ChartOptions>;
  public barOptions: Partial<ChartOptions>;
  public areaOptions: Partial<ChartOptions>;
  public radial: Partial<ChartOptions>;
  public chartOptions: Partial<ChartOptions>;
  static value: any;
  dataSource: MatTableDataSource<IBoss> = new MatTableDataSource<IBoss>([]);
  @ViewChild(MatPaginator) paginator : MatPaginator;
  dataBarCharOffre = [];
  dataBarCharChiffreAffaire = [];
  constructor(private appService: AppService,
              private loginService: LoginService,
              public jeveBaraService: JeveBaraService) {

                this.jeveBaraService.getAllSinistre({page : '0', size : '10000'}).subscribe((res) => {
                  let i = 0 ;
                  res.data.forEach(element => {
                    if(element?.statut != "RESOLU"){
                      i = i + 1 ;
                    }
                  });
                  this.visites = i;
                });
                this.jeveBaraService.getOffres({page : '0', size : '1'}).subscribe((res) => {
                  this.offres =res.nombreTotalElements;
                });
                this.jeveBaraService.getAllBoss({page : '0', size : '1'}).subscribe((res) => {
                  this.utilisateur =res.nombreTotalElements;
                });
                this.jeveBaraService.getActiveGrouilleur({page : '0', size : '1'}).subscribe((res) => {
                  this.grouiAct =res.nombreTotalElements;
                });


    let date = new Date();
    this.subgetMoyenneOffreparJour = this.jeveBaraService.getMoyenneOffreparJour(date.getFullYear()).subscribe((res) => {
      this.subgetMoyenneUtilisateurparJour = this.jeveBaraService.getMoyenneUtilisateurparJour(date.getFullYear()).subscribe((res) => {
          this.subgetChiffreAffaireParMois = this.jeveBaraService.getChiffreAffaireParMois(date.getFullYear()).subscribe((res) => {
            this.dataBarCharChiffreAffaire = res;
            this.subgetMoyenneOffreparMois = this.jeveBaraService.getMoyenneOffreparMois(date.getFullYear()).subscribe((res) => {
                this.dataBarCharOffre = res;
                this.barChart();
              //   this.chartData =  [
              //     [
              //         "London",
              //         this.dataBarCharChiffreAffaire[0].montant
              //     ],
              //     [
              //         "New York",
              //         this.dataBarCharChiffreAffaire[1].montant
              //     ],
              //     [
              //         "Paris",
              //         this.dataBarCharChiffreAffaire[2].montant
              //     ],
              //     [
              //         "Berlin",
              //         this.dataBarCharChiffreAffaire[3].montant
              //     ],
              //     [
              //         "Kairo",
              //         this.dataBarCharChiffreAffaire[4].montant
              //     ]
              // ];
              });
            });
        });
    });
  }


  title = 'google-charts';
  myType = ChartType.PieChart;
//   chartData: any =  [
//     [
//         "London",
//         21
//     ],
//     [
//         "New York",
//         21
//     ],
//     [
//         "Paris",
//         21
//     ],
//     [
//         "Berlin",
//         21
//     ],
//     [
//         "Kairo",
//         21
//     ]
// ];
  private getChartData() {
  }

  ngOnInit() {
    this.getChartData();
    // this.maFonction();
    this.dataSource.paginator = this.paginator
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

 largeur = '120%';

  barChart() {
    if(this.jeveBaraService.mobile){
      this.largeur = "50%";
    }
    this.barOptions = {
      chart: {
        type: 'bar',
        height: 200,
        width: this.largeur,
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      fill: {
        colors : [
          function({ value, seriesIndex, ApexDataLabels , w }) {
            var date = new Date();
            var month = date.getMonth();
              if (MenuComponent.mois != -123 && MenuComponent.mois == month) {
              MenuComponent.mois = -123;
              MenuComponent.value = value;
            } else {
              MenuComponent.mois++;
              if(MenuComponent.value == value){
                return '#db7100'
              }else{
                return '#dbdbdb'
              }
            }
          }
        // }
        ],
        pattern: {
          style: [
            'boder-raduis: 10%;'
          ],
          width: 6,
          // height: 6,
          // strokeWidth: 2
        }
      },
      series: [
        {
          // this.dataBarCharChiffreAffaire[0].montant
          // this.dataBarCharChiffreAffaire[0].nombreOffres
          name: 'Offres',
          data1: [852,32,98,54,32, 32,32,65,56,65,32,21],
          data: [
                this.dataBarCharOffre[0].nombreOffres,
                this.dataBarCharOffre[1].nombreOffres,
                this.dataBarCharOffre[2].nombreOffres,
                this.dataBarCharOffre[3].nombreOffres,
                this.dataBarCharOffre[4].nombreOffres,
                this.dataBarCharOffre[5].nombreOffres,
                this.dataBarCharOffre[6].nombreOffres,
                this.dataBarCharOffre[7].nombreOffres,
                this.dataBarCharOffre[8].nombreOffres,
                this.dataBarCharOffre[9].nombreOffres,
                this.dataBarCharOffre[10].nombreOffres,
                this.dataBarCharOffre[11].nombreOffres
              ],
        },
      ],
      labels: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jui", "Jui", "Aou", "Sep", "Oct", "Nov", "Déc"],
      grid: {
        borderColor: '#dbdbdb',//'#343E59',
        show: false,
        padding: {
          right: 0,
          left: 0,
        },
      },
      colors:['#dbdbdb'],
      xaxis: {

        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          color: '#dbdbdb',
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          color: '#dbdbdb',
        },
        labels: {
          show: false,
          style: {
            colors: '#dbdbdb',//'#78909c',
          },
        },
      },
      title: {
        // text: 'Monthly Sales',
        align: 'left',
        style: {
          fontSize: '16px',
          color: '#dbdbdb',//#78909c',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        labels: {
          colors: '#000',
        },
      },
    };
  }


}
