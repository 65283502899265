import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private locaStorageService : LocalStorageService,
		private router: Router){
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
        console.log("can load login")
        
        return new Promise((resolve,reject)=>{
          console.log("can load login");
          
          this.locaStorageService.getItem('isLoggedIn').then(val => {
            console.log(" value "+ val);

            console.log("get Item isloggedIn :"+localStorage.getItem("isLoggedIn"));

              // if (true) {
                if (localStorage.getItem("isLoggedIn")==null) {
                console.log(" to login  ");
              resolve(true);
              }
              else {
                console.log(" to dashboard 1");
                this.router.navigateByUrl('/dashboard');
                resolve(false);
              }
            }).catch(e=>{
              console.log(" to dashboard 2");
              this.router.navigateByUrl('/dashboard');
              resolve(false);
            })
         });
  }
  
}
