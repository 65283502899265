import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
// import { AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
// import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { IUser } from 'src/app/models/entities/iuser';
// import { CanComponentDeactivate } from 'src/app/guard/can-desactivate.guard';
import { Observable } from 'rxjs';

import { NgForm } from '@angular/forms';
// import { MatPaginator } from '@angular/material/paginator';
// import { transition } from '@angular/animations';

@Component({
  selector: 'app-add-sous-categirie',
  templateUrl: './add-sous-categirie.component.html',
  styleUrls: ['./add-sous-categirie.component.scss'],
})
export class AddSousCategirieComponent implements OnInit {
  selectedValueg: string = "periode";

  telephone = "787878" ;
  role = "OPERATIONNEL"; 
  email = "@gmail.com";
  nom = "";
  pin = "0000";
  prenom = "qfs";
  referenceFichier = "";
  saved = false;
  update = false;
  submitt: string;

  
  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }

  periodess  = [
    // ADMIN, , , , UTILISATEUR
    {value: 'MANAGER', viewValue: 'Manager'},
    {value: 'FINANCIER', viewValue: 'Financier'},
    {value: 'OPERATIONNEL', viewValue: 'Opérationnel'},
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<AddSousCategirieComponent>,
  public loginService : LoginService,
  public jeveBaraService : JeveBaraService ) {
    if(data.id){
      this.submitt = "Modifier";
      this.update = true;
      console.log("id User : "+ data.id);
      
    const loading = this.loginService.loading();
    // (await loading).present();                             
      this.jeveBaraService.getBackOfficeUserById(data.id).subscribe( async (res) => {
            (await loading).dismiss();                 
      console.log("User : "+ JSON.stringify(res));

        this.telephone = res.telephone;
        this.role = res.roleUtilisateur;
        this.email = res.email  ;
        this.nom = res.nom;
        this.pin = res.pin;
        this.prenom = res.prenom;
        this.referenceFichier = res.referenceFichier;
      
      },
      async e => {
        (await loading).dismiss();                 
      });
    }else{
      this.role = "MANAGER"; 
      this.submitt = "Ajouter";
      // this.role = "Manager";
      // this.user = this.loginService.getUser();
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.telephone.length > 0 || this.role.length > 0)
        && !this.saved) { 
      return confirm('Your changes are unsaved!! Do you like to exit');
    }
    return true;
  }

  // Création des categories
  ///////////////////////
  categorie = "";
  fichier: any;
  fichierBase: any;


  file(file : any, fichierBase: any){
    console.log(file);
    console.log(this.categorie);
    this.fichier = file;
    this.fichierBase = fichierBase;
    // this.upload();
  }

  async upload(){
    this.closeDialog(true);
    console.log(this.fichier);
    const loading = this.loginService.loading();
    (await loading).present(); 
    this.jeveBaraService.uploadFile(this.fichier).subscribe(async (response) => {
    (await loading).dismiss();                 

    this.jeveBaraService.saveCategorie({"libelle" : this.categorie , "libelleSuperCategorie" : ""+this.data.libelle, "referenceFichier" : ""+response.path}).subscribe((res) => {

      // this.jeveBaraService.saveSuperCategorie({"libelle" : this.categorie, "referenceFichier" : response.path}).subscribe((res) => {
        this.loginService.showAlert("Création sous categorie","Sous catégorie créée avec succès !");
        this.jeveBaraService.addBackOffice.emit();
      }, async e => {        
        (await loading).dismiss();                 
        console.log("Erreur de modification "+(e.error.message));
        if(e.error.message == "CATEGORIE_DEJA_EN_BD"){
            
          const loading = this.loginService.loading("");
          (await loading).present();
          this.jeveBaraService.getCategorieList({page: "0",size: "1000"}).subscribe(async (res) => {
          (await loading).dismiss();                 
            res.forEach(async element => {
              if(element.libelle == this.categorie){
                if(!element.actif){
                  const loading = this.loginService.loading("");
                  (await loading).present();
                  this.jeveBaraService.activateOrDeactivateSousCategorie(element.id).subscribe(async (res) => {
                  (await loading).dismiss();                 
                      this.jeveBaraService.updateCategorie(element.id, {"libelle" : this.categorie, "referenceFichier" : response.path}).subscribe((res) => {
                      this.loginService.showAlert("Création de sous categorie", "Sous catégorie créer avec succès !");  
                      this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();  
                    });
                  },async e => {
                    (await loading).dismiss();
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  });
                }else{      
                  this.loginService.showAlert("Création de sous categorie", "Cette sous catégorie existe déjà !"); 
                  return;

                  const loading = this.loginService.loading("");
                  (await loading).present();
                  this.jeveBaraService.updateCategorie(element.id, {"libelle" : this.categorie, "referenceFichier" : response.path})
                  .subscribe(async (res) => {
                  (await loading).dismiss();                 
                    this.loginService.showAlert("Création de sous categorie", "Sous catégorie créer avec succès !");  
                    this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();  
                  },async e => {
                    (await loading).dismiss();
                    this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                  });
              }
              }
            });
          },async e => {
            (await loading).dismiss();
            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
          });
         }else{
            this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
         }
        
    });
    }, async e => {
      (await loading).dismiss();                 
    });
  }  
  ///////////////////////CATEGORIE_DEJA_EN_BD
  // formBuilder : FormBuilder;
  
  ngOnInit() {
  }

  roles = ["ADMIN", "BOSS", "GROUILLEUR"];

  onSubmit(form: NgForm) {
    this.upload();
  }
    
  closeDialog(bol: boolean){
  this.dialogRef.close(bol);
  }

}
