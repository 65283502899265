import { LoginService } from 'src/app/services/login.service';
import { IGrouilleur } from './../../models/entities/igrouilleur';
import { JeveBaraService } from './../../services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-affecter-bara',
  templateUrl: './affecter-bara.component.html',
  styleUrls: ['./affecter-bara.component.scss'],
})
export class AffecterBaraComponent implements OnInit {
  id : string = "";
  // idq : IGr = "";
  
  periodess  = [];
  
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<AffecterBaraComponent>, 
              public jeveBaraService : JeveBaraService, 
              private loginService : LoginService ) {
        const loading = this.loginService.loading();
        this.jeveBaraService.getGroulleurs({page : "0", size : "20"}).subscribe(async (res) => {
          (await loading).dismiss();                 
          this.periodess = res.data;
          this.id = res.data[0].id;
        },async e => {
          (await loading).dismiss();                 
        });
   }

   async affecter(){
      this.closeDialog(false);
      const loading = this.loginService.loading();
      (await loading).present();
      this.jeveBaraService.attribuerOffre(+this.id, +this.data.title).subscribe(async (res) => {
        (await loading).dismiss();                 
        this.loginService.showAlert("Affectation de l'offre","cette offre a été affecter avec success !");
      },async e => {
        (await loading).dismiss();                 
      });
  }

ngOnInit() {}

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}