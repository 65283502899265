import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/entities/iuser';
import { CanComponentDeactivate } from 'src/app/guard/can-desactivate.guard';
import { Observable } from 'rxjs';

import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  selectedValueg: string = "periode";

  telephone = "" ;
  role = "OPERATIONNEL"; 
  email = "";
  nom = "";
  pin = "0000";
  prenom = "";
  referenceFichier = "";
  saved = false;
  update = false;
  submitt: string;
  initUser: IUser = {};

  periodess  = [
    // ADMIN, , , , UTILISATEUR
    {value: 'MANAGER', viewValue: 'Manager'},
    {value: 'FINANCIER', viewValue: 'Financier'},
    {value: 'OPERATIONNEL', viewValue: 'Opérationnel'},
  ];

  roles = ["ADMIN", "BOSS", "GROUILLEUR"];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  public loginService : LoginService,
  public jeveBaraService : JeveBaraService ) {
    if(data.id){
      this.submitt = "Modifier";
      this.update = true;
      console.log("id User : "+ data.id);
      
     const loading = this.loginService.loading();
    // (await loading).present();
      this.jeveBaraService.getBackOfficeUserById(data.id).subscribe( async (res) => {
        (await loading).dismiss();   
        console.log("User : "+ JSON.stringify(res));
        this.initUser  = res ;
        this.telephone = res.telephone;
        this.role = res.roleUtilisateur;
        this.email = res.email  ;
        this.nom = res.nom;
        this.prenom = res.prenom;
        this.pin = res.pin;
        this.referenceFichier = res.referenceFichier;
      
      },async e => {
        (await loading).dismiss();    
        console.log("Erreur de modification "+JSON.stringify(e.error.message));
        if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
          this.loginService.showAlert("Erreur de modification", "Mot de passe pas correct !");          
        }else{ 
          if(e.error.message == "PHONE_NUMBER_ALREADY_EXISTS"){
            this.loginService.showAlert("Erreur de modification", "Ce numéro existe déjà !");   
          }else{
              this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
          }
        }
      });
    }else{ 
      this.submitt = "Ajouter";
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.telephone.length > 0 || this.role.length > 0)
        && !this.saved) { 
      return confirm('Your changes are unsaved!! Do you like to exit');
    }
    return true;
  }
  
  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.closeDialog(true);
    if(this.update){
      this.modifier(form);
      return;
    }
  }

  async modifier(form: NgForm){

    let user = {
      "prenom":form.value.prenom ? form.value.prenom : this.prenom,
      "nom": form.value.nom ? form.value.nom : this.nom,
      "role": form.value.role ? form.value.role : this.role,
      "email": form.value.email ? form.value.email : this.email,
      "telephone": form.value.telephone ? form.value.telephone : this.telephone,
      "pin": "0000"
    }; 
    
        const loading = this.loginService.loading();
        (await loading).present();
        this.jeveBaraService.updateBackOfficeUser(this.data.id, user).subscribe(async (res) => {
          (await loading).dismiss();                 
          this.loginService.showAlert("Modifier administrateur","Administrateur modifier avec succès !");          
          this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();
        },async e => {
          (await loading).dismiss();
          console.log("Erreur de modification de modification "+JSON.stringify(e.error.message));
          if(e.error.message == "PHONE_NUMBER_ALREADY_EXISTS"){
            this.loginService.showAlert("Erreur de modification", "Ce numéro existe déjà !");          
          }else{
            this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
          }
        });
  }
    
  closeDialog(bol: boolean){
  this.dialogRef.close(bol);
  }

}
