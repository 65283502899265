// <<<<<<< HEAD
import { IAvis } from './../models/entities/iavis';
import { IPromotion } from './../models/entities/ipromotion';
import { ISinistre } from './../models/entities/isinistre';
import { ITache } from './../models/entities/itache';
import { ITransaction } from './../models/entities/itransaction';
import { IUser } from './../models/entities/iuser';
import { IPositionnement } from './../models/entities/ipositionnement';
import { IBoss } from 'src/app/models/entities/iboss';
import { IOffre } from '../models/entities/ioffre';
import { ICategorie } from './../models/entities/icategorie';
import { EventEmitter, Injectable, ViewChild } from '@angular/core';

import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavigationService } from './navigation.service';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';
import { LoginService } from './login.service';
import { IGrouilleur } from '../models/entities/igrouilleur';
import { ITicket } from '../models/entities/iticket';
import { IStat } from '../models/entities/istat';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { IWallet } from '../models/entities/iwallet';
import { OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class JeveBaraService {
  public userEmitter: EventEmitter<any> = new EventEmitter<any>();
  public updateAdminEmiter: EventEmitter<any> = new EventEmitter<any>();

  public bossDetailProfil: EventEmitter<any> = new EventEmitter<any>();
  public grouillerDetailProfil: EventEmitter<any> = new EventEmitter<any>();
  public offreDetailProfil: EventEmitter<any> = new EventEmitter<any>();
  public updataBage: EventEmitter<any> = new EventEmitter<any>();
  public offreSinistre: EventEmitter<any> = new EventEmitter<any>();



  actif = new EventEmitter<any>();

  apiUrl = environment.apiUrl;
  addBackOffice = new EventEmitter<any>();
  downloadImage = new EventEmitter<any>();
  deleteOrUpdateBackOfficeUser = new EventEmitter<void>();
  positionementEmit = new EventEmitter<void>();
  imageProfil = new EventEmitter<string>();
  notif = new EventEmitter<any>();
  messageEmit = new EventEmitter<any>();
  discutionUpdate = new EventEmitter<any>();
  tacheNumberEmit = new EventEmitter<any>();
  message: any;
  image: string;

  searchBoss = new EventEmitter<any>();
  searchGrouilleur = new EventEmitter<any>();
  searchbara = new EventEmitter<any>();
  searchSinistre = new EventEmitter<any>();
  searchTransaction = new EventEmitter<any>();
  searchPromotion = new EventEmitter<any>();
  searchParametre = new EventEmitter<any>();
  searchCorbeille = new EventEmitter<any>();
  searchMessage = new EventEmitter<any>();
  traiterOffre = new EventEmitter<any>();
  bossByBara = new EventEmitter<any>();
  detailSinistre = new EventEmitter<any>();
  detailBara = new EventEmitter<any>();

  constructor(private http: HttpClient,
    private loadingController : LoadingController,
    private localStorageProvider : LocalStorageService,
    private navigationProvider :  NavigationService,
    private alertController : AlertController,
    private userService :  UserService,
    private loginService :  LoginService,
    // localStorage : LocalStorage
    ) { }

    // ADMIN, FINANCIER, MANAGER, OPERATIONNEL, UTILISATEUR
  getBossById(id : number) {

    if(true ){
          return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/${id}`,{
              },);
          }else{
            this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
          }
  }

  getAdmins(data : { page: string, size: string }) {

    if(true ){
        return this.http.get<IBoss[]>(`${this.apiUrl}api/boss/admin-all`,{
            params: {"page":data.page, "size":data.size},
          },);
        }else{
          this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
        }
  }

  getAdminById(id : number) {
    if(true){
      return this.http.get<IBoss>(`${this.apiUrl}api/boss/admin-all/${id}`,{
        },);
    }else{
      this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
    }
  }

  getTacheByType(type : any, data : { page: string, size: string }) {
      return this.http.get<any>(`${this.apiUrl}api/tache/admin-all/${type}`,{
        params: {"page":data.page, "size":data.size},
      },);
      //BARA, PAIEMENT, BADGE
  }


  getSanctionBydUser(idUser : number) {
      return this.http.get<any[]>(`${this.apiUrl}api/sanction/admin-all/${idUser}`,{
        },);
  }


  graderGrouilleur(note : any, id : number) {
    /*
    AvisArchitecteDto{
      avisArchitecte	string
      grade	string
      Enum:
      [ BON, MOYEN, TRES_BON ] note exemple
      }*/
          return this.http.put<any>(`${this.apiUrl}grouilleur/admin-all/enregistrer-avis-architecte/${id}`, note);
  }


  getAllBoss(data : { page: string, size: string }) {

        return this.http.get<any>(`${this.apiUrl}api/boss/admin-all`,{
            params: {"page":data.page, "size":data.size},
          },);
  }


  getActiveBoss(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/actif`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  getDesactiveBoss(data : { page: string, size: string }) {
      return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/non-actif`, {
          params: {"page":data.page, "size":data.size},
        },
      );
  }

  getBossFilerAlpha(data : { ordreData: string, page: string, size: string }) {
    // ASC, DESC
    return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/V2`, {
        params: {"ordreData":data.ordreData,"page":data.page, "size":data.size},
      },
    );
}

getGrouilleurFilerAlpha(data : { ordreData: string, page: string, size: string }) {
  // ASC, DESC
  return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/V2`, {
      params: {"ordreDATA":data.ordreData,"page": +data.page, "size": +data.size},
    },
  );
}

  nbp : Number = 0;
  mobile : boolean = false;

  public grouileur : IGrouilleur[] = [] ;


  public offre :  IOffre[] = [] ;
  public returnOffre : MatTableDataSource<IOffre>;

  getGrouilleurById(id: number) {

          return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/${id}`,{
              },);
  }


  getGroulleurs(data : { page: string, size: string }) {

    return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all`,{
        params: {"page":data.page, "size":data.size},
      });
}

getNbUserByCanal(canal : string) {
  // BOUCHE_A_OREILLE, COMMERCIAL_TERRAIN, FACEBOOK, LINKEDIN, PANNEAU_PUBLICITAIRE, PRESSE, TV
  return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin-all/get-Numbers-of-Users-By-CanalReference`,{
      params: {"connuJeVeBara":canal},
    });
}

  getGrouilleurDetails(id: number) {

          return this.http.get<IGrouilleur>(`${this.apiUrl}api/grouilleur/admin-all/${id}`,{
              },);
  }

  getActiveGrouilleur(data : { page: string, size: string }) {

        return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/actif`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  getDesactiveGrouilleur(data : { page: string, size: string }) {

      return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/non-actif`,{
          params: {"page":data.page, "size":data.size},
        },);
  }

  public activeteOrDesactivateUser(idUtilisateur : number) {

          this.userEmitter.emit(null);
          this.userEmitter.next(null);
          return this.http.get(`${this.apiUrl}api/utilisateur/admin/activer-desactiver/${idUtilisateur}`,{
              },);
  }

  public activeteaGrouilleur(idGrouilleur : number) {
          return this.http.put(`${this.apiUrl}api/grouilleur/admin-all/activer/${idGrouilleur}`,null);
  }

  public desactivateGrouilleur(idGrouilleur : number) {
          return this.http.put(`${this.apiUrl}api/grouilleur/admin-all/desactiver/${idGrouilleur}`,null);
  }

  public getBackOfficeUserById(idUtilisateur : number) {

          this.userEmitter.emit(null);
          this.userEmitter.next(null);
          return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin-all/${idUtilisateur}`,{
              },);
  }

  registerBoss(data : IBoss) {

    if(true){
          return this.http.post<any>(`${this.apiUrl}api/utilisateur/register-boss`,data, {
              },).subscribe(res=>{
                console.log(res);
              });
          }else{
            this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
          }
  }

  registerGrouilleur(data : IGrouilleur) {

    if(true){
          return this.http.post<any>(`${this.apiUrl}statEvent`, data, {
              },);
          }else{
            this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
          }
  }

  /// 25/02/2022
  getValidateOffres(data : { page: string, size: string }) {

        return this.http.get<any>(`${this.apiUrl}api/offre`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  getOffres(data : { page: string, size: string }) {
          return this.http.get<any>(`${this.apiUrl}api/offre/admin-all`,{
              params: {"page":data.page, "size":data.size},
            },);
  }


  reserverOffre(id : number) {

      return this.http.put<any>(`${this.apiUrl}api/offre/admin-all/reserver/${id}`,null );
  }


  getPositionnement(idOffre : number, data : { page: string, size: string }) {

        return this.http.get<any>(`${this.apiUrl}api/positionnement/${idOffre}`,{
            params: {"page":data.page, "size":data.size},
          },);
  }


  refuserPositionnement(idPositionnement : number) {

      return this.http.put<any>(`${this.apiUrl}api/positionnement/refuser/${idPositionnement}`, null);
  }


  getGrouilleurPositionnement(idGrouilleur : number, data : { page: string, size: string }) {

        return this.http.get<any>(`${this.apiUrl}api/positionnement/admin-all/grouilleur/${idGrouilleur}`,{
            params: {"page":data.page, "size":data.size},
          },);
  }


  getOffrePositionnement(idOffre : number, data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/positionnement/admin-all/${idOffre}`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  getBossByNbOffre(data : {ordreData: string, page: string, size: string }) {
    // DESC  ASC
    return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/V2/list-boss-par-nombre-bara`,{
        params: {"page":data.page, "size":data.size, "ordreData":data.ordreData},
      },);
}

getBossByNbPositionnement(data : {ordreData: string, page: string, size: string }) {
  // DESC  ASC
  return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/V2/list-grouilleur-par-nombre-positionnement`,{
      params: {"page":data.page, "size":data.size, "ordreData":data.ordreData},
    },);
}

  // api/positionnement/admin-all/{idOffre}
  id : number;
  idSnistre : number = null;
  bossId:number = null;
  grouilleurId:number = null;
  offreId : number = null;
  validerPositionnement(idPositionnement : number) {

      return this.http.put<any>(`${this.apiUrl}api/positionnement/valider/${idPositionnement}`,null);
  }

  uploadFile(event : any) {

    const formData = new FormData();
    const file : File = event;
    if (file) {
        formData.append("file", file);
          return this.http.post<any>(`${this.apiUrl}api/file`, formData);
    }

  }

  uploadFileBadge(event : any) {
    return this.http.post<any[]>(`${this.apiUrl}api/file/list`, event);
  }

  saveManyBadges(paths : any, type : string, idG : number) {

    const formData = {
      "fichierJoints": paths,
      "typeBadge": type
    };
    // /api/badge/admin-all/{idGrouilleur}
      return this.http.post<any>(`${this.apiUrl}api/badge/admin-all/${idG}`, formData);
  }

  updateBadge(paths : any, type : string, idB : number) {

    const formData = {
      "fichierJoints": paths,
      "typeBadge": type
    };
      return this.http.put<any>(`${this.apiUrl}api/badge/admin-all/${idB}`, formData);
  }



  validerOffre(id : number) {
      return this.http.put<any>(`${this.apiUrl}api/offre/admin-all/valider/${id}`, null);
  }

  annulerOffre(idOffre : number) {
    return this.http.put<any>(`${this.apiUrl}api/offre/annuler/${idOffre}`, null);
  }

  terminerOffre(idOffre : number) {
    return this.http.put<any>(`${this.apiUrl}api/offre/admin-all/terminer/${idOffre}`, null);
  }



  refuserOffre(id : number) {

      return this.http.put<any>(`${this.apiUrl}api/offre/admin-all/refuser/${id}`, null);
    }

    attribuerOffre(idGrouilleur : number, idOffre : number) {

      if(true){

        return this.http.post<any>(`${this.apiUrl}api/offre/operationnel/attribuer-grouilleur/${idGrouilleur}/${idOffre}`, {distance: 0, montant: 0,
                                                  montantCaution: 0 },
          {responseType : "json",});
        }else{
          this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
        }
    }

  getOffreById(id: string) {
      return this.http.get<any>(`${this.apiUrl}api/offre/${id}`);
  }


  createOffre(data : IOffre) {

      return this.http.post<any>(`${this.apiUrl}api/offre`, data);
  }

  invaliderBages(id : number) {
        return this.http.put<any>(`${this.apiUrl}api/badge/admin-all/invalider/${id}`, null);
  }
  validerBages(id : number) {
        return this.http.put<any>(`${this.apiUrl}api/badge/admin-all/valider/${id}`, null);
  }

  supprimerBages(idB : number) {
    return this.http.delete<any>(`${this.apiUrl}api/badge/${idB}`);
  }

  exporterGrouilleur() {
    const url = this.apiUrl+'api/V2/grouilleur/test/export';
    window.open( url, "_blank");
  }

  exporterBoss() {
    const url = this.apiUrl+'api/boss/admin-all/export';
    window.open( url, "_blank");
  }

  openFile(fileReference : string) {

      return this.http.get<any>(`${this.apiUrl}api/file/${fileReference }`);
  }

  registerUser(data : any) {

    if(true){
      return this.http.post<any>(`${this.apiUrl}api/utilisateur/admin-all/add`, data);
    }else{
      this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
    }
  }

  updateCurentAdmin(data : any) {
    this.updateAdminEmiter.emit(data);

      return this.http.put<any>(`${this.apiUrl}api/utilisateur/admin-all/update-profil`, data);
  }


  resetPasswordOfCurrentAdmin(data : {ancienPin	: string, nouveauPin	: string }) {

      return this.http.put<any>(`${this.apiUrl}api/utilisateur/admin-all/change-password`, data);
  }

  ///////////////
  updateSanctionWithIdOfSanction(idSanction :number, data : {dateDebut	: string, dateFin	: string, motif	: string }) {

    return this.http.put<any>(`${this.apiUrl}api/sanction/admin-all/${idSanction}`, data);
  }

getDiscutionByIdPositionnement(data : {idPositionnement	: string, page	: string, size	: string }) {
  return this.http.get<any>(`${this.apiUrl}api/message-user/discussions-by-id-positionnement`,{
    params: {"page":data.page, "size":data.size, "idPositionnement":data.idPositionnement},
  },);
}


  listerSanctionWithIdOfUser(idUser :number) {
      return this.http.get<any>(`${this.apiUrl}api/sanction/admin-all/${idUser}`);
  }


  saveUserSanction(idUser :number, data : {dateDebut: string, dateFin: string, motif :string} ) {
      return this.http.post<any>(`${this.apiUrl}api/sanction/admin-all/${idUser}`, data);
  }


  activerDesactiverSanction(idSanction :number) {
        return this.http.put<any>(`${this.apiUrl}api/sanction/admin-all/activer-desactiver/${idSanction}`,null);
  }

  //////
  //////
  // getAllTache(data : { page: string, size: string }) {

  //   if((this.loginService.user.role == "ADMIN") ||
  //   (this.loginService.user.role == "MANAGER" ) ){
  //       return this.http.get<any>(`${this.apiUrl}api/tache`,{
  //           params: {"page":data.page, "size":data.size},
  //         },);
  //       }else{
  //         this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
  //       }
  // }

  getAllTache(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/tache`,{
            params: {"page":data.page, "size":data.size},
          },);
  }
  // STAUT : NON_TRAITER, REFUSER, VALIDER
  getAllMyTache(data : { page: string, size: string, statut:string }) {
        return this.http.get<any>(`${this.apiUrl}api/tache/mes-taches`,{
            params: {"page":data.page, "size":data.size, "statut":data.statut},
          },);
  }

  getTacheNonAssignerSelonStatut(data : { page: string, size: string, statut:string }) {
        return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
            params: {"page":data.page, "size":data.size, "statut":data.statut},
          },);
  }

  getTacheAssignerSelonStatut(data : { page: string, size: string, statut:string }) {
        return this.http.get<any>(`${this.apiUrl}api/tache/selon-statut`,{
            params: {"page":data.page, "size":data.size, "statut":data.statut},
          },);
  }

  attribuerTache(idTache :number, idUser : number ) {
      if(true){
        return this.http.put<any>(`${this.apiUrl}api/tache/manager/attribuer-tache/${idTache}/${idUser}`,null);
      }else{
        this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
      }
  }

  nonTraiteTache(idTache :number) {
        return this.http.put<any>(`${this.apiUrl}api/tache/liberer-tache/${idTache}`,null);
  }

  getTacheByid(idTache :number) {
        return this.http.get<any>(`${this.apiUrl}api/tache/${idTache}`);
  }

  refuserTache(idTache :number) {
        return this.http.put<any>(`${this.apiUrl}api/tache/refuser-tache/${idTache}`,null);
  }

  selectionnerTache(idTache :number) {
        return this.http.put<any>(`${this.apiUrl}api/tache/selectionner-tache/${idTache}`,null);
  }

  traiterTache(idTache :number) {
         return this.http.put<any>(`${this.apiUrl}api/tache/valider-tache/${idTache}`,null);
        //  return this.http.put<any>(`${this.apiUrl}api/tache/traiter-tache/${idTache}`,null);
  }

  getAllTransaction(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  getTransactionByid(idTransaction : number) {
        return this.http.get<ITransaction>(`${this.apiUrl}api/transaction/admin-all/${idTransaction}`);
  }



  creditOrDebitCagnote(idGrouilleur : number, transaction : {"montant": number,"motif": string,"moyenOperation": string,
                                                      "numeroTransaction": string,"typeOperation": string
                                                    }) {
    return this.http.post<any>(`${this.apiUrl}api/transaction/financier/crediter-debiter-cagnote/${idGrouilleur}`,transaction);
  }

  creditOrDebitCaution(idGrouilleur : number, transaction : {"montant": number,"motif": string,"moyenOperation": string,
                                                      "numeroTransaction": string,"typeOperation": string
                                                    }) {
        return this.http.post<any>(`${this.apiUrl}api/transaction/financier/crediter-debiter-caution/${idGrouilleur}`,transaction);
  }


  creditOrDebitCagnoteBoss(idBoss : number, transaction : {"montant": number,"motif": string,"moyenOperation": string,
                                                      "numeroTransaction": string,"typeOperation": string
                                                    }) {
    return this.http.post<any>(`${this.apiUrl}api/transaction/financier/crediter-debiter-credit-boss/${idBoss}`,transaction);
  }

  // creditOrDebitCaution(idGrouilleur : number, transaction : {"montant": number,"motif": string,"moyenOperation": string,
  //                                                     "numeroTransaction": string,"typeOperation": string
  //                                                   }) {
  //       return this.http.post<any>(`${this.apiUrl}api/transaction/financier/crediter-debiter-caution/${idGrouilleur}`,transaction);
  // }

  effectuerTransaction(idTransaction : number) {

    if((this.loginService.user.role == "ADMIN") ||
    (this.loginService.user.role == "FINANCIER" ) ){
        return this.http.put<any>(`${this.apiUrl}api/transaction/financier/effectuer-transaction/${idTransaction}`,null);
        }else{
          this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
        }
  }

  annulerTransactionByid(idTransaction : number) {
        return this.http.put<ITransaction>(`${this.apiUrl}api/transaction/financier/annuler-transaction/${idTransaction}`,null);
  }


  getAllBackOfficeUser(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin-all`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  getAllSinistre(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/sinistre/admin-all`,{
            params: {"page":data.page, "size":data.size},
          },);
  }
  getSinistreById(idSnistre : number) {
        return this.http.get<ISinistre>(`${this.apiUrl}api/sinistre/${idSnistre}`);
  }

  createSinistre(idOffre : number) {
        return this.http.post<any>(`${this.apiUrl}api/sinistre/${idOffre}`,null);
  }

  debuterTraitement(idSnistre : number) {
        return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/debuter-traitement/${idSnistre}`,null);
  }

  nonResoudreSinistre(idSnistre : number) {
        return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/non-resoudre/${idSnistre}`,null);
  }


  resoudreSinistre(idSnistre : number) {
        return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/resoudre/${idSnistre}`,null);
  }


  getBaraOfBossId(idBoss : number , data : { page: string, size: string }) {
      return this.http.get<any>(`${this.apiUrl}api/offre/admin-all/by-boss/${idBoss}`,{
          params: {"page":data.page, "size":data.size},
        },);
  }
  getMySinistre(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/sinistre/mes-sinistres`,{
            params: {"page":data.page, "size":data.size},
          },);
  }


  /**
   * Upload files
   * @param filePaths paths of files
   * @returns
   */
   uploadFiles(filePaths:string[] ){
    // return from(this.getMultipleFiles(filePaths)).pipe(switchMap(medias =>{
    //     const formData = new FormData();


    //     for (let media of medias) {
    //       formData.append("files", media.blob, media.file.name);
    //     }

    //     return this.http.post<IMediaGallery[]>(`${this.apiUrl}/file/list`,formData);
    //   }));
  }

  promotionEmit = new EventEmitter<void>();
  /**
   * new Update
   */
   createPromotion(promotion : {
    "cible": string,
    "code": string,
    "dateDebut": string,
    "dateFin": string,
    "libelle": string,
    "regle": string,
    "taux": boolean,
    "valeur": number,
} ){
this.promotionEmit.emit();
return this.http.post<any>(`${this.apiUrl}api/promotion`, promotion);
}

  getAllPromtion(data : { page: string, size: string }) {

      return this.http.get<any>(`${this.apiUrl}api/promotion`,{
            params: {"page":data.page, "size":data.size},
          },);
  }


  rendredDisponileCategorie(idCat : number, cat : any)
  {
        return this.http.put<any>(`${this.apiUrl}api/V2/super-categorie/admin-all/${idCat}`,cat);
  }


  updateSuperCategorie(idCat : number, cat : any){
     return this.http.put<any>(`${this.apiUrl}api/V2/super-categorie/admin-all/${idCat}`, cat);
  }


  activateOrDeactivateSuperCategorie(idCat : number){
        return this.http.put<any>(`${this.apiUrl}api/V2/super-categorie/admin-all/activer-desactiver/${idCat}`,null);
  }

  getSuperCategorieList(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/V2/super-categorie/admin-all`,{
            params: {"page":data.page, "size":data.size},
          },);
  }

  // searchSuperCategorieList(data : { page: string, size: string, query: string }) {

  //     return this.http.get<ICategorie[]>(`${this.apiUrl}api/categorie`,{
  //       params:{"page":data.page, "size":data.size, "query":data.query
  //     }});
  // }


  saveSuperCategorie(categorie : any) {
      return this.http.post<any>(`${this.apiUrl}api/V2/super-categorie/admin-all`,categorie);
  }



  getAllSuperCategorie() {
      return this.http.get<any[]>(`${this.apiUrl}api/V2/categorie`);
  }

///////////////////////
  updateCategorie(idCat : number, cat : any)
  {
        return this.http.put<any>(`${this.apiUrl}api/categorie/admin-all/${idCat}`,cat);
  }


  activateOrDeactivateCategorie(idCat : number){
    return this.http.put<any>(`${this.apiUrl}api/V2/super-categorie/admin-all/activer-desactiver/${idCat}`,null);
  }


  activateOrDeactivateSousCategorie(idCat : number){
    return this.http.put<any>(`${this.apiUrl}api/categorie/admin-all/activer-desactiver/${idCat}`,null);
  }

getOffreByStatut(data : { page: string, size: string, statut : string }){
      return this.http.get<any>(`${this.apiUrl}api/offre/mes-offres-selon-statut/${data.statut}`,{
        params:{"page":data.page, "size":data.size}
      });
}

getCategorieList(data : { page: string, size: string }) {

      return this.http.get<any>(`${this.apiUrl}api/categorie/admin-all`,{
          params: {"page":data.page, "size":data.size},
        },);
}

// getSuperCategorieList(data : { page: string, size: string }) {

//       return this.http.get<any>(`${this.apiUrl}api/V2/super-categorie/admin-all`,{
//           params: {"page":data.page, "size":data.size},
//         },);
// }

  searchCategorieList(data : { page: string, size: string, query: string }) {

      return this.http.get<ICategorie[]>(`${this.apiUrl}api/categorie`,{
        params:{"page":data.page, "size":data.size, "query":data.query}
      });
  }


  saveCategorie(categorie) {
      return this.http.post<any>(`${this.apiUrl}api/categorie/admin-all/V2/`,categorie);
  }



  getAllCategorie() {

      return this.http.get<ICategorie[]>(`${this.apiUrl}api/categorie`);
  }


  activateOrDeactivategrouilleur(idG : number){
      return this.http.put<any>(`${this.apiUrl}api/grouilleur/admin-all/activer/${idG}`,null);
  }



  getTransactionDetail(idTransaction : number){
        return this.http.get<ITransaction>(`${this.apiUrl}api/transaction/${idTransaction}`,null);
  }



  updateBackOfficeUser(idUser : number, user : any){
    // this.loginService.showAlert("User to upadate", JSON.stringify(user));
       return this.http.put<any>(`${this.apiUrl}api/utilisateur/admin-all/update/${idUser}`,user);
  }

  activateOrDeactivateBackOfficeUser(idUser : number){
        this.tacheEmit.emit();
        return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin/activer-desactiver/${idUser}`);
  }

  tacheEmit = new EventEmitter<any>();

  getFreeTache(data : { page: string, size: string }) {
        return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
            params: {"page":data.page, "size":data.size},
          });
  }

  getAvisByIdGrouilleur(idGrouilleur : number){
        return this.http.get<any[]>(`${this.apiUrl}api/avis/V2/${idGrouilleur}`);

  }

  getOffrestat(idAnne : number){
        return this.http.get<{nombreOffres: number,numMois: number}[]>(`${this.apiUrl}api/offre/nombre-par-mois/${idAnne}`);
  }

  getChiffreAffaireParMois(anne : number){
        return this.http.get<{montant: number,numMois: number}[]>(`${this.apiUrl}api/statistiques/chiffre-affaire-par-mois/${anne}`);
  }

  getMoyenneOffreparJour(anne : number){
        return this.http.get<{valeur: number}>(`${this.apiUrl}api/statistiques/moyenne-offre-par-jour/${anne}`);
  }

  getMoyenneUtilisateurparJour(anne : number){
        return this.http.get<{valeur: number}>(`${this.apiUrl}api/statistiques/moyenne-utilisateur-par-jour/${anne}`);
  }

  getMoyenneOffreparMois(anne : number){
    return this.http.get<{nombreOffres: number, numMois: number}[]>(`${this.apiUrl}api/statistiques/nombre-offres-par-mois/${anne}`);
  }

  deleteRealisation(idRealisation : number){
    return this.http.delete<any>(`${this.apiUrl}api/realisation/${idRealisation}`);
  }

  invaliderRealisation(idRealisation : number){
    return this.http.put<any>(`${this.apiUrl}api/realisation/admin-all/invalider/${idRealisation}`, null);
  }

  validerRealisation(idRealisation : number){
    return this.http.put<any>(`${this.apiUrl}api/realisation/admin-all/valider/${idRealisation}`, null);
  }

  saveWalet(idGrouilleur : number, wallet : any){
    return this.http.post<any>(`${this.apiUrl}api/wallet/admin-all/${idGrouilleur}`, wallet);
  }

  updateWalet(idWallet : number, wallet : IWallet){
    return this.http.put<any>(`${this.apiUrl}api/wallet/${idWallet}`, wallet);
  }

  deleteWalet(idWallet : number){
    return this.http.delete<any>(`${this.apiUrl}api/wallet/${idWallet}`);
  }

  getWaletByIdgrouilleur(idGrouilleur : number){
    return this.http.get<IWallet[]>(`${this.apiUrl}api/wallet/admin-all/${idGrouilleur}`);
  }

  getPromotionById(idprom : number){
    return this.http.get<IPromotion>(`${this.apiUrl}api/promotion/${idprom}`);
  }

  updatePromotionById(idprom : number, prom : any){
    return this.http.put<any>(`${this.apiUrl}api/promotion/${idprom}`, prom);
  }

  deletePromotionById(idprom : number){
    return this.http.put<any>(`${this.apiUrl}api/promotion/activer-desactiver/${idprom}`, null);
  }

  saveMessagePourAdmin(message : any){
    return this.http.post<any>(`${this.apiUrl}api/message-admin`, message);
  }

  deleteMessagePourAdmin(id : number){
    return this.http.delete<any>(`${this.apiUrl}api/message-admin/${id}`);
  }

  getDiscution(nombreDiscussion : number){
    return this.http.get<any>(`${this.apiUrl}api/message-admin/admin-all?nombreDiscussion=${nombreDiscussion}`);
  }

  getPaginateDiscutionFormUser(data : {page	: string, size	: string }) {
    //                                          api/V2/message-admin/admin-all/discussion
    return this.http.get<any>(`${this.apiUrl}api/V2/message-admin/admin-all/discussion`,{
      params: {"page":data.page, "size":data.size},
    },);
  }

  getPaginateMessageFormUser(idUser : number ,data : {pageNumber	: string, pageSise	: string }) {
    return this.http.get<any>(`${this.apiUrl}api/V2/message-admin/admin-all/discussion/`+idUser,{
      params: {"pageNumber":data.pageNumber, "pageSise":data.pageSise},
    },);
  }

  getPaginateDiscution(data : {pageNumber: string, pageSize: string }){
    return this.http.get<any>(`${this.apiUrl}api/V2/message-admin/admin-all`,{
      params: {"pageNumber":data.pageNumber, "pageSise":data.pageSize},
    });
  }

  sendMessageToUser(message : any){
    return this.http.post<any>(`${this.apiUrl}api/message-admin/admin-all/select-users`, message);
  }

  sendDiffusionMessage(message : any){
    return this.http.post<any>(`${this.apiUrl}api/message-admin/admin-all/all-users`, message);
  }

  lireNouveauMessage(idUser : any){
    return this.http.put<any>(`${this.apiUrl}api/message-admin/admin-all/lire-messages/${idUser}`, null);
  }

  NouveauMessage(){
    return this.http.get<{"valeur" : number}>(`${this.apiUrl}api/message-admin/admin-all/nombre-nouveaux-messages`);
  }

  getListMessageFromUser(idUser : number){
    return this.http.get<any>(`${this.apiUrl}api/message-admin/admin-all/by-user/${idUser}`);
  }

  // {
  //   "date": "string",
  //   "message": "string"
  // }
  getMessageFromUser(typeProfil : String, data : { page: string, size: string }){
    return this.http.get<any>(`${this.apiUrl}api/message-admin/selon-type-profil/${typeProfil}`,{
      params: {"page":data.page, "size":data.size},
    });
  }

  sendSmsToBoss(userType : String, data : { date: string, message: string }){
    return this.http.post<any>(`${this.apiUrl}api/sms/admin-all/sms/${userType}/send-all/`, data);
  }

  getDiscutionNonLuGrouilleur(data : { page: string, size: string }){
    return this.http.get<any>(`${this.apiUrl}api/V2/message-user/lister-messages-non-lus-grouilleur`,{
      params: {"page":data.page, "size":data.size},
    });
  }

  getDiscutionNonLuBoss(data : { page: string, size: string }){
    return this.http.get<any>(`${this.apiUrl}api/V2/message-user/lister-messages-non-lus-boss`,{
      params: {"page":data.page, "size":data.size},
    });
  }

rechercheGrouilleur(data : { page: string, query: string, size: string }){
  return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/search`,{
    params: {"page":data.page, "size":data.size, "query":data.query},
  });
}

rechercheOffre(data : { page: string, query: string, size: string }){
  return this.http.get<any>(`${this.apiUrl}api/offre/V2/search`,{
    params: {"page":data.page, "size":data.size, "query":data.query},
  });
}

rechercheBoss(data : { page: string, query: string, size: string }){
  return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/search`,{
    params: {"page":data.page, "size":data.size, "query":data.query},
  });
}

rechercheDiscutionByNomPrenom(query: string){//api/message-admin/admin-all/search
  return this.http.get<any>(`${this.apiUrl}api/message-admin/admin-all/search`,{
    params: {"query": query},
  });
}

getSousCatByCatId(idCat: number){
  return this.http.get<any[]>(`${this.apiUrl}api/V2/categorie/lister-sous-categorie/${idCat}`);
  // return this.http.get<any[]>(`${this.apiUrl}api/V2/sous-categorie/sous-categorie/${idCat}`);
}

exportTransaction(periode : string){
  const url = this.apiUrl+`api/transaction/admin-all/test-export/${periode}`;
  window.open( url, "_blank");
}

getSinistreBuyStatut(satut : string){
  //  ATTENTE, EN_TRAITEMENT, NON_RESOLU, RESOLU
  return this.http.get<any[]>(`${this.apiUrl}api/sinistre/admin-all/sinitre-selon-statut/${satut}`);
}


rechercheGeneralSisnistre(data : { page: string, size: string, query: string }) {
  return this.http.get<any>(`${this.apiUrl}api/sinistre/V2/search`,{
    params: {"page":data.page, "size":data.size, "query":data.query},
  });
}

rechercheSisnistreParPeriode(periode : string) {
  // ANNEE_EN_COURS, JOUR_EN_COURS, MOIS_EN_COURS
  return this.http.get<any>(`${this.apiUrl}api/sinistre/admin-all/listSinistreByPeriode/${periode}`);
}


rechercheGeneralTransaction(data : { page: string, size: string, query: string }) {
  return this.http.get<any>(`${this.apiUrl}api/sinistre/V2/search`,{
    params: {"page":data.page, "size":data.size, "query":data.query},
  });
}

rechercheTransactionParTypeOperation(TypeOperation : string) {
  // DEBIT ou CREDIT
  return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all/searchByTypeOperation/${TypeOperation}`);
}

rechercheTransactionParStatut(statut : string) {
  // ANNULER, EFFECTUER, EN_ATTENTE
  return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all/searchByStatut/${statut}`);
}

rechercheTransactionParValeurAndIdOrDescription(data : { page: string, size: string, ValeurAndIdOrDescription: string }) {
  return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all/V2/search`,{
    params: {"page":data.page, "size":data.size, "IdOrValeur" : data.ValeurAndIdOrDescription },
  });;
}

rechercheTransactionParSolde(solde : string) {
  // CAGNOTTE, CAGNOTTE_CAUTION, CAUTION, CREDIT
  return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all/searchByTypeSolde/${solde}`);
}

rechercheTransactionParMontant(montant : number) {
  return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all/searchByValeur/${montant}`);
}

rechercheTransactionParMontantDesc() {
  return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all/searchByValeurDesc`);
}

rechercheTacheParIdLibelleNomPrenomEmetteur(data : { page: string, size: string, IdLibelleNomPrenomEmetteur: string }) {
  return this.http.get<any>(`${this.apiUrl}api/tache/admin-all/V2/search`,{
    params: {"page":data.page, "size":data.size, "parametre" : data.IdLibelleNomPrenomEmetteur },
  });
}


traiterSinistre(data : { commentaire: string, idSinistre: number }) {
  return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/traiter-sinistre`,data);
}

//   getFreeTache(data : { page: string, size: string }) { api/transaction/admin-all/searchByValeur/
//     return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
//         params: {"page":data.page, "size":data.size},
//       });
// }

//   getFreeTache(data : { page: string, size: string }) {
//     return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
//         params: {"page":data.page, "size":data.size},
//       });
// }
// NON_TRAITER, REFUSER, VALIDER
  getTacheByStatut(data : { page: string, size: string, statut: string }){
    return this.http.get<any>(`${this.apiUrl}api/tache/selon-statut`,{
      params: {"page":data.page, "size":data.size, "statut":data.statut},
    });
  }

}
// =======
// import { IAvis } from './../models/entities/iavis';
// import { IPromotion } from './../models/entities/ipromotion';
// import { ISinistre } from './../models/entities/isinistre';
// import { ITache } from './../models/entities/itache';
// import { ITransaction } from './../models/entities/itransaction';
// import { IUser } from './../models/entities/iuser';
// import { IPositionnement } from './../models/entities/ipositionnement';
// import { IBoss } from 'src/app/models/entities/iboss';
// import { IOffre } from '../models/entities/ioffre';
// import { ICategorie } from './../models/entities/icategorie';
// import { EventEmitter, Injectable, ViewChild } from '@angular/core';

// import { tap, catchError, map, switchMap } from 'rxjs/operators';
// import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
// import { AlertController, LoadingController } from '@ionic/angular';
// import { Router } from '@angular/router';
// import { NavigationService } from './navigation.service';
// import { UserService } from './user.service';
// import { LocalStorageService } from './local-storage.service';
// import { LoginService } from './login.service';
// import { IGrouilleur } from '../models/entities/igrouilleur';
// import { ITicket } from '../models/entities/iticket';
// import { IStat } from '../models/entities/istat';
// import { MatTableDataSource } from '@angular/material/table';
// import { MatPaginator } from '@angular/material/paginator';
// import { Observable } from 'rxjs';
// import { IWallet } from '../models/entities/iwallet';


// @Injectable({
//   providedIn: 'root'
// })
// export class JeveBaraService {
//   public userEmitter: EventEmitter<any> = new EventEmitter<any>();
//   public updateAdminEmiter: EventEmitter<any> = new EventEmitter<any>();

//   public bossDetailProfil: EventEmitter<any> = new EventEmitter<any>();
//   public grouillerDetailProfil: EventEmitter<any> = new EventEmitter<any>();
//   public offreDetailProfil: EventEmitter<any> = new EventEmitter<any>();
//   public updataBage: EventEmitter<any> = new EventEmitter<any>();


//   actif = new EventEmitter<any>();

//   apiUrl = environment.apiUrl;
//   addBackOffice = new EventEmitter<any>();

//   deleteOrUpdateBackOfficeUser = new EventEmitter<void>();
//   positionementEmit = new EventEmitter<void>();
//   imageProfil = new EventEmitter<string>();
//   notif = new EventEmitter<any>();
//   messageEmit = new EventEmitter<any>();
//   discutionUpdate = new EventEmitter<any>();
//   tacheNumberEmit = new EventEmitter<any>();

//   constructor(private http: HttpClient,
//     private loadingController : LoadingController,
//     private localStorageProvider : LocalStorageService,
//     private navigationProvider :  NavigationService,
//     private alertController : AlertController,
//     private userService :  UserService,
//     private loginService :  LoginService,
//     // localStorage : LocalStorage
//     ) { }

//     // ADMIN, FINANCIER, MANAGER, OPERATIONNEL, UTILISATEUR
//   getBossById(id : number) {

//     if(true ){
//           return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/${id}`,{
//               },);
//           }else{
//             this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//           }
//   }

//   getAdmins(data : { page: string, size: string }) {

//     if(true ){
//         return this.http.get<IBoss[]>(`${this.apiUrl}api/boss/admin-all`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//         }else{
//           this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//         }
//   }

//   getAdminById(id : number) {

//     if(true){
//           return this.http.get<IBoss>(`${this.apiUrl}api/boss/admin-all/${id}`,{
//               },);
//           }else{
//             this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//           }
//   }


//   graderGrouilleur(note : any, id : number) {
//     /*
//     AvisArchitecteDto{
//       avisArchitecte	string
//       grade	string
//       Enum:
//       [ BON, MOYEN, TRES_BON ] note exemple
//       }*/
//           return this.http.put<any>(`${this.apiUrl}grouilleur/admin-all/enregistrer-avis-architecte/${id}`, note);
//   }


//   getAllBoss(data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/boss/admin-all`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }


//   getActiveBoss(data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/boss/actif`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   getDesactiveBoss(data : { page: string, size: string }) {

//       return this.http.get<any>(`${this.apiUrl}api/boss/admin-all/non-actif`, {
//           params: {"page":data.page, "size":data.size},
//         },
//       );
//   }

//   nbp : Number = 0;

//   public grouileur : IGrouilleur[] = [] ;


//   public offre :  IOffre[] = [] ;
//   public returnOffre : MatTableDataSource<IOffre>;
//   // public getOffre(i : any = 0) : MatTableDataSource< IOffre> {
//   //   this.offre = [];
//   //   this.getOffres({page : '0', size : '100'}).subscribe( (res) => {
//   //     this.nbp = res.totalPages;
//   //     for(i=0; i<this.nbp; i++){
//   //        this.getOffres({page : ''+i , size : '100'}).subscribe( (res) => {
//   //         // console.log("OK "+res);
//   //         this.offre = this.offre.concat(res.data);
//   //         this.returnOffre = new MatTableDataSource<IOffre>(this.offre);
//   //         // this.returnOffre.paginator = this.paginator;
//   //         // console.log('tableau de offre '+ JSON.stringify(this.returnOffre));
//   //         // console.log('tableau de offre '+ JSON.stringify(this.offre));
//   //       });
//   //     }
//   //   });
//   //   return this.returnOffre;
//   // }

//   getCategorieList(data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/categorie`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   searchCategorieList(data : { page: string, size: string, query: string }) {

//       return this.http.get<ICategorie[]>(`${this.apiUrl}api/categorie`,{
//         params:{"page":data.page, "size":data.size, "query":data.query
//       }});
//   }

//   getGrouilleurById(id: number) {

//           return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/${id}`,{
//               },);
//   }


//   getGroulleurs(data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   getGrouilleurDetails(id: number) {

//           return this.http.get<IGrouilleur>(`${this.apiUrl}api/grouilleur/admin-all/${id}`,{
//               },);
//   }

//   getActiveGrouilleur(data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/actif`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   getDesactiveGrouilleur(data : { page: string, size: string }) {

//       return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin/non-actif`,{
//           params: {"page":data.page, "size":data.size},
//         },);
//   }

//   public activeteOrDesactivateUser(idUtilisateur : number) {

//           this.userEmitter.emit(null);
//           this.userEmitter.next(null);
//           return this.http.get(`${this.apiUrl}api/utilisateur/admin/activer-desactiver/${idUtilisateur}`,{
//               },);
//   }

//   public getBackOfficeUserById(idUtilisateur : number) {

//           this.userEmitter.emit(null);
//           this.userEmitter.next(null);
//           return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin-all/${idUtilisateur}`,{
//               },);
//   }

//   registerBoss(data : IBoss) {

//     if(true){
//           return this.http.post<any>(`${this.apiUrl}api/utilisateur/register-boss`,data, {
//               },).subscribe(res=>{
//                 console.log(res);
//               });
//           }else{
//             this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//           }
//   }

//   registerGrouilleur(data : IGrouilleur) {

//     if(true){
//           return this.http.post<any>(`${this.apiUrl}statEvent`, data, {
//               },);
//           }else{
//             this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//           }
//   }

//   /// 25/02/2022
//   getValidateOffres(data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/offre`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   getOffres(data : { page: string, size: string }) {
//           return this.http.get<any>(`${this.apiUrl}api/offre/admin-all`,{
//               params: {"page":data.page, "size":data.size},
//             },);
//   }


//   reserverOffre(id : number) {

//       return this.http.put<any>(`${this.apiUrl}api/offre/admin-all/reserver/${id}`,null );
//   }


//   getPositionnement(idOffre : number, data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/positionnement/${idOffre}`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }


//   refuserPositionnement(idPositionnement : number) {

//       return this.http.put<any>(`${this.apiUrl}api/positionnement/refuser/${idPositionnement}`, null);
//   }


//   getGrouilleurPositionnement(idGrouilleur : number, data : { page: string, size: string }) {

//         return this.http.get<any>(`${this.apiUrl}api/positionnement/admin-all/grouilleur/${idGrouilleur}`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }


//   getOffrePositionnement(idOffre : number, data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/positionnement/admin-all/${idOffre}`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   // api/positionnement/admin-all/{idOffre}
//   id : number;
//   idSnistre : number = null;
//   bossId:number = null;
//   grouilleurId:number = null;
//   offreId : number = null;
//   validerPositionnement(idPositionnement : number) {

//       return this.http.put<any>(`${this.apiUrl}api/positionnement/valider/${idPositionnement}`,null);
//   }


//   saveCategorie(categorie : any) {

//       return this.http.post<any>(`${this.apiUrl}api/categorie/admin-all`,categorie);
//   }



//   getAllCategorie() {

//       return this.http.get<ICategorie[]>(`${this.apiUrl}api/categorie`);
//   }

//   uploadFile(event : any) {

//     const formData = new FormData();
//     const file : File = event;
//     if (file) {
//         formData.append("file", file);
//           return this.http.post<any>(`${this.apiUrl}api/file`, formData);
//     }

//   }

//   uploadFileBadge(event : any) {
//     return this.http.post<any[]>(`${this.apiUrl}api/file/list`, event);
//   }

//   saveManyBadges(paths : any, type : string, idG : number) {

//     const formData = {
//       "fichierJoints": paths,
//       "typeBadge": type
//     };
//     // /api/badge/admin-all/{idGrouilleur}
//       return this.http.post<any>(`${this.apiUrl}api/badge/admin-all/${idG}`, formData);
//   }

//   updateBadge(paths : any, type : string, idB : number) {

//     const formData = {
//       "fichierJoints": paths,
//       "typeBadge": type
//     };
//       return this.http.put<any>(`${this.apiUrl}api/badge/admin-all/${idB}`, formData);
//   }





//   refuserOffre(id : number) {

//       return this.http.put<any>(`${this.apiUrl}api/offre/admin-all/refuser/${id}`, null);
//     }

//     attribuerOffre(idGrouilleur : number, idOffre : number) {

//       if(true){

//         return this.http.post<any>(`${this.apiUrl}api/offre/operationnel/attribuer-grouilleur/${idGrouilleur}/${idOffre}`, {distance: 0, montant: 0,
//                                                   montantCaution: 0 },
//           {responseType : "json",});
//         }else{
//           this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//         }
//     }

//   getOffreById(id: string) {

//       return this.http.get<any>(`${this.apiUrl}api/offre/${id}`);
//   }


//   createOffre(data : IOffre) {

//       return this.http.post<any>(`${this.apiUrl}api/offre`, data);
//   }

//   invaliderBages(id : number) {
//         return this.http.put<any>(`${this.apiUrl}api/badge/admin-all/invalider/${id}`, null);
//   }
//   validerBages(id : number) {
//         return this.http.put<any>(`${this.apiUrl}api/badge/admin-all/valider/${id}`, null);
//   }

//   supprimerBages(idB : number) {
//     return this.http.delete<any>(`${this.apiUrl}api/badge/${idB}`);
//   }

//   exporterGrouilleur() {
//     const url = this.apiUrl+'api/grouilleur/test/export';
//     window.open( url, "_blank");
//   }

//   openFile(fileReference : string) {

//       return this.http.get<any>(`${this.apiUrl}api/file/${fileReference }`);
//   }

//   registerUser(data : any) {

//     if(true){
//       return this.http.post<any>(`${this.apiUrl}api/utilisateur/admin-all/add`, data);
//     }else{
//       this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//     }
//   }

//   updateCurentAdmin(data : any) {
//     this.updateAdminEmiter.emit(data);

//       return this.http.put<any>(`${this.apiUrl}api/utilisateur/admin-all/update-profil`, data);
//   }


//   resetPasswordOfCurrentAdmin(data : {ancienPin	: string, nouveauPin	: string }) {

//       return this.http.put<any>(`${this.apiUrl}api/utilisateur/admin-all/change-password`, data);
//   }

//   ///////////////
//   updateSanctionWithIdOfSanction(idSanction :number, data : {dateDebut	: string, dateFin	: string, motif	: string }) {

//       return this.http.put<any>(`${this.apiUrl}api/sanction/admin-all/${idSanction}`, data);
//   }


//   listerSanctionWithIdOfUser(idUser :number) {

//       return this.http.get<any>(`${this.apiUrl}api/sanction/admin-all/${idUser}`);
//   }


//   saveUserSanction(idUser :number, data : {dateDebut: string, dateFin: string, motif :string} ) {
//       // return this.http.put<any>(`${this.apiUrl}api/sanction/admin-all/activer-desactiver/${idSanction}`,null);
//       return this.http.post<any>(`${this.apiUrl}api/sanction/admin-all/${idUser}`, data);
//   }


//   activerDesactiverSanction(idSanction :number, data : {dateDebut: string, dateFin: string, motif :string} ) {
//         return this.http.put<any>(`${this.apiUrl}api/sanction/admin-all/activer-desactiver/${idSanction}`,null);
//   }

//   //////
//   //////
//   // getAllTache(data : { page: string, size: string }) {

//   //   if((this.loginService.user.role == "ADMIN") ||
//   //   (this.loginService.user.role == "MANAGER" ) ){
//   //       return this.http.get<any>(`${this.apiUrl}api/tache`,{
//   //           params: {"page":data.page, "size":data.size},
//   //         },);
//   //       }else{
//   //         this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//   //       }
//   // }

//   getAllTache(data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/tache`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }
//   // STAUT : NON_TRAITER, REFUSER, VALIDER
//   getAllMyTache(data : { page: string, size: string, statut:string }) {
//         return this.http.get<any>(`${this.apiUrl}api/tache/mes-taches`,{
//             params: {"page":data.page, "size":data.size, "statut":data.statut},
//           },);
//   }

//   getTacheNonAssignerSelonStatut(data : { page: string, size: string, statut:string }) {
//         return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
//             params: {"page":data.page, "size":data.size, "statut":data.statut},
//           },);
//   }

//   getTacheAssignerSelonStatut(data : { page: string, size: string, statut:string }) {
//         return this.http.get<any>(`${this.apiUrl}api/tache/selon-statut`,{
//             params: {"page":data.page, "size":data.size, "statut":data.statut},
//           },);
//   }

//   attribuerTache(idTache :number, idUser : number ) {
//       if(true){
//         return this.http.put<any>(`${this.apiUrl}api/tache/manager/attribuer-tache/${idTache}/${idUser}`,null);
//       }else{
//         this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//       }
//   }

//   nonTraiteTache(idTache :number) {
//         return this.http.put<any>(`${this.apiUrl}api/tache/liberer-tache/${idTache}`,null);
//   }

//   getTacheByid(idTache :number) {
//         return this.http.get<any>(`${this.apiUrl}api/tache/${idTache}`);
//   }

//   refuserTache(idTache :number) {
//         return this.http.put<any>(`${this.apiUrl}api/tache/refuser-tache/${idTache}`,null);
//   }

//   selectionnerTache(idTache :number) {
//         return this.http.put<any>(`${this.apiUrl}api/tache/selectionner-tache/${idTache}`,null);
//   }

//   traiterTache(idTache :number) {
//          return this.http.put<any>(`${this.apiUrl}api/tache/valider-tache/${idTache}`,null);
//         //  return this.http.put<any>(`${this.apiUrl}api/tache/traiter-tache/${idTache}`,null);
//   }

//   getAllTransaction(data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/transaction/admin-all`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   getTransactionByid(idTransaction : number) {
//         return this.http.get<ITransaction>(`${this.apiUrl}api/transaction/admin-all/${idTransaction}`);
//   }



//   creditOrDebitCagnote(idGrouilleur : number, transaction : {"montant": number,"motif": string,"moyenOperation": string,
//                                                       "numeroTransaction": string,"typeOperation": string
//                                                     }) {
//     return this.http.post<any>(`${this.apiUrl}api/transaction/financier/crediter-debiter-cagnote/${idGrouilleur}`,transaction);
//   }

//   creditOrDebitCaution(idGrouilleur : number, transaction : {"montant": number,"motif": string,"moyenOperation": string,
//                                                       "numeroTransaction": string,"typeOperation": string
//                                                     }) {
//         return this.http.post<any>(`${this.apiUrl}api/transaction/financier/crediter-debiter-caution/${idGrouilleur}`,transaction);
//   }

//   effectuerTransaction(idTransaction : number) {

//     if((this.loginService.user.role == "ADMIN") ||
//     (this.loginService.user.role == "FINANCIER" ) ){
//         return this.http.put<any>(`${this.apiUrl}api/transaction/financier/effectuer-transaction/${idTransaction}`,null);
//         }else{
//           this.loginService.showAlert("NOT ACCESS","Vous ne pouvez pas effectuer cette action");
//         }
//   }

//   annulerTransactionByid(idTransaction : number) {
//         return this.http.put<ITransaction>(`${this.apiUrl}api/transaction/financier/annuler-transaction/${idTransaction}`,null);
//   }


//   getAllBackOfficeUser(data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin-all`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }

//   getAllSinistre(data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/sinistre/admin-all`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }
//   getSinistreById(idSnistre : number) {
//         return this.http.get<ISinistre>(`${this.apiUrl}api/sinistre/${idSnistre}`);
//   }

//   createSinistre(idOffre : number) {
//         return this.http.post<any>(`${this.apiUrl}api/sinistre/${idOffre}`,null);
//   }

//   debuterTraitement(idSnistre : number) {
//         return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/debuter-traitement/${idSnistre}`,null);
//   }

//   nonResoudreSinistre(idSnistre : number) {
//         return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/non-resoudre/${idSnistre}`,null);
//   }


//   resoudreSinistre(idSnistre : number) {
//         return this.http.put<any>(`${this.apiUrl}api/sinistre/admin-all/resoudre/${idSnistre}`,null);
//   }


//   getBaraOfBossId(idBoss : number , data : { page: string, size: string }) {
//       return this.http.get<any>(`${this.apiUrl}api/offre/admin-all/by-boss/${idBoss}`,{
//           params: {"page":data.page, "size":data.size},
//         },);
//   }
//   getMySinistre(data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/sinistre/mes-sinistres`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }


//   /**
//    * Upload files
//    * @param filePaths paths of files
//    * @returns
//    */
//    uploadFiles(filePaths:string[] ){
//     // return from(this.getMultipleFiles(filePaths)).pipe(switchMap(medias =>{
//     //     const formData = new FormData();


//     //     for (let media of medias) {
//     //       formData.append("files", media.blob, media.file.name);
//     //     }

//     //     return this.http.post<IMediaGallery[]>(`${this.apiUrl}/file/list`,formData);
//     //   }));
//   }

//   promotionEmit = new EventEmitter<void>();
//   /**
//    * new Update
//    */
//    createPromotion(promotion : {
//     "cible": string,
//     "code": string,
//     "dateDebut": string,
//     "dateFin": string,
//     "libelle": string,
//     "regle": string,
//     "taux": boolean,
//     "valeur": number,
// } ){
// this.promotionEmit.emit();
// return this.http.post<any>(`${this.apiUrl}api/promotion`, promotion);
// }

//   getAllPromtion(data : { page: string, size: string }) {

//       return this.http.get<any>(`${this.apiUrl}api/promotion`,{
//             params: {"page":data.page, "size":data.size},
//           },);
//   }


//   updateCategorie(idCat : number, cat : any)
//   {
//         return this.http.put<any>(`${this.apiUrl}api/categorie/admin-all/${idCat}`,cat);
//   }


//   activateOrDeactivateCategorie(idCat : number){
//         return this.http.put<any>(`${this.apiUrl}api/categorie/admin-all/activer-desactiver/${idCat}`,null);
//   }


//   activateOrDeactivategrouilleur(idG : number){
//       return this.http.put<any>(`${this.apiUrl}api/grouilleur/admin-all/activer/${idG}`,null);
//   }



//   getTransactionDetail(idTransaction : number){
//         return this.http.get<ITransaction>(`${this.apiUrl}api/transaction/${idTransaction}`,null);
//   }



//   updateBackOfficeUser(idUser : number, user : any){
//     // this.loginService.showAlert("User to upadate", JSON.stringify(user));
//        return this.http.put<any>(`${this.apiUrl}api/utilisateur/admin-all/update/${idUser}`,user);
//   }

//   activateOrDeactivateBackOfficeUser(idUser : number){
//         this.tacheEmit.emit();
//         return this.http.get<any>(`${this.apiUrl}api/utilisateur/admin/activer-desactiver/${idUser}`);
//   }

//   tacheEmit = new EventEmitter<any>();

//   getFreeTache(data : { page: string, size: string }) {
//         return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
//             params: {"page":data.page, "size":data.size},
//           });
//   }

//   getAvisByIdGrouilleur(idGrouilleur : number){
//         return this.http.get<IAvis[]>(`${this.apiUrl}api/avis/${idGrouilleur}`);

//   }

//   getOffrestat(idAnne : number){
//         return this.http.get<{nombreOffres: number,numMois: number}[]>(`${this.apiUrl}api/offre/nombre-par-mois/${idAnne}`);
//   }

//   getChiffreAffaireParMois(anne : number){
//         return this.http.get<{montant: number,numMois: number}[]>(`${this.apiUrl}api/statistiques/chiffre-affaire-par-mois/${anne}`);
//   }

//   getMoyenneOffreparJour(anne : number){
//         return this.http.get<{valeur: number}>(`${this.apiUrl}api/statistiques/moyenne-offre-par-jour/${anne}`);
//   }

//   getMoyenneUtilisateurparJour(anne : number){
//         return this.http.get<{valeur: number}>(`${this.apiUrl}api/statistiques/moyenne-utilisateur-par-jour/${anne}`);
//   }

//   getMoyenneOffreparMois(anne : number){
//     return this.http.get<{nombreOffres: number, numMois: number}[]>(`${this.apiUrl}api/statistiques/nombre-offres-par-mois/${anne}`);
//   }

//   deleteRealisation(idRealisation : number){
//     return this.http.delete<any>(`${this.apiUrl}api/realisation/${idRealisation}`);
//   }

//   invaliderRealisation(idRealisation : number){
//     return this.http.put<any>(`${this.apiUrl}api/realisation/admin-all/invalider/${idRealisation}`, null);
//   }

//   validerRealisation(idRealisation : number){
//     return this.http.put<any>(`${this.apiUrl}api/realisation/admin-all/valider/${idRealisation}`, null);
//   }

//   saveWalet(idGrouilleur : number, wallet : any){
//     return this.http.post<any>(`${this.apiUrl}api/wallet/admin-all/${idGrouilleur}`, wallet);
//   }

//   updateWalet(idWallet : number, wallet : IWallet){
//     return this.http.put<any>(`${this.apiUrl}api/wallet/${idWallet}`, wallet);
//   }

//   deleteWalet(idWallet : number){
//     return this.http.delete<any>(`${this.apiUrl}api/wallet/${idWallet}`);
//   }

//   getWaletByIdgrouilleur(idGrouilleur : number){
//     return this.http.get<IWallet[]>(`${this.apiUrl}api/wallet/admin-all/${idGrouilleur}`);
//   }

//   getPromotionById(idprom : number){
//     return this.http.get<IPromotion>(`${this.apiUrl}api/promotion/${idprom}`);
//   }

//   updatePromotionById(idprom : number, prom : any){
//     return this.http.put<any>(`${this.apiUrl}api/promotion/${idprom}`, prom);
//   }

//   deletePromotionById(idprom : number){
//     return this.http.put<any>(`${this.apiUrl}api/promotion/activer-desactiver/${idprom}`, null);
//   }

//   saveMessagePourAdmin(message : any){
//     return this.http.post<any>(`${this.apiUrl}api/message-admin`, message);
//   }

//   deleteMessagePourAdmin(id : number){
//     return this.http.delete<any>(`${this.apiUrl}api/message-admin/${id}`);
//   }

//   getDiscution(nombreDiscussion : number){
//     return this.http.get<any>(`${this.apiUrl}api/message-admin/admin-all?nombreDiscussion=${nombreDiscussion}`);
//   }

//   sendMessageToUser(message : any){
//     return this.http.post<any>(`${this.apiUrl}api/message-admin/admin-all/select-users`, message);
//   }

//   sendDiffusionMessage(message : any){
//     return this.http.post<any>(`${this.apiUrl}api/message-admin/admin-all/all-users`, message);
//   }

//   lireNouveauMessage(idUser : any){
//     return this.http.put<any>(`${this.apiUrl}api/message-admin/admin-all/lire-messages/${idUser}`, null);
//   }

//   NouveauMessage(){
//     return this.http.get<{"valeur" : number}>(`${this.apiUrl}api/message-admin/admin-all/nombre-nouveaux-messages`);
//   }

//   getListMessageFromUser(idUser : number){
//     return this.http.get<any>(`${this.apiUrl}api/message-admin/admin-all/by-user/${idUser}`);
//   }

//   getMessageFromUser(typeProfil : String, data : { page: string, size: string }){
//     return this.http.get<any>(`${this.apiUrl}api/message-admin/selon-type-profil/${typeProfil}`,{
//       params: {"page":data.page, "size":data.size},
//     });
//   }

// rechercheGrouilleur(data : { page: string, query: string, size: string }){
//   return this.http.get<any>(`${this.apiUrl}api/grouilleur/admin-all/search`,{
//     params: {"page":data.page, "size":data.size, "query":data.query},
//   });
// }

// exportTransaction(periode : string){
//   // ANNEE_EN_COURS, JOUR_EN_COURS, MOIS_EN_COURS
//   const url = this.apiUrl+`api/transaction/admin-all/test-export/${periode}`;
//   window.open( url, "_blank");
// }


// // exporterGrouilleur() {
// //   const url = this.apiUrl+'api/grouilleur/test/export';
// //   window.open( url, "_blank");
// // }

// //   getFreeTache(data : { page: string, size: string }) {
// //     return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
// //         params: {"page":data.page, "size":data.size},
// //       });
// // }

// //   getFreeTache(data : { page: string, size: string }) {
// //     return this.http.get<any>(`${this.apiUrl}api/tache/non-attribuer`,{
// //         params: {"page":data.page, "size":data.size},
// //       });
// // }
// // NON_TRAITER, REFUSER, VALIDER
//   getTacheByStatut(data : { page: string, size: string, statut: string }){
//     return this.http.get<any>(`${this.apiUrl}api/tache/selon-statut`,{
//       params: {"page":data.page, "size":data.size, "statut":data.statut},
//     });
//   }

// }
// >>>>>>> c2a404b0dd73380cd08def51f9696bf03378c220
