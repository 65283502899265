import { element } from 'protractor';
import { ICategorie } from './../../models/entities/icategorie';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/entities/iuser';
import { CanComponentDeactivate } from 'src/app/guard/can-desactivate.guard';
import { Observable } from 'rxjs';

import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-update-sous-categirie',
  templateUrl: './update-sous-categirie.component.html',
  styleUrls: ['./update-sous-categirie.component.scss'],
})
export class UpdateSousCategirieComponent implements OnInit {
  selectedValueg: string = "periode";
// E:\Projet de devellopement\jevebara-admin\src\app\AlertComponents\update-categorie\update-categorie.component.ts
  categories : ICategorie = {};
  telephone = "787878" ;
  role = "OPERATIONNEL"; 
  email = "@gmail.com";
  nom = "";
  pin = "0000";
  prenom = "qfs";
  referenceFichier = "";
  saved = false;
  update = false;
  submitt: string;

  
  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }

  periodess  = [
    // ADMIN, , , , UTILISATEUR
    {value: 'MANAGER', viewValue: 'Manager'},
    {value: 'FINANCIER', viewValue: 'Financier'},
    {value: 'OPERATIONNEL', viewValue: 'Opérationnel'},
  ];
  categorie: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<UpdateSousCategirieComponent>,
  public loginService : LoginService,
  public jeveBaraService : JeveBaraService ) {
    if(data.id){
      this.id = data.id;
      this.submitt = "Modifier";
      this.update = true;
      console.log("id User : "+ data.id);
      
      
      this.jeveBaraService.getCategorieList(data.id).subscribe( (res) => {
        res.forEach(element => {
          if(element.id == this.id){
            console.log("categorie : "+ JSON.stringify(element));
            this.categories = element;
            this.categorie = element.libelle;
          }
        });
      
      });
    }else{
      this.role = "MANAGER"; 
      this.submitt = "Ajouter";
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.telephone.length > 0 || this.role.length > 0)
        && !this.saved) { 
      return confirm('Your changes are unsaved!! Do you like to exit');
    }
    return true;
  }

  fichier: any;
  fichierBase: any;
  id: number;

  async upload(){
    this.closeDialog(true);
    console.log(this.fichier);
    const loading = this.loginService.loading("1");
    if(this.fichier){

      this.jeveBaraService.uploadFile(this.fichier).subscribe(async (response) => {
        (await loading).dismiss();                 
        
        this.jeveBaraService.updateCategorie(this.id, {"libelle" : this.categorie, "referenceFichier" : response.path})
        .subscribe(async (res) => {      
          (await loading).dismiss();                 
          this.loginService.showAlert("Modification sous categorie","Sous catégorie modifier avec succès !");
          this.jeveBaraService.addBackOffice.emit();
        },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");       
          console.log("Erreur de modification "+(e.error.message));
          if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
            this.loginService.showAlert("Erreur de modification", "Mot de passe pas correct !");          
          }else{ 
            if(e.error.message == "CATEGORIE_DEJA_EN_BD"){
              const loading = this.loginService.loading("2");
              (await loading).present();
              this.jeveBaraService.getCategorieList({page: "0",size: "1000"}).subscribe(async (res) => {
              (await loading).dismiss();                 
              res.forEach(async element => {
                if(element.libelle == this.categorie){
                if(!element.actif){
                const loading = this.loginService.loading("3");
                (await loading).present();
                    this.jeveBaraService.activateOrDeactivateCategorie(element.id).subscribe(async (res) => {
                      (await loading).dismiss();                 
                      this.jeveBaraService.updateCategorie(element.id, {"libelle" : this.categorie, "referenceFichier" : response.path}).subscribe((res) => {
                        this.loginService.showAlert("Modification de Sous categorie", "Catégorie modifier avec succès !");   
                      });
                    },async e => {
                      (await loading).dismiss();
                      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                    });
                  }else{                  
                    const loading = this.loginService.loading("4");
                    (await loading).present();
                    this.jeveBaraService.updateCategorie(element.id, {"libelle" : this.categorie, "referenceFichier" : response.path})
                    .subscribe(async (res) => {
                            (await loading).dismiss();                 
                      this.loginService.showAlert("Modification de Sous categorie", "Sous catégorie modifier avec succès !");   
                    },async e => {
                      (await loading).dismiss();
                      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                    });
                  }
                }
              });
            },async e => {
              (await loading).dismiss();
              this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
            });
           }else{
              this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
           }
          }
      });
      },async e => {
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });
    }else{
        const loading = this.loginService.loading();
        (await loading).present();
        this.jeveBaraService.updateCategorie(this.id, {"libelle" : this.categorie, "referenceFichier" : this.categories.referenceFichier})
        .subscribe(async (res) => {
         (await loading).dismiss();                 
          this.loginService.showAlert("Création categorie","Catégorie modifier avec succès !");
          this.jeveBaraService.addBackOffice.emit();
        },async e => {
          (await loading).dismiss();  
          console.log("Erreur de modification "+(e.error.message));
          if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
            this.loginService.showAlert("Erreur de modification", "Mot de passe pas correct !");          
          }else{ 
            if(e.error.message == "CATEGORIE_DEJA_EN_BD"){            
                  const loading = this.loginService.loading();
                  (await loading).present();
                  this.jeveBaraService.getCategorieList({page: "0",size: "1000"}).subscribe(async (res) => {
                  (await loading).dismiss();                 
                  res.forEach(async element => {
                    if(element.libelle == this.categorie){
                      if(element.actif){                    
                        const loading = this.loginService.loading();
                        (await loading).present();
                        this.jeveBaraService.activateOrDeactivateCategorie(element.id).subscribe(async (res) => {
                                (await loading).dismiss();                 
                          this.jeveBaraService.updateCategorie(element.id, {"libelle" : this.categorie, "referenceFichier" : this.categories.referenceFichier})
                          .subscribe((res) => {
                            this.loginService.showAlert("Création de Categorie", "Catégorie modifier avec succès !");   
                          },async e => {
                            (await loading).dismiss();
                            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                          });
                        },async e => {
                          (await loading).dismiss();
                          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                        });
                      }else{
                      const loading = this.loginService.loading();
                      (await loading).present();
                        this.jeveBaraService.updateCategorie(element.id, {"libelle" : this.categorie, "referenceFichier" :  this.categories.referenceFichier})
                        .subscribe(async (res) => {
                        (await loading).dismiss();                 
                          this.loginService.showAlert("Création de Categorie", "Catégorie modifier avec succès !");   
                        },async e => {
                          (await loading).dismiss();
                          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                        });
                    }
                    }
                  });
              },async e => {
                (await loading).dismiss();
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              });
            }else{
              this.loginService.showAlert("Erreur de modification", "Oups erreur inattendue veuillez réessayer plus tard !");
            }
          }
      });
    }
  }

  file(file : any, fichierBase: any){
    console.log(file);
    console.log(this.categorie);
    this.fichier = file;
    this.fichierBase = fichierBase;
  }
  
  ngOnInit() {
  }

  roles = ["ADMIN", "BOSS", "GROUILLEUR"];

  onSubmit(form: NgForm) {
    this.upload();
  }

    
  closeDialog(bol: boolean){
  this.dialogRef.close(bol);
  }

}
