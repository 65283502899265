import { IOffre } from './../../models/entities/ioffre';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { IBoss } from 'src/app/models/entities/iboss';
import { LoginService } from 'src/app/services/login.service';
// import { ConfirmDialogComponent } from '../confim-dialog/confim-dialog.component';
import { DialogUpdateBossComponent } from '../dialog-update-boss/dialog-update-boss.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offre-details',
  templateUrl: './offre-details.component.html',
  styleUrls: ['./offre-details.component.scss'],
})
export class OffreDetailsComponent implements OnInit {
  boss : IBoss;
  pour : boolean = true;

  loginService: LoginService;
  jeveBarService: JeveBaraService;
  offre : IOffre;
  boutonText :string;
  panelOpenState = false;

  constructor(private dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogUpdateBossComponent>) {
      // if(data?.id != -1){
      //   this.pour = false;
      // }
      
      
     const loading = this.loginService.loading();
    // (await loading).present();
      this.jeveBarService.getOffreById(data?.id).subscribe( async (res) =>{
          (await loading).dismiss();                 
          this.offre = res; 
          console.log("détail du bara");         
          console.log(res);         
        if(res.statut){
          this.boutonText  = 'Desactiver';
        }else{
          this.boutonText = "Activer";
        }
      },async e => {
        console.log("détail du bara erreur"); 
        (await loading).dismiss();
        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
      });

     }

  ngOnInit() {}

  onClose(){
    this.dialogRef.close();
  }

  Reserver(){

  }
  valider(){
    
  }
  Annuler(){
    
  }
  Refuser(){

  }
  
  private apiUrl = environment.apiUrl;
  
  ouvrir(path){  
    console.log(path);
   const url = this.apiUrl +"api/file?fileReference="+ path;   
   window.open( url, "_blank");
 }
  
  download(path){
   this.ouvrir(path);
  }

  addBoss(msg){
    this.closeDialog();
    console.log(this.boss);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      message : msg
    }
    
    this.dialog.open(DialogUpdateBossComponent, dialogConfig);
    this.closeDialog();
  }

  delete(){
    this.closeDialog();
    this.addBoss('');
    this.closeDialog();
  }

  closeDialog(){
    this.dialogRef.close(false);
  }
}
