import { IBoss } from './../../models/entities/iboss';
import { IPositionnement } from 'src/app/models/entities/ipositionnement';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IOffre } from 'src/app/models/entities/ioffre';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-message-to-user',
  templateUrl: './send-message-to-user.component.html',
  styleUrls: ['./send-message-to-user.component.scss'],
})
export class SendMessageToUserComponent implements OnInit {

  @Input() headerTitle : String = "";
  recherche : string = "";
  title : boolean = false;
  user : any = {} ;
  offre : IPositionnement[] = [] ;
  offreBoolean : Boolean = true;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  path: string;
  textMessage: any;

  constructor(private loginService: LoginService,
              public jeveBaraService : JeveBaraService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<SendMessageToUserComponent> ) {
        console.log("data : "+ JSON.stringify(data));
        if(data.type == "boss" || data.type == "BOSS"){
          data.type = "BOSS";
            this.jeveBaraService.getBossById(+data.id).subscribe( (res) => {
              console.log("boss send message : "+ JSON.stringify(res));
              this.user = res; 
              if(res.photoProfil != null){
                this.path = this.apiUrl +"api/file?fileReference="+ res.photoProfil;
              }else{
                this.path = "../../../assets/imgs/profil.jpg";
              }                       
            });
        }
        if(data.type == 'grouilleur' || data.type == 'GROUILLEUR'){
          data.type = "GROUILLEUR";
            jeveBaraService.getGrouilleurById(+data.id).subscribe( (res) => {
              console.log("grouilleur send message : "+ JSON.stringify(res))
              this.user = res;         
              if(res.photoProfil != null){
                this.path = this.apiUrl +"api/file?fileReference="+ res.photoProfil ;
              }else{
                this.path = "../../../assets/imgs/profil.jpg";
              }
            });
        }
        if(data.type == 'ALLGROUILLEUR' || data.type == 'ALLBOSS' || data.type == 'All'){
          if(data.type == 'All'){
            data.type = "TOUS";
          }
          this.path = "../../../assets/icon/user.svg";
        }
  }

  
  private apiUrl = environment.apiUrl;
  
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

  async sendMessage(){                   
    this.closeDialog(true);
     const loading = this.loginService.loading();
    (await loading).present();
    (await this.jeveBaraService.sendMessageToUser({"contenu": this.textMessage, 
                                              "idUsers": [this.user.idUser], 
                                              "typeProfil": this.data.type})).
    subscribe(async (res) => {
      (await loading).dismiss();  
      this.loginService.showAlert("Envoi de message", "Message envoyé avec success !");
      this.jeveBaraService.messageEmit.emit();    
      this.jeveBaraService.notif.emit();

    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé : "+e.error.message ,"Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }

  changeOffreBox(idOffre : number){
    this.jeveBaraService.getOffreById(idOffre+"").subscribe( (res) => {
      this.offre = res ;
    });
  }

  ngOnInit() {}

}
