import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(value: object[], 
            filterString: string, 
            propName: string
    ): any {
      if (value.length === 0 || filterString === '') {
        return value;
      }
      
      const resultArray = [];
      for (const item of value) {      
        console.log("etienne");
        console.log(`The word "${filterString}" ${item[propName].includes(filterString) ? 'is' : 'is not'} in the sentence`);
        if (item[propName].includes(filterString)) {
          resultArray.push(item);
        }
      }
      return resultArray;
  }

}
