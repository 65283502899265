import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/entities/iuser';
import { CanComponentDeactivate } from 'src/app/guard/can-desactivate.guard';
import { Observable } from 'rxjs';

import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-add-categorie',
  templateUrl: './add-categorie.component.html',
  styleUrls: ['./add-categorie.component.scss'],
})
export class AddCategorieComponent implements OnInit {
  selectedValueg: string = "periode";

  telephone = "787878" ;
  role = "OPERATIONNEL"; 
  email = "@gmail.com";
  nom = "";
  pin = "0000";
  prenom = "qfs";
  referenceFichier = "";
  saved = false;
  update = false;
  submitt: string;

  periodess  = [
    // ADMIN, , , , UTILISATEUR
    {value: 'MANAGER', viewValue: 'Manager'},
    {value: 'FINANCIER', viewValue: 'Financier'},
    {value: 'OPERATIONNEL', viewValue: 'Opérationnel'},
  ];

  categorie = "";
  fichier: any;
  fichierBase: any;
  disponible : boolean = true;

  roles = ["ADMIN", "BOSS", "GROUILLEUR"];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  public loginService : LoginService,
  public jeveBaraService : JeveBaraService ) {
    if(data.id){
      this.submitt = "Modifier";
      this.update = true;
      console.log("id User : "+ data.id);      
      const loading = this.loginService.loading();            
      this.jeveBaraService.getSuperCategorieList(data.id).subscribe( async (res) => {
        (await loading).dismiss();                 
        console.log("User : "+ JSON.stringify(res));
        this.telephone = res.telephone;
        this.role = res.roleUtilisateur;
        this.email = res.email  ;
        this.nom = res.nom;
        this.pin = res.pin;
        this.prenom = res.prenom;
        this.referenceFichier = res.referenceFichier;
      },
      async e => {
        (await loading).dismiss();                 
      });
    }else{
      this.role = "MANAGER"; 
      this.submitt = "Ajouter";
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if ((this.telephone.length > 0 || this.role.length > 0)
        && !this.saved) { 
      return confirm('Your changes are unsaved!! Do you like to exit');
    }
    return true;
  }
  
  file(file : any, fichierBase: any){
    console.log(file);
    console.log(this.categorie);
    this.fichier = file;
    this.fichierBase = fichierBase;
  }

  async upload(){
    this.closeDialog(true);
    console.log("this.fichier : "+this.fichier);
    const loading = this.loginService.loading();
    (await loading).present(); 
    if(this.fichier){

      this.jeveBaraService.uploadFile(this.fichier).subscribe(async (response) => {
        (await loading).dismiss();                 
            // this.loginService.showAlert("Upload des fichiers 2", JSON.stringify({"libelle" : this.categorie, "referenceFichier" : response.path}));
            this.jeveBaraService.saveSuperCategorie({"disponible" : this.disponible,"libelle" : this.categorie, "referenceFichier" : response.path}).subscribe((res) => {
            this.loginService.showAlert("Création categorie","Catégorie créée avec succès !");
            this.jeveBaraService.addBackOffice.emit();
          }, async e => {        
            (await loading).dismiss();                 
            console.log("Erreur de modification "+(e.error.message));
            if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
              this.loginService.showAlert("Erreur de modification", "Mot de passe pas correct !");          
            }else{ 
              if(e.error.message == "CATEGORIE_DEJA_EN_BD"){
                          
              const loading = this.loginService.loading("");
              (await loading).present();
              this.jeveBaraService.getSuperCategorieList({page: "0",size: "1000"}).subscribe(async (res) => {
              (await loading).dismiss();                 
                res.forEach(async element => {
                  if(element.libelle == this.categorie){
                    if(!element.actif){
                      const loading = this.loginService.loading("");
                      (await loading).present();
                      this.jeveBaraService.activateOrDeactivateSuperCategorie(element.id).subscribe(async (res) => {
                      (await loading).dismiss();                 
                          this.jeveBaraService.updateSuperCategorie(element.id, {"disponible" : this.disponible,"libelle" : this.categorie, "referenceFichier" : response.path}).subscribe((res) => {
                          this.loginService.showAlert("Création de Categorie", "Catégorie modifier avec succès !"); 
                          this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();  
                        });
                      },async e => {
                        (await loading).dismiss();
                        this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                      });
                    }else{
                      this.loginService.showAlert("Création de categorie", "Cette catégorie existe déjà !"); 
                      return;        
                    }
                  }
                });
              },async e => {
                (await loading).dismiss();
                this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
              });
             }else{
                this.loginService.showAlert("Erreur de modification", "Oups categorie déjà en base de donnés !");
             }
            }
        });
        }, async e => {
          (await loading).dismiss();                 
        });
    }else{
      
    // this.jeveBaraService.uploadFile(this.fichier).subscribe(async (response) => {
      // this.loginService.showAlert("Upload des fichiers 2", JSON.stringify({"libelle" : this.categorie, "referenceFichier" : response.path}));
      this.jeveBaraService.saveSuperCategorie({"disponible" : this.disponible,"libelle" : this.categorie, "referenceFichier" : ""}).subscribe(async (res) => {
          (await loading).dismiss();                 
          this.loginService.showAlert("Création categorie","Catégorie créée avec succès !");
          this.jeveBaraService.addBackOffice.emit();
        }, async e => {        
          (await loading).dismiss();                 
          console.log("Erreur de modification "+(e.error.message));
          if(e.error.message == "ANCIEN_PIN_NON_VALIDE"){
            this.loginService.showAlert("Erreur de modification", "Mot de passe pas correct !");          
          }else{ 
            if(e.error.message == "CATEGORIE_DEJA_EN_BD"){
                        
            const loading = this.loginService.loading("");
            (await loading).present();
            this.jeveBaraService.getSuperCategorieList({page: "0",size: "1000"}).subscribe(async (res) => {
            (await loading).dismiss();                 
              res.forEach(async element => {
                if(element.libelle == this.categorie){
                  if(!element.actif){
                    const loading = this.loginService.loading("");
                    (await loading).present();
                    this.jeveBaraService.activateOrDeactivateSuperCategorie(element.id).subscribe(async (res) => {
                    (await loading).dismiss();                 
                        this.jeveBaraService.updateSuperCategorie(element.id, {"disponible" : this.disponible,"libelle" : this.categorie, "referenceFichier" : ""}).subscribe((res) => {
                        this.loginService.showAlert("Création de Categorie", "Catégorie modifier avec succès !"); 
                        this.jeveBaraService.deleteOrUpdateBackOfficeUser.emit();  
                      });
                    },async e => {
                      (await loading).dismiss();
                      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
                    });
                  }else{
                    this.loginService.showAlert("Création de categorie", "Cette catégorie existe déjà !"); 
                    return;        
                  }
                }
              });
            },async e => {
              (await loading).dismiss();
              this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
            });
           }else{
              this.loginService.showAlert("Erreur de modification", "Oups categorie déjà en base de donnés !");
           }
          }
      });
      // }, async e => {
      //   (await loading).dismiss();                 
      // }
      // );
    }
  }
  
  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.upload();
  }
    
  closeDialog(bol: boolean){
  this.dialogRef.close(bol);
  }

}
