import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/entities/iuser';
import { CanComponentDeactivate } from 'src/app/guard/can-desactivate.guard';
import { Observable } from 'rxjs';

import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
})
export class BadgesComponent implements OnInit, OnDestroy {
  subuploadFileBadge: any;
  subsaveManyBadges: any;

  ngOnDestroy(): void {
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<BadgesComponent>,
  public loginService : LoginService,
  public jeveBaraService : JeveBaraService) {
    this.id = this.data;
   }
  @Input() categorie;
  @Input() id;
  fichier: any[] = [];
  fichierBase: any = {};


  file(file : any, fileChangeEvent: any){
    console.log(file);
    this.fichierBase = fileChangeEvent;
    console.log(this.fichierBase);
    console.log(this.fichierBase);

    for (let index = 0; index < fileChangeEvent.target.files.length; index++) {
      this.fichier.push(fileChangeEvent.target.files[index])
    }
  }

  
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }
  
  async upload(){
    let b = [];
    if(this.fichier.length == 0){
      this.loginService.showAlert("Erreur de chargement", "Vueillez selectionner des fichiers !");  
      return;      
    }
    let message = this.data.message=="DIPLOMES" ? "diplome(s) " : "" ;
        message = this.data.message=="PLAN_DE_LOCALISATION" ? "plan de localisation " : "" ;
        message = this.data.message=="CAUTION_MORAL" ? "caution moral " : "" ;
        message = this.data.message=="CNI" ? "CNI " : "" ;
        message = this.data.message=="FACTURE" ? "facture " : "" ;
        message = this.data.message=="PERMIS_CONDUIRE" ? "permis de conduire " : "" ;
        message = this.data.message=="REGISTRE_DE_COMMERCE" ? "registre de commerce moral " : "" ;

    this.closeDialog(true);
    console.log(this.fichier);
    
    if(this.data.message == null){
      let formData = new FormData();
      for (let i = 0; i < this.fichier.length; i++) {
        formData.append('files', this.fichier[i], this.fichier[i].name);
      }

     const loading = this.loginService.loading();
     (await loading).present();
      this.subuploadFileBadge = this.jeveBaraService.uploadFileBadge(formData).subscribe(async (res) => {
        (await loading).dismiss();                 
        let paths = [];
        res.forEach(element => {
          paths.push(element.path);
        });
        this.data.badge.fichierJoints.forEach(element => {
          paths.push(element.reference);
        });

        console.log(this.data.badge);
        this.jeveBaraService.updateBadge(paths, this.data.badge.typeBadge, this.data.badge.id).subscribe((res) => {        
            this.loginService.showAlert("Badge",message+" modifié avec succès !");
            this.jeveBaraService.updataBage.emit();
          }, e => {        
            this.loginService.showAlert("Erreur de modification", "Vueillez réesayer plus tard !");          
            console.log("Erreur de chargement "+(e.message));
        });        
      },async e => {
        (await loading).dismiss();
        // this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        this.loginService.showAlert("Erreur de modification", "Vueillez réesayer plus tard !");          
        console.log("Erreur de chargement 1 "+(e.message));
      });
      return;
    }
     

    let formData = new FormData();
    for (let i = 0; i < this.fichier.length; i++) {
      formData.append('files', this.fichier[i], this.fichier[i].name);
    }
    
    const loading = this.loginService.loading();
    (await loading).present();
    this.subuploadFileBadge = this.jeveBaraService.uploadFileBadge(formData).subscribe(async (res) => {
      (await loading).dismiss();                 
      let paths = [];
      res.forEach(element => {
        paths.push(element.path);
      });
      this.subsaveManyBadges = this.jeveBaraService.saveManyBadges(paths, this.data.message, this.data.id).subscribe((res) => {        
          this.loginService.showAlert("Badge", message+"chargé avec succès !");
          this.jeveBaraService.updataBage.emit();
        }, e => {        
          this.loginService.showAlert("Erreur de chargement", "Vueillez réesayer plus tard !");          
          console.log("Erreur de chargement "+(e.message));
      });
    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");   
      this.loginService.showAlert("Erreur de chargement", "Vueillez réesayer plus tard !");          
      console.log("Erreur de chargement 1 "+(e.message));
    });
    
  }  

  ngOnInit() {}

}
