import { ISinistre } from './../../models/entities/isinistre';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-sinistre',
  templateUrl: './sinistre.component.html',
  styleUrls: ['./sinistre.component.scss'],
})
export class SinistreComponent implements OnInit {
  sinistre = true;
  id : number = null;
  sinistreO: ISinistre;
  constructor(public jeveBaraService : JeveBaraService,
              public loginService : LoginService) { 
              }

  ngOnInit() {
    this.jeveBaraService.detailSinistre.subscribe((res) => {
      this.jeveBaraService.getSinistreById(this.id).subscribe( (res) => {
          console.log("sinistre de l'id "+this.id+" : :-) "+res.statut );
          console.log(res);
          this.sinistreO = res;
          this.jeveBaraService.idSnistre = null;
      });
    });
  }

  traiterSinistre(statu : string){
    if(statu == 'RESOLU')
      return;
      
    this.loginService.showSendTaiterSinistre(this.id);
  }

  sinistreDetail(id : number){
    this.id = id;
    
    this.jeveBaraService.getSinistreById(this.id).subscribe( (res) => {
        console.log("sinistre de l'id "+this.id+" : :-) "+res.statut );
        console.log(res);
        this.sinistreO = res;
        this.jeveBaraService.idSnistre = null;
    });

    this.jeveBaraService.idSnistre = id;
    console.log("id des details : "+id);
    this.sinistre = !this.sinistre;
    return;
  }
}
