import { IUser } from 'src/app/models/entities/iuser';
import { LoginService } from 'src/app/services/login.service';
import { JeveBaraService } from './../../services/jeve-bara.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-attriber-tache',
  templateUrl: './attriber-tache.component.html',
  styleUrls: ['./attriber-tache.component.scss'],
})
export class AttriberTacheComponent implements OnInit {
  id : number = 0;
  // idq : IGr = "";
  
  periodess : IUser[] = [];
  
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<AttriberTacheComponent>, 
              public jeveBaraService : JeveBaraService, 
              private loginService : LoginService ) {
      const loading = this.loginService.loading();
      this.jeveBaraService.getAllBackOfficeUser({page : "0", size : "100"}).subscribe(async (res) => {
        (await loading).dismiss();                 
        this.periodess = [];
        res.data.forEach(element => {
        if(element.actif)
            this.periodess = this.periodess.concat([element]);
        });
        console.log("getAllBackOfficeUser", JSON.stringify(this.periodess));
        this.id = res.data[0].id;
      },async e => {
        (await loading).dismiss();                 
      });
  }

   async affecter(){
     this.closeDialog(false);    
     const loading = this.loginService.loading();
    (await loading).present();
     this.jeveBaraService.attribuerTache( +this.data.title, +this.id).subscribe(async (res) => {    
      (await loading).dismiss();                 
      this.loginService.showAlert("Affectation de Tâche","cette tâche a été affecter avec success !");
      this.jeveBaraService.tacheEmit.emit();
      this.jeveBaraService.notif.emit();
     },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
     });
  }

ngOnInit() {}

  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

}