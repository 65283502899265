import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";

declare var require: any; 

let log = console.log;
let logE = console.error;



// function log(message?: any, ...optionalParams: any[]){
//     log("flipcide::",message,...optionalParams);
//    log.bind(log, message);
// }s
function logInfo(message?: any, ...optionalParams: any[]){
    console.info("tickevent::",message,...optionalParams);
}

function logError(message?: any, ...optionalParams: any[]){
    console.error("tickevent::",message,...optionalParams);
}

function logWarn(message?: any, ...optionalParams: any[]){
    console.warn("tickevent::",message,...optionalParams);
}
 
 
export { 
    log,
    logE,
    logInfo,
    logError,
    logWarn,
}

 