import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd, NavigationExtras } from '@angular/router'
import { AlertController, ModalController, NavController, PopoverController } from "@ionic/angular";
import { ToastService } from './toast.service'; 
// import { log } from '../core/core';
import { log } from 'src/app/core/core';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  history: string[] = [];
  // history: any = [];
  // history2: any = [];
  prevUrl: string = ""; 
  lastUrl: string = "";
  backIsPushed: boolean = false;

  exceptiosnUrl: string[] = [
    "profile-photo",
    "view-message",
    "loading",
    "/premium-slider",
    "/community/new-group/creation",
    //"/stripe-first-step"
  ]

  nonRegister : string[] = [
    "/stripe-first-step"
  ]

  // history2: string[] = [
  //   "/home/home/community",
  //   "/community/open/direct/qqmuaG7BrpM0L2LNoi9PL2Rs64r1",
  //   "/home/home/user/qqmuaG7BrpM0L2LNoi9PL2Rs64r1",
  // ]

  lastMenu: string;

  counter: number = 0;

  constructor(private router: Router, 
    private location: Location,
    private navCtrl : NavController, 
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private toastProvider : ToastService,
    public popoverCtrl : PopoverController
    ) {
		log("Initializing navigation service");
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(!(new RegExp(this.exceptiosnUrl.join("|")).test(event.url)) && event.url!='/'){
          if(!this.backIsPushed){
              if(this.prevUrl!=''){
              
                if(!this.nonRegister.includes(this.prevUrl)){
                  this.counter = 0;
                  this.history.push(this.prevUrl);
                  log("history  push prevUrl", this.prevUrl);
                }

              }else{
                // this.history.pop();
              }
              
              this.prevUrl = event.url.split('?')[0];

              
					 
          }else{
            
            this.prevUrl = event.url;
            log("history  pop prevUrl", this.prevUrl);
            this.history.pop();
            this.backIsPushed = false;
          }
        }else{
          if(event.url.includes("profile-photo")){
            this.prevUrl="/home";
          }
          log("history not included", event.url);
        }
      }
    })
  }
  
  getURL(){
	  return this.router.url;
  }

  back(){
    this.backIsPushed = true;

    if(this.history && this.history.length>0){


      let last:string = this.history[this.history.length-1];
      log("history_OnBack_last",last);
      this.openPage(last);
    }else{
      this.counter++;
      if(this.counter==2){
        this.popupExitApp();
      }else{
      
        if (localStorage.getItem('isLoggedIn') == 'true') {
          if(this.prevUrl!='' && this.prevUrl!='/'){
            this.router.navigateByUrl(this.prevUrl);
          }else{
            this.router.navigateByUrl('/home');
          }
        }else{
          this.router.navigateByUrl('/main');
        }

      }
   }
  }
  



  async popupExitApp() {
    const alert_controller = await this.alertController.create({
      header: 'Exit',
      message: 'Do you want to exit the app?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // this.back();
            this.counter = 0;
            if (localStorage.getItem('isLoggedIn') == 'true') {
              // if(this.lastMenu!=''){
              //   this.router.navigateByUrl(this.lastMenu);
              // }else{
                if(this.prevUrl!='' && this.prevUrl!='/'){
                  this.router.navigateByUrl(this.prevUrl);
                }else{
                  this.router.navigateByUrl('/home');
                }
              // }
            }else{
              this.router.navigateByUrl('/main');
            }
          }
        }, {
          text: 'Confirm',
          handler: () => {
            (navigator as any).app.exitApp();
          }
        }
      ]
    });
  
    await alert_controller.present();
  }




  back2(): void {
    log("history navigation router back button alert!!!!!!!!!!!!!!", this.router.url);

    this.prevUrl = this.history[this.history.length-1];
    log("history navigation last in history", this.prevUrl);
    
					 
    let isProfile = this.prevUrl.includes("user") ||  this.prevUrl.includes("profile");
    if(isProfile){
      let userId:any = this.prevUrl.split("/");
      userId = userId.pop();
      //this.loginProvider.hideBackground();
    }

    this.history.pop();
    let last_url = this.history[this.history.length-1];

    log("history navigation last", this.lastUrl);

    log("history navigation lu", last_url);
    if(this.lastUrl==last_url && this.history.length>0){
      this.history.pop();
      last_url = this.history[this.history.length-1];
    }
    this.lastUrl = this.router.url;

    log("history navigation lu", last_url);
    
    if(last_url){
      if(last_url.includes("profile-photo")){
        if (localStorage.getItem('isLoggedIn') == 'true') {
          this.openPage('/home');
        }else{
          this.openPage('/main');
        }
      }else if(last_url.includes("new-group")){
        this.router.navigateByUrl('/home/home/community');
      }else{
        if(last_url.includes("chat-info")){
          this.history.pop();
          last_url = this.history[this.history.length-1];
        }
        if(this.prevUrl==last_url){
          this.history.pop();
          last_url = this.history[this.history.length-1];
        }
        this.prevUrl = last_url;
        
        log("navigateForward", last_url);
        if (last_url && !last_url.includes("loading")) {
        // if(this.history[this.history.length-1].includes("search/subscriber/SUBSCRIPTION")){
        // 	this.router.navigateByUrl('/home/home/profile/GRID');
        // }else{
        // 	this.navCtrl.back();
        // }
        log("navigateForward", last_url);
        this.navCtrl.navigateForward(last_url);
          //this.location.back()
        } else {
          if (localStorage.getItem('isLoggedIn') == 'true') {
            this.openPage('/home');
          }else{
            this.openPage('/main');
          }
        }
      }
    }else{
      if (localStorage.getItem('isLoggedIn') == 'true') {
        this.router.navigateByUrl('/home');
      }else{
        this.router.navigateByUrl('/main');
      }
    }
  }

  navBack(){
		// this.navCtrl.back();
  }

  popCurrentPage(){
    
		this.navCtrl.pop();
  }


  routerLink(page: any) {
    
    this.router.navigate(page);
    
  }

  openPage(page: string , data?) {

    let navigationExtras: NavigationExtras = {

      state: {

        data :{
          ...data,
        }
      }
    };

    this.router.navigate([page],navigationExtras);
  }

 
  
 
 
}