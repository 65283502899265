import { async } from '@angular/core/testing';
import { IUser } from 'src/app/models/entities/iuser';
import { MenuController } from '@ionic/angular';
import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoginService } from 'src/app/services/login.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';

@Component({
  selector: 'app-new-dasboard',
  templateUrl: './new-dasboard.component.html',
  styleUrls: ['./new-dasboard.component.scss'],
})
export class NewDasboardComponent implements OnInit {
  alertController: any;
  // user: IUser = {};
  notif: number;
  headerTitle: String = "Paramètres";
  user =  {"prenom":"","nom":"","role":"","email":"","telephone":"","ancienPin":""};

  showFiller = false;

  constructor(private localStorageProvider : LocalStorageService,
              private loginProvider : LoginService,
              public jeveBaraService: JeveBaraService,
              private navigationService : NavigationService,
              // private appService: AppService,
              private menu1: MenuController) {

                this.menu1.enable(true, 'custom');
                this.menu1.open('custom');

    this.localStorageProvider.getItem("user").then((data:any)=>{

      console.log("dashboard user get before : "+JSON.stringify(data));

      data = JSON.parse(data);
      this.user.ancienPin = data?.ancienPin;
      this.user.email = data?.email;
      this.user.role = data?.role;
      this.user.telephone = data?.telephone;
      this.user.nom = data?.nom;
      this.user.prenom = data?.prenom;

      console.log("dashboard user get after : "+JSON.stringify(data));
   
    });
                
                console.log("dashboard user : "+JSON.stringify(this.user));
                console.log("dashboard user val : "+JSON.stringify(this.loginProvider.getUser()));
                // .then( (val) => {
                //   this.user = val;
                //   console.log("dashboard user : "+JSON.stringify(this.user));
                //   console.log("dashboard user val : "+JSON.stringify(val));
                // });
                this.notif = 0;
                
                this.menu.forEach(element => {
                  this.notif = this.notif + element.new;
                });
               }
    
        changeComponent(index:number){  
          this.menu.forEach(element => {
            element.isActivate = false;
          });
          this.menu[index].isActivate = true;
        }

        fund(){
          this.menu.forEach(element => {
            if((element.icon != "home") && (element.isActivate))
              return false
          }); 
          return true;
        }

  menu = [
    {isActivate : true,  icon:"../../../assets/icon/home", route:"menu", label:"Accueil", new: 0},
    {isActivate : false, icon:"../../../assets/icon/boss", route:"boss", label:"Boss", new: 0},
    {isActivate : false, icon:"../../../assets/icon/grouilleur", route:"grouilleur", label:"Grouilleurs", new: 0},
    {isActivate : false, icon:"../../../assets/icon/offre", route:"offre", label:"Bara", new: 0},
    {isActivate : false, icon:"../../../assets/icon/sinistre", route:"sinistre", label:"Sinistres", new: 0},
    {isActivate : false, icon:"../../../assets/icon/transaction", route:"transaction", label:"Transactions", new: 0},
    {isActivate : false, icon:"../../../assets/icon/message", route:"message", label:"Messages", new: 5},
    {isActivate : false, icon:"../../../assets/icon/corbeille", route:"corbeille", label:"Corbeille de tâches", new: 0},
    {isActivate : false, icon:"../../../assets/icon/promotion", route:"promotions", label:"Promotions", new: 0},
    {isActivate : false, icon:"../../../assets/icon/parametre", route:"parametres", label:"Parametres", new: 5}
  ];
  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Login',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }
  opened = true;
  vara = ">";

  logout(){
    this.loginProvider.logout();
  }
  
  inicioRegistro(){
    return 5  ;
  }

  ngOnInit() {
  }

}
