import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  
  @Output() notifOut: any = new EventEmitter();
  
  @Input() notif : number;

  @Input() headerTitle : String = "";
  
  title : boolean = false;

  constructor() { 
    if(this.headerTitle != ""){
      this.title = true;
    }else{
      this.title = false;
    }
  }

  ngOnChanges() {
  }

  ngOnInit() {}

  fun(){}

  dwonNotif(){
    // this.notif = 0;
  }
}
