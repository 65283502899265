import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { IBoss } from 'src/app/models/entities/iboss';
import { ifError } from 'assert';
import { IonSlides } from '@ionic/angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import { MatDialog,  } from '@angular/material';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
];
@Component({
  selector: 'app-cobeille-des-taches',
  templateUrl: './cobeille-des-taches.component.html',
  styleUrls: ['./cobeille-des-taches.component.scss'],
})
export class CobeilleDesTachesComponent implements OnInit, OnDestroy {
  subsgetTacheByid: any;
  subsnonTraiteTache: any;
  subsrefuserTache: any;
  substraiterTache: any;

  ngOnDestroy(): void {
    // this.subsgetTacheByid.unsubscribe();      
    // this.subsnonTraiteTache.unsubscribe();
    // this.subsrefuserTache.unsubscribe();
    // this.substraiterTache.unsubscribe();
  }
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol','valider'];
  @ViewChild(MatPaginator) paginator : MatPaginator;
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA) ;
  dataSource1 :any ;

  @ViewChild('slides', { static: true }) slider: IonSlides;
  segment = 0;

  userStat1 : string = "userStat1";
  // bossTable : boolean = false;
  bossTable : boolean = true;
  boss : IBoss;
  tache : any;
  listeTache = true;
  nom: string = "Liste Des Boss En Attente";
  pin:          string;
  prenom:        string;
  quartier:      string;
  telephone:    string;
  addPromotionPropertie: boolean = true;
  id: number;

  constructor(private dialog: MatDialog,
    private loginService: LoginService,
    public jeveBaraService: JeveBaraService) { 
      // this.cameBack(); 
  }

      ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
      }
      mesTaches(){
        this.listeTache = !this.listeTache;
      }
      
      cameBack(){
        this.addPromotionPropertie = !this.addPromotionPropertie;
      }
      filtre(){
        this.cameBack();
      }

      async valider(){
        const loading = this.loginService.loading();
        (await loading).present();
        this.substraiterTache = this.jeveBaraService.traiterTache(this.id).subscribe(async (res) => {
         (await loading).dismiss();                 
          this.jeveBaraService.tacheEmit.emit();
          this.jeveBaraService.notif.emit();
          this.loginService.showAlert("Traitement","Tâche traité avec success !");          
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        });
        this.cameBack();
      }

      async supprimer(){
        
        const loading = this.loginService.loading();
        (await loading).present();
        this.subsrefuserTache = this.jeveBaraService.refuserTache(this.id).subscribe(async (res) => {
        (await loading).dismiss();                 
          this.jeveBaraService.tacheEmit.emit();
          this.loginService.showAlert("Supression","Tâche refusé avec success !"); 
          this.jeveBaraService.notif.emit();         
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        });
        this.cameBack();
      }

      async Liberer(){
        const loading = this.loginService.loading();
        (await loading).present();
        this.subsnonTraiteTache = this.jeveBaraService.nonTraiteTache(this.id).subscribe(async (res) => {
        (await loading).dismiss();                 
          this.jeveBaraService.tacheEmit.emit();
          this.jeveBaraService.notif.emit();
          this.loginService.showAlert("Liberation","Tâche libéré avec success !");          
        },async e => {
          (await loading).dismiss();
          this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
        });
        this.cameBack();
      }

      document = false
      async traiter(id : number){
          const loading = this.loginService.loading();
          (await loading).present();
          this.subsgetTacheByid = this.jeveBaraService.getTacheByid(id).subscribe(async (res) => {
          (await loading).dismiss();                 
            if(res.typeProfil == "GROUILLEUR"){
              this.document = true;
            }else{
              this.document = false;
            }
            this.id = id;
            this.cameBack();
          },async e => {
            (await loading).dismiss();
            this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
          });
      }

  goToaddBoss(){
    this.loginService.showUpdateBoss("msg : string", -1);
  }
  
    delete(){
    }
    
  async slideChanged() {
    this.segment = await this.slider.getActiveIndex();

  }
  
  async segmentChanged() {
    this.nom = "Liste Des Boss En Attente";
    await this.slider.slideTo(this.segment);
    if(this.segment==0){     
    }
    else if(this.segment==1){ 
    this.nom = "Liste Des Boss Actif";
      this.displayedColumns = ['position', 'name', 'weight', 'symbol', 'etienne'];
      this.dataSource1 = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA) ;
    }else{
    }    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {}

}
