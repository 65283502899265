import { IBoss } from './../../models/entities/iboss';
import { IPositionnement } from 'src/app/models/entities/ipositionnement';
import { JeveBaraService } from 'src/app/services/jeve-bara.service';
import { LoginService } from 'src/app/services/login.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IOffre } from 'src/app/models/entities/ioffre';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-taiter-tache',
  templateUrl: './taiter-tache.component.html',
  styleUrls: ['./taiter-tache.component.scss'],
})
export class TaiterTacheComponent implements OnInit {

  @Input() headerTitle : String = "";
  recherche : string = "";
  title : boolean = false;
  user : any = {} ;
  offre : IPositionnement[] = [] ;
  offreBoolean : Boolean = true;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  path: string;
  textMessage: any;

  constructor(private loginService: LoginService,
              public jeveBaraService : JeveBaraService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<TaiterTacheComponent> ) {
        console.log("data : "+ JSON.stringify(data));
  }

  
  private apiUrl = environment.apiUrl;
  
  closeDialog(bol: boolean){
    this.dialogRef.close(bol);
  }

  close(){              
    this.closeDialog(true);
  }

  async sendMessage(){                   
    this.closeDialog(true);
     const loading = this.loginService.loading();
    (await loading).present();
    let data = {"commentaire": this.textMessage,"idSinistre": this.data.id};

    // this.loginService.showAlert("Traiter sinistre", JSON.stringify(data));
    // return;
    (await this.jeveBaraService.traiterSinistre(data)).
    subscribe(async (res) => {
      (await loading).dismiss();  
      this.loginService.showAlert("Traiter sinistre", "Sinistre traiter avec succès !");
      this.jeveBaraService.detailSinistre.emit();

    },async e => {
      (await loading).dismiss();
      this.loginService.showAlert("Désolé","Désolé une erreur c'est produite veuillez reésayer plus tard !");
    });
  }

  // changeOffreBox(idOffre : number){
  //   this.jeveBaraService.getOffreById(idOffre+"").subscribe( (res) => {
  //     this.offre = res ;
  //   });
  // }

  ngOnInit() {}

}
