import { LoginService } from './../login.service';
import { Injectable } from '@angular/core';
import { JeveBaraService } from './../jeve-bara.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http'; 
 
import {  tap } from "rxjs/operators"; 
import { Observable } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';
 
 

@Injectable()
export class TokenInterceptor implements HttpInterceptor {  
  
  
  constructor(public auth: LoginService,
    public localStorageService :  LocalStorageService,
    // private loginService : LoginService,
    public jeveBaraService : JeveBaraService,
    private navigationProvider :  NavigationService,
    ) {
      // this.jeveBaraService.notif.emit();
    }  




  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
      
      // this.jeveBaraService.notif.emit();
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        
        if (err.status === 401 || err.status === 403) {
          console.log("deconnexion");
          this.localStorageService.clearSession();
          this.auth.logout();
          this.navigationProvider.openPage("login");
        }
        // if (err.status === 0 ) {
        //   this.auth.showAlert("Connexion","Vérifiez Votre Connection Internet");
        // }
      }
    }));
  }
}